<section>
    <nz-spin [nzSpinning]="isLoading">
        <form nz-form #form="ngForm" (ngSubmit)="submit()">
            <div class="drawer-form-header-empty"></div>
            <div nz-row class="drawer-form-header">
                <div nz-col nzSpan="12">
                    <div class="heading-fs-18 mb-0">
                        <span nz-icon nzType="arrow-left" nzTheme="outline" class="drawer-back-arrow mr-2 disp-mob"
                            (click)="cancel()"></span>
                        New Screen
                    </div>
                </div>
                <div nz-col nzSpan="12" class="text-right disp-web">
                    <button nz-button class="mr-3" type="button" (click)="cancel()">Cancel</button>
                    <button nz-button nzType="primary" [disabled]="form.invalid">Save</button>
                </div>
                <div nz-col nzSpan="24">
                    <nz-divider></nz-divider>
                </div>
            </div>

            <div nz-row class="mobOne-action-m-b">
                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control class="mb-0"
                                [nzValidateStatus]="screenName.invalid && (screenName.dirty || screenName.touched) ? 'error' : ''">
                                <nz-form-label nzRequired>Screen name
                                    <span nz-icon nzType="exclamation-circle" nzTheme="outline" nz-tooltip
                                        nzTooltipTitle="Please enter the screen name" class="label-icon"></span>
                                </nz-form-label>
                                <nz-input-group>
                                    <input nz-input placeholder="Give your screen a name" name="screenName"
                                        #screenName="ngModel" [(ngModel)]="model.name" required [minlength]="1"
                                        [maxlength]="100" appAlphaNumericsLevelThree />
                                </nz-input-group>
                                <div *ngIf="form.submitted || screenName?.touched || screenName?.dirty"
                                    class="validation-error-message">
                                    <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                        class="validation-icon mt-1" *ngIf="screenName?.errors"></span>
                                    <span *ngIf="screenName?.errors?.['required']" class="ml-1"> Screen
                                        name
                                        is required. </span>
                                </div>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <div nz-col nzSpan="24" class="mt-2">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-form-label nzRequired>Select a player</nz-form-label>
                                <nz-select nzPlaceHolder="Select a player" name="playerTypeId"
                                    [(ngModel)]="selectedPlayerType" #playerTypeId="ngModel"
                                    (ngModelChange)="selectPlayerType($event)" required>
                                    <nz-option *ngFor="let item of playerTypeList" [nzValue]="item"
                                        nzLabel="{{item.name}}"></nz-option>
                                </nz-select>
                                <div *ngIf="playerTypeId.invalid && playerTypeId.touched"
                                    class="validation-error-message">
                                    <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                        class="validation-icon mt-1" *ngIf="playerTypeId?.errors"></span>
                                    <span *ngIf="playerTypeId?.errors?.['required']" class="ml-1">
                                        Please select a player type. </span>
                                </div>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-form-label>Player activation code
                                    <span nz-icon nzType="exclamation-circle" nzTheme="outline" nz-tooltip
                                        nzTooltipTitle="Enter the 4-digit Activation Code" class="label-icon"></span>
                                </nz-form-label>
                                <nz-input-group>
                                    <input nz-input placeholder="Example 567A" maxlength="4" minlength="4"
                                        (input)="toUpperCase($event)" [(ngModel)]="model.displayCode" name="displayCode"
                                        #displayCode="ngModel" />
                                </nz-input-group>
                                <div class="fs-12">
                                    <app-content-text-view
                                        [contentType]="'DISPLAY_ADD_ACTIVATION'"></app-content-text-view>
                                </div>
                                <div *ngIf="form.submitted || displayCode?.touched || displayCode?.dirty"
                                    class="validation-error-message">
                                    <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                        class="validation-icon mt-1" *ngIf="displayCode?.errors"></span>
                                    <span *ngIf="displayCode?.errors?.['minlength']" class="ml-1">Codes
                                        are 4 characters long and are not case sensitive.</span>
                                </div>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-form-label>Group</nz-form-label>
                                <nz-select [(ngModel)]="selectedGroupId" nzPlaceHolder="Groups allow you to share things within manager with other people." name="group"
                                    (ngModelChange)="confirmGroupChange($event)" [nzDisabled]="true">
                                    <nz-option [nzValue]="item.keyInt" nzLabel="{{item.value}}"
                                        *ngFor="let item of subscriptionPlans"></nz-option>
                                    <nz-option *ngIf="subscriptionPlans.length == 0" nzValue="disabled"
                                        nzLabel="No group added" nzDisabled></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-form-label>Folder</nz-form-label>
                                <nz-select [(ngModel)]="model.folderId" nzPlaceHolder="Organize items in Manager with folders" name="folder">
                                    <nz-option [nzValue]="item.keyInt" nzLabel="{{item.value}}"
                                        *ngFor="let item of folders"></nz-option>
                                    <nz-option *ngIf="folders.length == 0" nzValue="disabled" nzLabel="No Folder Added"
                                        nzDisabled></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <div nz-col nzSpan="24" class="mt-2 mb-1" *ngIf="!addFromPlaylist">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-form-label>Playlist
                                    <span nz-icon nzType="exclamation-circle" nzTheme="outline" nz-tooltip
                                        nzTooltipTitle="Select Playlist" class="label-icon"></span>
                                </nz-form-label>
                                <nz-select nzPlaceHolder="Select playlist" name="displayLists"
                                    [(ngModel)]="model.displayListId">
                                    <nz-option [nzValue]="item.keyInt" nzLabel="{{item.value}}"
                                        *ngFor="let item of displayLists"></nz-option>
                                    <nz-option *ngIf="displayLists.length == 0" nzValue="disabled"
                                        nzLabel="No playlist added" nzDisabled></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>


                <div nz-col nzSpan="24" class="mt-2 mb-3">

                    <div class="d-flex align-items-center text-muted">
                        <div class="mr-2 cursor-pointer" for="" (click)="onVisibleChange()">Screen orientation</div>
                        <span nz-icon [nzType]="selectOrientation ? 'down' : 'right'" class="ortn-arrow cursor-pointer"
                            (click)="onVisibleChange()"></span>
                    </div>

                    <div nz-row [nzGutter]="[16, 16]" class="mt-3" *ngIf="selectOrientation">
                        <div nz-col nzSpan="6" class="cursor-pointer">
                            <nz-card class="orientation-card select-option" (click)="selectRotationDegree(0)"
                                [ngClass]="{'orientation-card-selected': model.rotationDegree == null}">
                                <div class="orientation-conatiner">
                                    <img class="lightmode_img" src="./assets/images/standard-rotation.svg" alt="">
                                    <img class="darkmode_img" src="./assets/images/standard-rotation-darkmode.svg"
                                        alt="">
                                </div>
                                <div class="mt-3 mb-2 ortn-content">Standard</div>
                            </nz-card>
                        </div>
                        <div nz-col nzSpan="6" class="cursor-pointer">
                            <nz-card class="orientation-card select-option" (click)="selectRotationDegree(90)"
                                [ngClass]="{'orientation-card-selected': model.rotationDegree == 90}">
                                <div class="orientation-conatiner">
                                    <img class="lightmode_img" src="./assets/images/90-rotation.svg" alt="">
                                    <img class="darkmode_img" src="./assets/images/90-rotation-darkmode.svg" alt="">
                                </div>
                                <div class="mt-3 mb-2 ortn-content">90 <span class="disp-web">&nbsp; Degree</span></div>
                            </nz-card>
                        </div>
                        <div nz-col nzSpan="6" class="cursor-pointer">
                            <nz-card class="orientation-card select-option" (click)="selectRotationDegree(180)"
                                [ngClass]="{'orientation-card-selected': model.rotationDegree == 180}">
                                <div class="orientation-conatiner">
                                    <img class="lightmode_img" src="./assets/images/180-rotation.svg" alt="">
                                    <img class="darkmode_img" src="./assets/images/180-rotation-darkmode.svg" alt="">
                                </div>
                                <div class="mt-3 mb-2 ortn-content">180 <span class="disp-web">&nbsp; Degree</span>
                                </div>
                            </nz-card>
                        </div>
                        <div nz-col nzSpan="6" class="cursor-pointer">
                            <nz-card class="orientation-card select-option" (click)="selectRotationDegree(270)"
                                [ngClass]="{'orientation-card-selected': model.rotationDegree == 270}">
                                <div class="orientation-conatiner">
                                    <img class="lightmode_img" src="./assets/images/270-rotation.svg" alt="">
                                    <img class="darkmode_img" src="./assets/images/270-rotation-darkmode.svg" alt="">
                                </div>
                                <div class="mt-3 mb-2 ortn-content">270 <span class="disp-web">&nbsp; Degree</span>
                                </div>
                            </nz-card>
                        </div>
                    </div>
                </div>

                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control class="mb-0">
                                <nz-form-label>Notes</nz-form-label>
                                <nz-textarea-count [nzMaxCharacterCount]="100">
                                    <textarea rows="2" nz-input placeHolder="Enter Notes" name="description"
                                        #description="ngModel" [(ngModel)]="model.description"
                                        [maxlength]="100"></textarea>
                                </nz-textarea-count>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </div>

            <div nz-row class="disp-mob mobOne-action-bar">
                <div nz-col nzSpan="24" class="text-right">
                    <nz-card>
                        <div nz-row [nzGutter]="[16]">
                            <div nz-col nzSpan="12">
                                <button nz-button class="btn-full" type="button" (click)="cancel()">Cancel</button>
                            </div>
                            <div nz-col nzSpan="12">
                                <button nz-button class="btn-full" nzType="primary"
                                    [disabled]="form.invalid">Save</button>
                            </div>
                        </div>
                    </nz-card>
                </div>
            </div>

            <nz-modal [(nzVisible)]="confirmAddDisplay" [nzTitle]="title" [nzFooter]="displayModalFooter"
                nzClosable="false" class="custom-width-450 ">
                <ng-container *nzModalContent>
                    <div>You are about to cross your maximum screens count. This newly created screen will be auto
                        updated
                        in your next billing cycle.</div>
                </ng-container>

                <ng-template #title>
                    <div class="mt-3">Confirm Change</div>
                </ng-template>

                <ng-template #displayModalFooter>
                    <div class="mb-3">
                        <button nz-button nzType="default" (click)="cancelAddDisplay()">Cancel</button>
                        <button nz-button nzType="primary" (click)="addDisplay()">Proceed</button>
                    </div>
                </ng-template>
            </nz-modal>
        </form>
    </nz-spin>
</section>