export const Constants = {
    defaultPageSize: 10,
    maxImageUploadSize: 15728640,
    interceptAuthToken: true,
    subscriptionWarningBefore: 2, // In days
    varAuthToken: 'AuthToken',
    varTempAuthToken: 'TempAuthToken',
    varB2CRedirectUri: 'B2CRedirectUri',
    nonAuthUrl: ['https://www.googleapis.com'],
    varUserInfo: 'UserInfo',
    varSubscriptionSummary: 'SubscriptionSummary',
    varSelectedGroupId: 'SelectedGroupId',
    varGroupSummary: 'GroupSummary',
    varDarkMode: 'DarkMode',
    displayPreviewPlaceholder: 'assets/images/rp_placeholder.png',
    displayLivePreviewPlaceholder: '/assets/images/rp_placeholder.png',
    maxSecondForDisplayList: 21600,
    defaultMediaImageKey: 'be00a74e-b2f9-c170-ab25-b5cad11718d5.jpeg',
    onedriveContentFilter: 'folder,.ppt,.pptx',
    canvaUserId: 'canvaUserId',
    canvaState: 'canvaState',
    varIsGroupsLoaded: 'IsGroupsLoaded',
    varIsTrialPeriodEnded: 'IsTrialPeriodEnded',
    varOnboardingSuccessParams: 'OnboardingSuccessParams',
    emailRegex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/,
    pageContentType: {
        displayAddTop: 'DISPLAY_ADD_TOP',
        displayAddBottom: 'DISPLAY_ADD_BOTTOM',
        displayEditTop: 'DISPLAY_EDIT_TOP',
        displayEditBottom: 'DISPLAY_EDIT_BOTTOM',
        displayManageTop: 'DISPLAY_MANAGE_TOP',
        displayChangeGroupTop: 'DISPLAY_CHANGE_GROUP_TOP',
        displayChangeGroupBottom: 'DISPLAY_CHANGE_GROUP_BOTTOM',
        displayAddActivation: 'DISPLAY_ADD_ACTIVATION',
        displayEmptyPlaceholder: 'DISPLAY_EMPTY_PLACEHOLDER',
        displayListAddTop: 'DISPLAY_LIST_ADD_TOP',
        displayListAddBottom: 'DISPLAY_LIST_ADD_BOTTOM',
        displayListEditTop: 'DISPLAY_LIST_EDIT_TOP',
        displayListEditBottom: 'DISPLAY_LIST_EDIT_BOTTOM',
        displayListManageTop: 'DISPLAY_LIST_MANAGE_TOP',
        displayListChangeGroupTop: 'DISPLAY_LIST_CHANGE_GROUP_TOP',
        displayListChangeGroupBottom: 'DISPLAY_LIST_CHANGE_GROUP_BOTTOM',
        displayListEmptyPlaceholder: 'DISPLAY_LIST_EMPTY_PLACEHOLDER',
        broadcastEmptyPlaceholder: 'BROADCAST_EMPTY_PLACEHOLDER',
        mediaAppTop: 'MEDIA_APP_TOP',
        mediaAppStoreEmptyPlaceholder: 'MEDIA_APP_STORE_EMPTY_PLACEHOLDER',
        mediaAppBottom: 'MEDIA_APP_BOTTOM',
        mediaAppWebTop: 'MEDIA_APP_WEB_TOP',
        mediaAppWebBottom: 'MEDIA_APP_WEB_BOTTOM',
        mediaAppO365Top: 'MEDIA_APP_O365_TOP',
        mediaAppO365Bottom: 'MEDIA_APP_O365_BOTTOM',
        mediaAppGoogleSlideTop: 'MEDIA_APP_GOOGLE_SLIDE_TOP',
        mediaAppGoogleSlideBottom: 'MEDIA_APP_GOOGLE_SLIDE_BOTTOM',
        mediaAppImageTop: 'MEDIA_APP_IMAGE_TOP',
        mediaAppImageBottom: 'MEDIA_APP_IMAGE_BOTTOM',
        mediaAppClockTop: 'MEDIA_APP_CLOCK_TOP',
        mediaAppClockBottom: 'MEDIA_APP_CLOCK_BOTTOM',
        mediaAppPdfTop: 'MEDIA_APP_PDF_TOP',
        mediaAppPdfBottom: 'MEDIA_APP_PDF_BOTTOM',
        mediaAppWeatherTop: 'MEDIA_APP_WEATHER_TOP',
        mediaAppWeatherBottom: 'MEDIA_APP_WEATHER_BOTTOM',
        mediaAppYouTubeTop: 'MEDIA_APP_YOUTUBE_TOP',
        mediaAppYouTubeBottom: 'MEDIA_APP_YOUTUBE_BOTTOM',
        mediaAppVideoTop: 'MEDIA_APP_VIDEO_TOP',
        mediaAppVideoBottom: 'MEDIA_APP_VIDEO_BOTTOM',
        mediaAppCanvaTop: 'MEDIA_APP_CANVA_TOP',
        mediaAppCanvaBottom: 'MEDIA_APP_CANVA_BOTTOM',
        mediaAppChangeGroupTop: 'MEDIA_APP_CHANGE_GROUP_TOP',
        mediaAppChangeGroupBottom: 'MEDIA_APP_CHANGE_GROUP_BOTTOM',
        mediaAppEmptyPlaceholder: 'MEDIA_APP_EMPTY_PLACEHOLDER',
        mediaAppBulkUploadTop: 'MEDIA_APP_BULK_UPLOAD_TOP',
        mediaAppBulkUploadBottom: 'MEDIA_APP_BULK_UPLOAD_BOTTOM',
        groupAddTop: 'GROUP_ADD_TOP',
        groupAddBottom: 'GROUP_ADD_BOTTOM',
        groupEditTop: 'GROUP_EDIT_TOP',
        groupEditBottom: 'GROUP_EDIT_BOTTOM',
        groupDetailTop: 'GROUP_DETAIL_TOP',
        groupDetailBottom: 'GROUP_DETAIL_BOTTOM',
        groupManageTop: 'GROUP_MANAGE_TOP',
        groupChangeOwnerTop: 'GROUP_CHANGE_OWNER_TOP',
        groupChangeOwnerBottom: 'GROUP_CHANGE_OWNER_BOTTOM',
        onboardingWelcomeTitle: 'ONBOARDING_WELCOME_TITLE',
        onboardingWelcomeSubtitle: 'ONBOARDING_WELCOME_SUBTITLE',
        onboardingWelcomeContent: 'ONBOARDING_WELCOME_CONTENT',
        onboardingOrientationAddDisplayBtn: 'ONBOARDING_ORIENTATION_ADDDISPLAYBTN',
        onboardingOrientationDisplayMenu: 'ONBOARDING_ORIENTATION_DISPLAYMENU',
        onboardingOrientationPlaylistMenu: 'ONBOARDING_ORIENTATION_PLAYLISTMENU',
        onboardingOrientationMediaMenu: 'ONBOARDING_ORIENTATION_MEDIAMENU',
        onboardingOrientationAddDisplayBtnForMobile: 'ONBOARDING_ORIENTATION_ADDDISPLAYBTN_FOR_MOBILE',
        onboardingOverviewOfSetup: 'ONBOARDING_OVERVIEW_OF_SETUP',
        addDisplayPlayerGoogleTv: 'ADD_DISPLAY_PLAYER_GOOGLE_TV',
        addDisplayPlayerAmazonFireTv: 'ADD_DISPLAY_PLAYER_AMAZON_FIRE_TV',
        addDisplayPlayerAndroidDevice: 'ADD_DISPLAY_PLAYER_ANDROID_DEVICE',
        addDisplayPlayerIosDevice: 'ADD_DISPLAY_PLAYER_IOS_DEVICE',
        addDisplayPlayerSamSungTv: 'ADD_DISPLAY_PLAYER_SAMSUNG_TV',
        addDisplayPlayerLgTv: 'ADD_DISPLAY_PLAYER_LG_TV',
        addDisplayWebBrowserPlayer: 'ADD_DISPLAY_WEB_BROWSER_PLAYER',
        addDisplayWindowsPlayer: 'ADD_DISPLAY_WINDOWS_PLAYER',
        onboardingStepOneTitle: 'ONBOARDING_STEP_ONE_TITLE',
        onboardingStepTwoTitle: 'ONBOARDING_STEP_TWO_TITLE',
        onboardingMoneyBackGuarantee: 'ONBOARDING_MONEY_BACK_GUARANTEE',
        onboardingCancellationTerm: 'ONBOARDING_CANCELLATION_TERM',
        onboardingSubscriptionTagLine: 'ONBOARDING_SUBSCRIPTION_TAG_LINE',
        onboardingStepThreeTitle: 'ONBOARDING_STEP_THREE_TITLE',
        onboardingStepThreeAgreementToolTip: 'ONBOARDING_STEP_THREE_AGREEMENT_TOOL_TIP',
        subscriptionCurrentTop: 'SUBSCRIPTION_CURRENT_TOP',
        broadcastAddTop: 'BROADCAST_ADD_TOP',
        broadcastAddBottom: 'BROADCAST_ADD_BOTTOM',
        broadcastEditTop: 'BROADCAST_EDIT_TOP',
        broadcastEditBottom: 'BROADCAST_EDIT_BOTTOM',
        cardUpdateTop: 'CARD_UPDATE_TOP',
        cardUpdateBottom: 'CARD_UPDATE_BOTTOM',
        resellerProfileTop: 'RESELLER_PROFILE_TOP',
        resellerBrandingTop: 'RESELLER_BRANDING_TOP',
        addFolderTooltip: 'ADD_FOLDER_TOOLTIP',
        groupMemberAccessPermissionMessage: 'GROUP_MEMBER_ACCESS_PERMISSION_MESSAGE',
        orderSummaryAgreementToolTip: 'ORDER_SUMMARY_AGREEMENT_TOOL_TIP',
        displayHelpVideo: 'DISPLAY_HELP_VIDEO',
        displayHelpTitle: 'DISPLAY_HELP_TITLE',
        displayListHelpVideo: 'DISPLAY_LIST_HELP_VIDEO',
        displayListHelpTitle: 'DISPLAY_LIST_HELP_TITLE',
        broadcastHelpVideo: 'BROADCAST_HELP_VIDEO',
        broadcastHelpTitle: 'BROADCAST_HELP_TITLE',
        groupHelpVideo: 'GROUP_HELP_VIDEO',
        groupHelpTitle: 'GROUP_HELP_TITLE',
        mediaAppHelpVideo: 'MEDIA_APP_HELP_VIDEO',
        mediaAppHelpTitle: 'MEDIA_APP_HELP_TITLE',
        mediaAppVimeoTop: 'MEDIA_APP_VIMEO_TOP',
        mediaAppVimeoBottom: 'MEDIA_APP_VIMEO_BOTTOM',
        broadcastMinutelyRepeatInfoMessage: "BROADCAST_MINUTELY_REPEAT_INFO_MESSAGE",
        onboardingStepOneContent: 'ONBOARDING_STEP_ONE_CONTENT',
        subscriptionPlanSavingText: 'SUBSCRIPTION_PLAN_SAVING_TEXT',
        subscriptionPlanHeading: 'SUBSCRIPTION_PLAN_HEADING',
        groupPriceHiddenMessage: "GROUP_PRICE_HIDDEN_MESSAGE",
        signInPageTitle: "SIGN_IN_PAGE_TITLE",
        signInPageDescription: "SIGN_IN_PAGE_DESCRIPTION",
        welcomePageHelpVideoUrl: 'WELCOME_PAGE_HELP_VIDEO_URL',
        welcomeAddScreen: 'WELCOME_ADD_SCREEN'
    },
    configsMultiSelectDropdownWithoutSearch: {
        singleSelection: false,
        idField: 'keyInt',
        textField: 'value',
        selectAllText: 'Select All',
        unSelectAllText: 'Unselect All',
        itemsShowLimit: 1,
        allowSearchFilter: false,
        closeDropDownOnSelection: false,
        noDataAvailablePlaceholderText: 'No record found'
    },
    configsMultiSelectDropdown: {
        singleSelection: false,
        idField: 'keyInt',
        textField: 'value',
        selectAllText: 'Select All',
        unSelectAllText: 'Unselect All',
        itemsShowLimit: 1,
        allowSearchFilter: true,
        closeDropDownOnSelection: false,
        noDataAvailablePlaceholderText: 'No record found'
    },
    configsSingleSelctionMultiSelectDropdown: {
        singleSelection: true,
        idField: 'keyInt',
        textField: 'value',
        itemsShowLimit: 1,
        allowSearchFilter: true,
        closeDropDownOnSelection: true,
        noDataAvailablePlaceholderText: 'No record found'
    },
    tourStepSequence: {
        mediaAdd: 1
    },
    modalList: {
        displayListAddModel: 'DisplayListAdd',
        displayListEditModel: 'DisplayListEdit',
        userMediaAppUpsertModel: 'UserMediaAppUpsert',
    },
    twoModalOpenCloseState: {
        initiated: 0,
        firstModalClosed: 1,
        openSecondModal: 2,
        secondModelOpned: 3,
        secondModalClosed: 4,
        firstModalOpenAgain: 5
    },
    recurrenceType: {
        daily: 1,
        weekly: 2,
        monthly: 3,
        yearly: 4,
        minutely: 5,
        hourly: 6
    },
    claimType: {
        maxVideoSize: 'max_video_size',
    },
    subscriptionPurchaseType: {
        buy: 1,
        update: 2
    },
    mediaUploadStatus: {
        pending: 1,
        inProgress: 2,
        completed: 3,
        rejected: 4
    },
    planType: {
        monthly: 'Month',
        yearly: 'Year'
    },
    azureUserIssuer: {
        google: 'google.com',
        microsoftLive: 'live.com'
    },
    cardTypes: {
        visa: "Visa",
        mastercard: "Mastercard",
        amex: "AmericanExpress",
        discover: "Discover",
        jcb: "Jcb",
        diners: "DinersClub",
        other: "Other"
    },
    type: {
        screen: "Screen",
        media: "Media",
        broadcast: "Broadcast",
        playlist: "Playlist",
        group: "Group",
        folder: "Folder"
    },
    mediaAppType: {
        webPage: 1,
        powerPoint: 2,
        googleSlide: 3,
        image: 4,
        clock: 5,
        weather: 6,
        pdf: 7,
        canva: 8,
        youTube: 9,
        video: 10,
        vimeo: 11,
        bulkUpload: 12
    },
    resellerLogoType:
    {
        lightModeLogo: 1,
        lightModeThumbnailLogo: 2,
        darkModeLogo: 3,
        darkModeThumbnailLogo: 4
    }
};
