import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import moment from "moment";
import { NzDrawerComponent } from "ng-zorro-antd/drawer";
import { Subscription } from "rxjs";
import { DisplayListListItemModel, GroupDetailModel, MediaAppDetailModel, MediaAppListItemModel, MediaListItemModel, UserMediaAppWeatherUpsertModel } from "src/app/shared/models";
import { UserMediaAppUpsertModel } from "src/app/shared/models/user-media-app/user-media-app-upsert.model";
import { BaseService, DisplayListService, FolderService, GroupService, MediaAppService, UserMediaAppService } from "src/app/shared/services";
import { AppUtils, Constants } from "src/helpers";
import { SelectListItemModel } from "src/models";

declare var google: any;

@Component({
    selector: 'app-user-media-app-weather-drawer',
    templateUrl: './user-media-app-weather.component.html',
    styleUrls: ['./user-media-app-weather.component.scss']
})

export class UserMediaAppWeatherComponent implements OnInit, OnDestroy {
    @ViewChild('drawer') drawer: NzDrawerComponent;
    @Output() closeDrawer: EventEmitter<{ media: Array<MediaListItemModel>, mediaAdded: boolean }> = new EventEmitter<{ media: Array<MediaListItemModel>, mediaAdded: boolean }>();
    @Input() mediaAppId: any;
    @Input() displayListId: number;
    @Input() selectedGroupId: number;

    isLoading = false;
    showScheduleFields = false;
    visible = false;
    model = new UserMediaAppUpsertModel();
    showGroupSelection = true;
    subscriptions = new Array<Subscription>();
    subscriptionPlans = new Array<SelectListItemModel>();
    mediaAppModel = new MediaAppDetailModel();
    groups = new Array<GroupDetailModel>();
    displayLists = new Array<SelectListItemModel>();
    selectedDisplayLists = new Array<SelectListItemModel>();
    mediaApps: Array<MediaAppListItemModel>;
    showDuration = false;
    isEndDateValid = true;
    isEndTimeValid = true;
    isStartTimeEndTimeValid = true;
    schedule = false;
    modelLoaded = false;
    folders = new Array<SelectListItemModel>();
    selectedFolder = new Array<SelectListItemModel>();
    selectedPlaylists = new Array<DisplayListListItemModel>();
    selectedPlayListIds = new Array<number>();
    isScheduled: boolean = false;
    scheduleStartTime: any;
    scheduleEndTime: any;
    isGroupLoaded: boolean = false;
    isTyping = false;
    autoComplete: any;

    get constants() { return Constants; }

    constructor(
        private baseService: BaseService,
        private groupService: GroupService,
        private folderService: FolderService,
        private userMediaAppService: UserMediaAppService,
        private mediaAppService: MediaAppService,
        private displayListService: DisplayListService,
        public appUtils: AppUtils,
    ) {
        this.model = new UserMediaAppUpsertModel();
        this.model.weather = new UserMediaAppWeatherUpsertModel();

        this.subscriptionPlans = new Array<SelectListItemModel>();
        this.folders = new Array<SelectListItemModel>();
        this.groups = new Array<GroupDetailModel>();
        this.mediaApps = new Array<MediaAppListItemModel>();
    }

    ngOnInit() {
        this.model.startDate = new Date();
        this.model.endDate = new Date();
        this.model.mediaAppId = this.mediaAppId;
        this.getMediaAppDetails();
        this.loadGroups();
    }

    onSearch(value: string): void {
        this.isTyping = value.length > 0;
    }


    getMediaAppDetails() {
        this.mediaAppService.get(this.model.mediaAppId)
            .subscribe({
                next: (response: MediaAppDetailModel) => {
                    this.mediaAppModel = response;
                    this.mediaAppModel.iconUrl = this.generateMediaIcon(this.mediaAppModel.typeString)

                },
                error: (error: any) => {
                    this.baseService.processErrorResponse(error);
                }
            });
    }

    generateMediaIcon(mediaAppType: string): string {
        return `./assets/images/media-types/icons/v2/${mediaAppType}.svg`;
    }

    loadGroups(): void {
        this.isLoading = true;
        const requestSubscription =
            this.groupService.getList()
                .subscribe({
                    next: (response: Array<GroupDetailModel>) => {
                        let selectListItem = new SelectListItemModel();
                        selectListItem.keyString = '-1';
                        selectListItem.keyInt = -1;
                        selectListItem.value = `My Subscription (${this.baseService.getUserName()})`;

                        this.subscriptionPlans.push(selectListItem);

                        Object.assign(this.groups, response);

                        this.groups.forEach(group => {
                            selectListItem = new SelectListItemModel();
                            selectListItem.keyInt = group.id;
                            selectListItem.keyString = group.id.toString();
                            selectListItem.value = `${group.name} (${group.ownerName})`;
                            this.subscriptionPlans.push(selectListItem);
                        });

                        if (this.selectedGroupId != undefined && this.selectedGroupId > 0) {
                            this.model.groupId = this.selectedGroupId;
                        }
                        else {
                            this.selectedGroupId = -1;
                            this.model.groupId = -1;
                        }

                        this.isGroupLoaded = true;
                        this.isLoading = false;
                        this.getFolders();
                        this.loadDisplayLists();
                    },
                    error: (error) => {
                        this.isLoading = false;
                        this.baseService.processErrorResponse(error);

                    }
                });

        this.subscriptions.push(requestSubscription);
    }

    getFolders() {
        this.isLoading = true;

        const requestsubscription =
            this.folderService.getList(this.model.groupId, this.model.folderId)
                .subscribe({
                    next: (response: Array<SelectListItemModel>) => {
                        this.folders = response;
                        this.updateSelectedFolder();
                        this.isLoading = false;
                    },
                    error: (error) => {
                        this.isLoading = false;
                        this.baseService.processErrorResponse(error);
                    }
                });

        this.subscriptions.push(requestsubscription);
    }

    updateSelectedFolder() {
        if (this.folders.length === 0 || !this.model.folderId) {
            return;
        }

        const selectedFolder = this.folders.filter(x => Number(x.keyInt) === Number(this.model.folderId));

        if (selectedFolder) {
            this.selectedFolder = selectedFolder;
        }
    }

    loadDisplayLists() {
        if (!this.isGroupLoaded) {
            return;
        }
        this.displayLists = new Array<SelectListItemModel>();
        this.isLoading = true;
        const requestSubscription = this.displayListService
            .getSelectListItems(this.model.groupId)
            .subscribe({
                next: (data: Array<any>) => {
                    this.isLoading = false;
                    this.displayLists = data;
                    if (!this.appUtils.isNullOrEmpty(this.displayListId)) {
                        let selectedDisplayList = this.displayLists.find(x => x.keyInt == this.displayListId);
                        this.model.displayLists.push(selectedDisplayList);
                      }
                    this.updateSelectedDisplayList();
                },
                error: (error: any) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                },
            });

        this.subscriptions.push(requestSubscription);
    }

    updateSelectedDisplayList() {
        if (this.displayLists.length === 0) {
            return;
        }

        const selectedDisplayLists = new Array<SelectListItemModel>();
        this.model.displayLists.forEach((displayList) => {
            selectedDisplayLists.push(
                this.displayLists.find(
                    (x) => Number(x.keyInt) === Number(displayList.keyInt)
                )
            );
        });

        if (selectedDisplayLists && selectedDisplayLists.length > 0) {
            this.selectedDisplayLists = selectedDisplayLists;
        }
    }

    validateScheduleDetails(): boolean {
        if (!this.model.isScheduled) {
            this.model.startTime = null;
            this.model.endTime = null;
            this.model.startDate = null;
            this.model.endDate = null;
            return true;
        }

        if (this.scheduleStartTime && this.scheduleEndTime) {
            if (!this.isStartTimeEndTimeValid) {
                return false;
            }

            const startTime = moment(this.scheduleStartTime, 'MM/DD/YYYY hh:mm A').set({ s: 0 });
            const endTime = moment(this.scheduleEndTime, 'MM/DD/YYYY hh:mm A').set({ s: 0 });
            if (
                !moment(endTime).isAfter(moment(startTime)) &&
                startTime.format('a') === endTime.format('a')
            ) {
                this.isEndTimeValid = false;
                return false;
            }
            this.model.startTime = this.appUtils.getFormattedTime(
                startTime.toString(),
                null
            );
            this.model.endTime = this.appUtils.getFormattedTime(
                endTime.toString(),
                null
            );

            if (this.model.isDaily) {
                this.model.startDate = null;
                this.model.endDate = null;
                return true;
            }
            this.model.startDate = this.appUtils.getFormattedDate(
                this.model.startDate,
                null
            );
            this.model.endDate = this.appUtils.getFormattedDate(
                this.model.endDate,
                null
            );
            if (moment(this.model.endDate).isBefore(this.model.startDate)) {
                this.isEndDateValid = false;
                return false;
            }
            this.isEndDateValid = true;
            return true;
        } else {
            return false;
        }
    }

    isTimeValid(time: any) {
        const startTime = moment(this.scheduleStartTime, 'hh:mm A').set({ s: 0 });
        const endTime = moment(time, 'hh:mm A').set({ s: 0 });
        if (
            !moment(endTime).isAfter(moment(startTime)) &&
            startTime.format('a') === endTime.format('a')
        ) {
            this.isEndTimeValid = false;
        } else {
            this.isEndTimeValid = true;
        }
    }

    checkMediaStatus() {
        if (!this.model.startTime || !this.model.endTime) {
            this.model.isScheduled = false;
            return;
        }
        this.model.isScheduled = true;
    }

    onScheduleClick() {
        this.model.startDate = null;
        this.model.endDate = null;
        this.model.startTime = null;
        this.model.endTime = null;
        this.showScheduleFields = this.model.isScheduled;
    }

    disableStartDate = (current: Date): boolean => {
        return current && current < new Date(new Date().setHours(0, 0, 0, 0));
    }

    disableEndDate = (current: Date): boolean => {
        if (!this.model.startDate) {
            return current && current < new Date(new Date().setHours(0, 0, 0, 0));
        }
        return current && current < new Date(this.model.startDate);
    }

    onSchedule() {
        this.model.startDate = null;
        this.model.endDate = null;
        this.scheduleStartTime = null;
        this.scheduleEndTime = null;

    }

    scheduleTypeChanged(isDaily: boolean) {
        this.model.isDaily = isDaily;
        this.model.startDate = null;
        this.model.endDate = null;
    }

    isStartTimeValid(time: any): void {
        const startTime = moment(time, 'hh:mm A').set({ s: 0 });
        const endTime = moment(this.scheduleEndTime, 'hh:mm A').set({ s: 0 });
        if (
            !moment(endTime).isAfter(moment(startTime)) &&
            startTime.format('a') === endTime.format('a')
        ) {
            this.isEndTimeValid = false;
        } else {
            this.isEndTimeValid = true;
        }
    }

    temperatureFormatChanged(temperatureFormat: number) {
        this.model.weather.temperatureFormat = temperatureFormat;
    }

    locationDetectionModeChanged(detectLocationAutomatically: boolean) {
        this.model.weather.detectLocationAutomatically = detectLocationAutomatically;
        if (detectLocationAutomatically) {
            this.model.weather.city = null;
            this.model.weather.latitude = null;
            this.model.weather.longitude = null;
        }
        else {
            setTimeout(() => {
                // this.appUtils.initControlLabels();
            });
        }

    }

    initPlacesAutocomplete() {
        this.model.weather.latitude = null;
        this.model.weather.longitude = null;
        const self = this;
        if (!this.autoComplete) {
            this.autoComplete = new google.maps.places.Autocomplete(document.getElementById('weatherLocation'),
                {
                    types: ['(cities)'],
                    fields: ['name', 'geometry']
                });
        }

        self.autoComplete.addListener('place_changed', function () {
            const place = self.autoComplete.getPlace();
            self.model.weather.city = (<HTMLInputElement>document.getElementById('weatherLocation')).value;
            self.model.weather.latitude = place.geometry.location.lat();
            self.model.weather.longitude = place.geometry.location.lng();
        });
    }

    removeDisplayList(item: SelectListItemModel) {
        const tempArr = [];
        this.selectedDisplayLists.forEach((displayList) => {
            tempArr.push(displayList);
        });
        const itemIndex = tempArr.indexOf(item);
        tempArr.splice(itemIndex, 1);
        this.selectedDisplayLists = tempArr;
    }

    cancel() {
        this.closeDrawer.emit({ media: new Array<MediaListItemModel>(), mediaAdded: false });
    }



    submit() {

        this.isLoading = true;
        if (this.model.groupId == null || this.model.groupId == -1) {
            this.model.groupId = null;
        }

        this.model.isScheduled = this.isScheduled;
        if (!this.validateScheduleDetails()) {
            this.isScheduled = true;
            this.isLoading = false;
            return;
        }

        this.model.displayLists = new Array<SelectListItemModel>();
        if (this.selectedDisplayLists && this.selectedDisplayLists.length > 0) {
            this.selectedDisplayLists.forEach((item) => {
                let selectedDisplayListModel = new SelectListItemModel();
                selectedDisplayListModel.keyInt = item.keyInt;
                selectedDisplayListModel.value = item.value;
                this.model.displayLists.push(selectedDisplayListModel);
            });
            this.model.lengthInSeconds =
                this.selectedDisplayLists.length === 1 || this.showDuration
                    ? this.model.lengthInSeconds
                    : '0';
        }


        if (this.selectedFolder && this.selectedFolder.length > 0) {
            this.model.folderId = this.selectedFolder[0].keyInt;
        } else {
            this.model.folderId = null;
        }

        this.model.id = 0;
        this.userMediaAppService.add(this.model).subscribe({
            next: (response: number) => {
                this.isLoading = false;
                this.baseService.successNotification('Media added successfully.');
                let addedMedia = new MediaListItemModel();
                addedMedia.id = response;
                addedMedia.typeString = this.mediaAppModel.typeString;
                addedMedia.title = this.model.title;
                addedMedia.iconUrl = this.appUtils.getBorderlessMediaAppIconUrl(this.mediaAppModel.typeString);
                addedMedia.mediaAppType = this.mediaAppModel.typeString;
                addedMedia.mediaAppTitle = this.mediaAppModel.title;
                addedMedia.status = this.model.weather.isEnabled == true ? 1 : 2;
                addedMedia.isSelected = true;
                addedMedia.urlError = false;
                addedMedia.isEnabled = this.model.weather.isEnabled;
                addedMedia.lengthInSeconds = this.model.lengthInSeconds;
                addedMedia.isScheduled = this.model.isScheduled;
                let addedMedias = new Array<MediaListItemModel>();
                addedMedias.push(addedMedia);
                this.closeDrawer.emit({ media: addedMedias, mediaAdded: true });
            },
            error: (error: any) => {
                this.isLoading = false;
                this.baseService.processErrorResponse(error);
            }
        });

    }

    onGroupChange(): void {
        this.getFolders();
    }

    validateSelectedPlace() {
        setTimeout(() => {
            if (!this.model.weather.latitude
                || !this.model.weather.longitude) {
                this.model.weather.city = null;
            }
        }, 200);
    }

    ngOnDestroy() {
        this.isLoading = false;
        this.subscriptions.forEach(s => { s.unsubscribe(); });
    }
}