<section>
    <nz-spin [nzSpinning]="isLoading">
        <form nz-form #form="ngForm" (ngSubmit)="submit()">
            <div class="drawer-form-header-empty"></div>
            <div nz-row class="drawer-form-header">
                <div nz-col nzSpan="12">
                    <div class="heading-fs-18 mb-0">
                        <span nz-icon nzType="arrow-left" nzTheme="outline" class="drawer-back-arrow mr-2 disp-mob"
                            (click)="cancel()"></span>
                        New Media
                    </div>
                </div>
                <div nz-col nzSpan="12" class="text-right disp-web">
                    <button nz-button class="mr-3" type="button" (click)="cancel()">Cancel</button>
                    <button nz-button nzType="primary" type="submit" [disabled]="form.invalid">Save</button>
                </div>
                <div nz-col nzSpan="24">
                    <nz-divider></nz-divider>
                </div>
            </div>

            <div nz-row class="mb-3">
                <div nz-col nzSpan="24">
                    <div class="media-type-header">
                        <div nz-row>
                            <div nz-col nzLg="12" nzMd="12" nzXs="12" nzSm="12" class="content-center">
                                <div class="align-items-center">
                                    <img class="media-type-header-img-wrapper" [src]="mediaAppModel.iconUrl"
                                        alt="media-type">

                                    <span class="ml-2 fs-16 fw-bold">{{mediaAppModel.title}}</span>
                                </div>
                            </div>
                            <div nz-col nzLg="12" nzMd="12" nzXs="12" nzSm="12" class="media-type-header-content">
                                <app-content-text-view
                                    [contentType]="constants.pageContentType.mediaAppO365Top"></app-content-text-view>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div nz-row class="mobOne-action-m-b">
                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control class="mb-0"
                                [nzValidateStatus]="mediaName.invalid && (mediaName.dirty || mediaName.touched) ? 'error' : ''">
                                <nz-form-label [nzRequired]="true">Media name</nz-form-label>
                                <nz-input-group>
                                    <input nz-input required placeholder="Enter your media name" name="mediaName"
                                        #mediaName="ngModel" [(ngModel)]="model.title" [minlength]="1" [maxlength]="100"
                                        appAlphaNumericsLevelThree required />
                                </nz-input-group>
                                <div *ngIf="form.submitted || mediaName?.touched || mediaName?.dirty"
                                    class="validation-error-message">
                                    <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                        class="validation-icon mt-1" *ngIf="mediaName?.errors"></span>
                                    <span *ngIf="mediaName?.errors?.['required']" class="ml-1"> Media
                                        name
                                        is required. </span>
                                </div>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-col nzSpan="24" class="mb-3 mt-2">
                    <div nz-row>
                        <div nz-col nzSpan="24">
                            <div nz-row class="mb-3">
                                <div nz-col nzSpan="24">
                                    Sign in with your Microsoft 365 account
                                    <span nz-icon nzType="exclamation-circle" nzTheme="outline" nz-tooltip
                                        nzTooltipTitle="Click here to sign in with your Microsoft 365 account to access and display your PowerPoint presentations directly from OneDrive or SharePoint."
                                        class="label-icon"></span>
                                </div>
                            </div>
                            <button type="button" nz-button nzType="primary" (click)="selectFromOneDrive()"
                                *ngIf="!model.powerPoint.pptName">
                                <div class="btn-with-google">
                                    <img src="./assets/images/media-types/icons/v2/PowerPointO365.svg" alt="vimeo-btn">
                                </div>
                                Select from office 365
                            </button>
                            <div nz-row class="mb-3" *ngIf="model.powerPoint.autherizedBy">
                                <div nz-col nzSpan="24">
                                    <div class="custom-chip">
                                        <span class="text-muted">Authorized by</span>
                                        <span> {{model.powerPoint.autherizedBy
                                            |slice:0:25}}{{model.powerPoint.autherizedBy.length > 25
                                            ? '...' : ''}}</span>
                                    </div>
                                </div>
                            </div>
                            <nz-card class="list-card pb-2 pt-2" *ngIf="model.powerPoint.pptName">
                                <div nz-row [nzGutter]="[16]">
                                    <div nz-col nzSpan="20">
                                        <div nz-row class="mr-3 align-items-center">
                                            <img [src]="imageUrlToPreview" alt="power-point" class="thumbnail mr-2">
                                            <span class="mt-1">{{model.powerPoint.pptName |
                                                slice:0:25}}{{model.powerPoint.pptName.length > 25 ? '...' :
                                                ''}}</span>
                                        </div>
                                    </div>
                                    <div nz-col nzSpan="4"
                                        class="align-items-center justify-content-end cursor-pointer">
                                        <span nz-icon nzType="delete" nzTheme="outline" class="cursor-pointer ml-2"
                                            (click)="removePowerPoint()"></span>
                                    </div>
                                </div>
                            </nz-card>

                            <div nz-row class="mt-3" *ngIf="model.powerPoint.pptName">
                                <div nz-col nzSpan="24">
                                    <button type="button" nz-button nzType="primary" class="mr-3"
                                        (click)="selectFromOneDrive()">
                                        <span nz-icon nzType="reload" nzTheme="outline"></span>
                                        Change media
                                    </button>

                                    <button type="button" nz-button (click)="startEditingPptFile()">
                                        <span nz-icon nzType="edit" nzTheme="outline"></span>
                                        Edit in Power Point
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div nz-col nzSpan="24" class="mt-1">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control class="mb-0" [ngClass]="isTyping ? 'hide-max-placeholder' : ''">
                                <nz-form-label>Playlist(s)
                                    <span nz-icon nzType="exclamation-circle" nzTheme="outline" nz-tooltip
                                        nzTooltipTitle="Select Playlist" class="label-icon"></span>
                                </nz-form-label>
                                <nz-select name="displayList" [(ngModel)]="selectedDisplayLists" [nzShowArrow]="true"
                                    nzPlaceHolder="Select Playlist" nzMode="multiple" [nzMaxTagCount]="0"
                                    [nzMaxTagPlaceholder]="isTyping ? null : maxTagPlaceholder"
                                             (nzOnSearch)="onSearch($event)">
                                    <nz-option *ngFor="let item of displayLists" [nzValue]="item"
                                        nzLabel="{{item.value}}"></nz-option>
                                    <nz-option *ngIf="displayLists.length == 0" nzDisabled
                                        nzLabel="No playlist added."></nz-option>
                                </nz-select>
                                <ng-template #maxTagPlaceholder let-selected>
                                    {{ selected.length }} item selected
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <div nz-col nzSpan="24" class="mt-2" *ngIf="selectedDisplayLists.length > 0">
                    <div class="selected-chip-container">
                        <nz-tag nzMode="closeable" class="selected-tag" (click)="removeDisplay(item)"
                            *ngFor="let item of selectedDisplayLists">
                            <span class="selected-tag-content">{{item.value}}</span></nz-tag>
                    </div>
                </div>

                <div nz-col nzSpan="24" class="mt-3">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-form-label>Group
                                    <span nz-icon nzType="exclamation-circle" nzTheme="outline" class="label-icon"
                                        nz-tooltip nzTooltipTitle="Groups allow you to share things within manager with other people."></span>
                                </nz-form-label>
                                <nz-select nzPlaceHolder="Select group" name="groupId" #groupId="ngModel"
                                    [(ngModel)]="model.groupId" (ngModelChange)="onGroupChange()" [nzDisabled]="true">
                                    <nz-option *ngFor="let item of subscriptionPlans" [nzValue]="item.keyInt"
                                        nzLabel="{{item.value}}"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-form-label>Folder
                                    <span nz-icon nzType="exclamation-circle" nzTheme="outline" class="label-icon"
                                        nz-tooltip nzTooltipTitle="Organize items in Manager with folders."></span>
                                </nz-form-label>
                                <nz-select nzPlaceHolder="Select folder" name="folderId" [(ngModel)]="model.folderId">
                                    <nz-option *ngFor="let item of folders" [nzValue]="item.keyInt"
                                        nzLabel="{{item.value}}"></nz-option>
                                    <nz-option *ngIf="folders.length == 0" nzLabel="No folder Added" nzValue="disabled"
                                        nzDisabled></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-col nzSpan="24">
                    <div nz-row class="mb-3">
                        <div nz-col nzSpan="24">
                            <div class="align-items-center">
                                <label class="mr-3" for="" class="fs-16 mb-0 fw-bold">Schedule</label>
                                <span class="ml-3"><nz-switch [(ngModel)]="isScheduled" (ngModelChange)="onSchedule()"
                                        name="schedule" #schedule="ngModel"></nz-switch></span>
                            </div>
                            <div nz-row *ngIf="isScheduled">
                                <div nz-col nzSpan="24">
                                    <nz-divider></nz-divider>
                                </div>
                            </div>
                            <div nz-row class="mt-2 ml-2" *ngIf="isScheduled">
                                <div nz-col nzSpan="24">
                                    <nz-radio-group [(ngModel)]="model.isDaily" name="isDaily" #isDaily="ngModel"
                                        (ngModelChange)="scheduleTypeChanged($event)">
                                        <label nz-radio [nzValue]="true">Daily</label>
                                        <label nz-radio [nzValue]="false">Date range</label>
                                    </nz-radio-group>
                                </div>
                            </div>

                            <div nz-row [nzGutter]="[16]" class="mt-2" *ngIf="isScheduled">
                                <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                                    <div class="input-container">
                                        <nz-form-item>
                                            <nz-form-control class="mt-3 mb-0">
                                                <nz-form-label>Start time</nz-form-label>
                                                <nz-time-picker [nzUse12Hours]="true" [nzAllowEmpty]="false"
                                                    (ngModelChange)="isStartTimeValid($event)" class="cursor-pointer"
                                                    [(ngModel)]="scheduleStartTime" name="startTime"
                                                    #startTime="ngModel" nzFormat="hh:mm a" nzPlaceHolder="Select"
                                                    required>
                                                </nz-time-picker>
                                                <div *ngIf="form.submitted || startTime?.touched"
                                                    class="validation-error-message">
                                                    <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                                        class="validation-icon mt-1" *ngIf="startTime?.errors"></span>
                                                    <span *ngIf="startTime?.errors?.['required']" class="ml-1">Start
                                                        time is
                                                        required</span>
                                                </div>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                                <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                                    <div class="input-container">
                                        <nz-form-item>
                                            <nz-form-control class="mt-3 mb-0">
                                                <nz-form-label>End time</nz-form-label>
                                                <nz-time-picker [nzUse12Hours]="true" class="cursor-pointer"
                                                    (ngModelChange)="isTimeValid($event)" [nzAllowEmpty]="false"
                                                    [(ngModel)]="scheduleEndTime" name="endTime" #endTime="ngModel"
                                                    nzFormat="hh:mm a" nzPlaceHolder="Select" required>
                                                </nz-time-picker>
                                                <div *ngIf="form.submitted || endTime?.touched"
                                                    class="validation-error-message">
                                                    <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                                        class="validation-icon mt-1"
                                                        *ngIf="(endTime?.errors) || (!endTime?.errors?.['required'] && !isEndTimeValid)"></span>
                                                    <span *ngIf="endTime?.errors?.['required']" class="ml-1">End
                                                        time is required</span>
                                                    <span *ngIf="!endTime?.errors?.['required'] && !isEndTimeValid"
                                                        class="ml-1">Please select any future time</span>
                                                </div>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                            <div nz-row [nzGutter]="[16]" *ngIf="isScheduled && !model.isDaily">
                                <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                                    <div class="input-container">
                                        <nz-form-item>
                                            <nz-form-control class="mt-3 mb-0">
                                                <nz-form-label>Start date</nz-form-label>
                                                <nz-date-picker nzFormat="MM/dd/YYYY" name="startDate"
                                                    [nzAllowClear]="false" [(ngModel)]="model.startDate"
                                                    #startDate="ngModel" [nzDisabledDate]="disableStartDate"
                                                    nzPlaceHolder="Select" class="cursor-pointer"></nz-date-picker>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                                <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                                    <div class="input-container">
                                        <nz-form-item>
                                            <nz-form-control class="mt-3 mb-0">
                                                <nz-form-label>End date</nz-form-label>
                                                <nz-date-picker nzFormat="MM/dd/YYYY" name="endDate"
                                                    [nzAllowClear]="false" [(ngModel)]="model.endDate"
                                                    #endDate="ngModel" [nzDisabledDate]="disableEndDate"
                                                    nzPlaceHolder="Select" class="cursor-pointer"></nz-date-picker>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div nz-col nzSpan="24" class="mt-2 mb-3">
                    <div class="align-items-center">
                        <label class="mr-3" for="" class="fs-16 mb-0 fw-bold">Show this media</label>
                        <span nz-icon nzType="exclamation-circle" nzTheme="outline" class="label-icon" nz-tooltip
                            nzTooltipTitle="Show or hide media"></span>
                        <span class="ml-3">
                            <nz-switch name="isEnabled" [(ngModel)]="model.powerPoint.isEnabled"
                                #isEnabled="ngModel"></nz-switch>
                        </span>
                    </div>
                </div>


                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control class="mb-0">
                                <nz-form-label>Notes</nz-form-label>
                                <nz-textarea-count [nzMaxCharacterCount]="100">
                                    <textarea rows="2" nz-input placeHolder="Enter Notes" name="description"
                                        #description="ngModel" [(ngModel)]="model.description"
                                        [maxlength]="100"></textarea>
                                </nz-textarea-count>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </div>

            <div nz-row class="disp-mob mobOne-action-bar">
                <div nz-col nzSpan="24" class="text-right">
                    <nz-card>
                        <div nz-row [nzGutter]="[16]">
                            <div nz-col nzSpan="12">
                                <button nz-button class="btn-full" type="button" (click)="cancel()">Cancel</button>
                            </div>
                            <div nz-col nzSpan="12">
                                <button nz-button class="btn-full" nzType="primary"
                                    [disabled]="form.invalid">Save</button>
                            </div>
                        </div>
                    </nz-card>
                </div>
            </div>
        </form>
    </nz-spin>
</section>