import { FilterModel } from "src/app/shared/models";

export class UserMediaAppFilterModel extends FilterModel {
    public folderId: number;

    constructor() {
        super();
        this.folderId = null;
    }

    override toQueryString() {
        let queryString = `filterKey=${this.filterKey}&folderId=${this.folderId}&sortBy=${this.sortBy}&sortDir=${this.sortDir}&page=${this.page}&pageSize=${this.pageSize}`;
        return queryString;
    }

}