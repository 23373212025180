import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';


import moment from 'moment';
import * as CryptoJS from 'crypto-js';
import { window } from 'rxjs/operators';
import { Constants } from './app.constants';
import { environment } from 'src/environments/environment';
import { SelectListItemModel } from '../models';
import { jwtDecode } from 'jwt-decode';

@Injectable({
    providedIn: 'root',
})

export class AppUtils {
    public urlRegexPattern = '^(http|https):\/\/[^ "]+$';
    public languages = ['English'];
    public onBoardingSteps =
        [{
            name: 'Overview Of Setup',
            url: 'step1',
            params: {},
            nextUrl: 'step2'
        },
        {
            name: 'Buy Page',
            url: 'step2',
            params: {},
            nextUrl: 'step3'
        },
        {
            name: 'User Self-Setup',
            url: 'step3',
            nextUrl: '/display/manage'
        }];

    public getDateTimeUtc() {
        return moment().utc();
    }

    public getFormattedDateTimeUtc() {
        return moment().utc().format('MM/DD/yyyy HH:mm:ss');
    }

    getBaseOffsetDateTime(baseUtcOffset: string) {
        const dateTime = moment().utc().add(baseUtcOffset);
        return dateTime ? dateTime.format('MM/DD/yyyy HH:mm:ss') : '';
    }

    public getFormattedDate(date: string, format: string) {
        if (!format) {
            format = 'MM/DD/YYYY';
        }
        return date ? moment(date).format(format) : '';
    }

    public getFormattedTime(time: string, format: string) {
        if (!format) {
            format = 'HH:mm:ss';
        }
        return time ? moment(time).format(format) : '';
    }

    public getFormattedDateTime(date: string, format: string) {
        if (!format) {
            format = 'MM/DD/yyyy hh:mm a';
        }
        return date ? moment(date).format(format) : '';
    }

    public getFormattedLocalDateTime(date: string, format: string): string {
        if (!format) {
            format = 'MM/DD/yyyy hh:mm a';
        }
        var date = moment.utc(date).format(format);
        var stillUtc = moment.utc(date).toDate();
        var local = moment(stillUtc).local().format(format);
        return local;
    }
    
    public getDateTime(date: string, time: string) {
        const dateTime = moment(date).add(time);
        return dateTime ? dateTime.format('MM/DD/yyyy hh:mm A') : '';
    }

    public getFullDateTime() {
        const dateTime = moment();
        return dateTime ? dateTime.format('MM/DD/yyyy hh:mm A') : '';
    }

    public getUtcOffSet(offsetBase: string = null) {
        return !offsetBase
            ? moment().utcOffset()
            : moment().utcOffset(offsetBase);
    }

    public isUserAuthenticated() {
        return this.getAuthToken();
    }

    public getRemotePreviewBlobUrl(displayCode: string) {
        return `${this.generateBlobUrlBase()}/${environment.azureContainers.remotePreviewImage}/${displayCode.toLowerCase()}.jpeg`;
    }

    public generateBlobUrlBase() {
        return `${environment.azureContainerBaseUrl}`;
    }

    public getTransitionTypeSelectListItems() {
        const transitionTypes = new Array<SelectListItemModel>();
        const fade = new SelectListItemModel();
        fade.keyString = 'Fade';
        fade.value = 'Fade';
        fade.isSelected = true;
        transitionTypes.push(fade);
        const instant = new SelectListItemModel();
        instant.keyString = 'Instant';
        instant.value = 'Instant';
        transitionTypes.push(instant);
        return transitionTypes;
    }

    public isAuthTokenExists() {
        const authToken = this.getAuthToken();
        return authToken !== null;
    }

    public getLocalDate(date: string) {
        return moment.utc(date).local();
    }

    public encrypt(str: string) {
        const passPhrase = environment.aesBase64Cryptography.passPhrase;
        const secret = environment.aesBase64Cryptography.secret;
        return CryptoJS.AES.encrypt(str, passPhrase + secret).toString();
    }

    public encryptSha256(str: string) {
        return CryptoJS.SHA256(str).toString();
    }

    public decrypt(encryptedStr: string) {
        const passPhrase = environment.aesBase64Cryptography.passPhrase;
        const secret = environment.aesBase64Cryptography.secret;
        return CryptoJS.AES.decrypt(encryptedStr, passPhrase + secret).toString(CryptoJS.enc.Utf8);
    }

    public getAddDisplayPlayerTypes() {
        const contents = new Array<SelectListItemModel>();
        const googleTv = new SelectListItemModel();
        googleTv.keyString = Constants.pageContentType.addDisplayPlayerGoogleTv;
        googleTv.value = 'Google TV';
        contents.push(googleTv);
        const amazonFireTv = new SelectListItemModel();
        amazonFireTv.keyString = Constants.pageContentType.addDisplayPlayerAmazonFireTv;
        amazonFireTv.value = 'Amazon Fire TV';
        contents.push(amazonFireTv);
        const androidDevice = new SelectListItemModel();
        androidDevice.keyString = Constants.pageContentType.addDisplayPlayerAndroidDevice;
        androidDevice.value = 'Android Device';
        contents.push(androidDevice);
        const iosDevice = new SelectListItemModel();
        iosDevice.keyString = Constants.pageContentType.addDisplayPlayerIosDevice;
        iosDevice.value = 'iOS Device';
        contents.push(iosDevice);
        const samsungTv = new SelectListItemModel();
        samsungTv.keyString = Constants.pageContentType.addDisplayPlayerSamSungTv;
        samsungTv.value = 'Samsung TV';
        contents.push(samsungTv);
        const lgTv = new SelectListItemModel();
        lgTv.keyString = Constants.pageContentType.addDisplayPlayerLgTv;
        lgTv.value = 'LG TV';
        contents.push(lgTv);
        const webBrowserPlayer = new SelectListItemModel();
        webBrowserPlayer.keyString = Constants.pageContentType.addDisplayWebBrowserPlayer;
        webBrowserPlayer.value = 'Web Browser Player';
        contents.push(webBrowserPlayer);
        const windowsPlayer = new SelectListItemModel();
        windowsPlayer.keyString = Constants.pageContentType.addDisplayWindowsPlayer;
        windowsPlayer.value = 'Windows Player';
        contents.push(windowsPlayer);
        return contents;
    }

    public getStartTypeSelectListItems() {
        const startTypes = new Array<SelectListItemModel>();
        const liveAt = new SelectListItemModel();
        liveAt.keyInt = 1;
        liveAt.value = 'Live at';
        liveAt.isSelected = true;
        startTypes.push(liveAt);
        const liveImmediately = new SelectListItemModel();
        liveImmediately.keyInt = 2;
        liveImmediately.value = 'Live immediately';
        startTypes.push(liveImmediately);
        return startTypes;
    }

    public getEndTypeSelectListItems() {
        const endTypes = new Array<SelectListItemModel>();
        const expireAt = new SelectListItemModel();
        expireAt.keyInt = 1;
        expireAt.value = 'Expire at';
        expireAt.isSelected = true;
        endTypes.push(expireAt);
        const nonExpire = new SelectListItemModel();
        nonExpire.keyInt = 2;
        nonExpire.value = 'Non Expiring';
        endTypes.push(nonExpire);
        return endTypes;
    }

    public getRecurrenceTypeSelectListItems() {
        const recurrenceTypes = new Array<SelectListItemModel>();
        const minute = new SelectListItemModel();
        minute.keyInt = Constants.recurrenceType.minutely;
        minute.value = 'Minute(s)';
        recurrenceTypes.push(minute);
        const hourly = new SelectListItemModel();
        hourly.keyInt = Constants.recurrenceType.hourly;
        hourly.value = 'Hour(s)';
        recurrenceTypes.push(hourly);
        const daily = new SelectListItemModel();
        daily.keyInt = Constants.recurrenceType.daily;
        daily.value = `Day(s)`;
        recurrenceTypes.push(daily);
        const weekly = new SelectListItemModel();
        weekly.keyInt = Constants.recurrenceType.weekly;
        weekly.value = 'Week(s)';
        recurrenceTypes.push(weekly);
        const monthly = new SelectListItemModel();
        monthly.keyInt = Constants.recurrenceType.monthly;
        monthly.value = 'Month(s)';
        recurrenceTypes.push(monthly);
        const yearly = new SelectListItemModel();
        yearly.keyInt = Constants.recurrenceType.yearly;
        yearly.value = 'Year(s)';
        recurrenceTypes.push(yearly);
        return recurrenceTypes;
    }

    public getWeekDaysSelectListItem() {
        const list = new Array<SelectListItemModel>();
        const weekDays = moment.weekdays();
        weekDays.forEach((d, index) => {
            const day = new SelectListItemModel();
            day.keyInt = index;
            day.value = d.substring(0, 2);
            list.push(day);
        });
        return list;
    }

    public getFileExtension(filename: string) {
        return filename.indexOf('.') !== -1 ? filename.split('.').pop() : null;
    }

    public getBlobUrl(containerName: string, fileKey: string) {
        return `${this.generateBlobUrlBase()}/${containerName}/${fileKey}`;
    }

    public getDateSelectListItem() {
        const list = new Array<SelectListItemModel>();
        const days = Array.from({ length: 31 }, (_, i) => i + 1);
        days.forEach((d) => {
            const day = new SelectListItemModel();
            day.keyInt = d;
            day.value = d.toString();
            list.push(day);
        });
        return list;
    }

    public getDifferenceFromCurrentDate(date: string): number {
        return moment(date).diff(moment(), 'days');
    }

    public isNullOrZero(input: string): boolean {
        return input === null
            || typeof input === undefined
            || typeof input === 'undefined'
            || input === '0'
            || !input;
    }

    public isNullOrEmpty(input: any): boolean {
        return input === null
            || typeof input === undefined
            || typeof input === 'undefined'
            || !input;
    }

    public static isNullOrUndefined(input: any): boolean {
        return input === null
            || typeof input === undefined
            || typeof input === 'undefined'
            || !input;
    }

    public getBlockSize(fileSize: number): number {
        const size32Mb = 1024 * 1024 * 32;
        const size4Mb = 1024 * 1024 * 4;
        const size512Kb = 1024 * 512;

        return fileSize > size32Mb ? size4Mb : size512Kb;
    }

    public formatFileSize(bytes: number): string {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    public extractQueryParams(url: string) {
        if (url.indexOf('?') === -1) {
            return null;
        }

        // remove return url from
        url = (decodeURIComponent(url).replace('?returnUrl=/', ''));
        url = decodeURIComponent(url.toString().split('?')[1]);
        return url;
    }

    public getUnixTimeStamp() {
        return new Date().getTime();
    }

    getCurrentLocalTime() {
        return moment().local().format('HH:mm:ss');
    }

    public buildVimeoAuthorizationEndpoint(state: string): string {
        // tslint:disable-next-line: max-line-length
        return encodeURI(`${environment.vimeo.authorizationEndpoint}/?response_type=token&client_id=${environment.vimeo.client_id}&redirect_uri=${environment.vimeo.redirectUri}&scope=${environment.vimeo.scope}&state=${state}`);
    }

    public getAuthToken() {
        let authToken = sessionStorage.getItem(Constants.varAuthToken)
        if (!authToken) {
            authToken = localStorage.getItem(Constants.varAuthToken);
        }
        return authToken;
    }

    public getCanvaDesignCreateUrl(designName: string, height: string, width: string) {
        return `${environment.canva.redirectUrl}?create&width=${width}&height=${height}&units=px&title=${designName}`;
    }

    public getCanvaDesignEditUrl(designId: string) {
        return `${environment.canva.redirectUrl}/${designId}`;
    }

    getBorderlessMediaAppIconUrl(mediaType: string): string {
        return `./assets/images/media-types/icons/v2/${mediaType}.svg`;
    }

    getBorderedMediaAppIconUrl(mediaType: string): string {
        return `./assets/images/media-types/icons/${mediaType}.svg`;
    }

    public getLanguages() {
        const languages = new Array<SelectListItemModel>();
        const english = new SelectListItemModel();
        english.keyString = 'en-US';
        english.value = 'English';
        languages.push(english);;
        return languages;
    }

    decodeJwtToken(token: string): any {
        return jwtDecode(token);
    }  

    public getDateForAntDatePicker(date: Date): any {
        return moment({ year: date.getFullYear(), month: date.getMonth(), date: date.getDate()});
    }
}
