import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import moment from "moment";
import { Subscription } from "rxjs";
import { BroadcastEditModel, BroadcastListItemModel, BroadcastRecurringModel, GroupDetailModel, GroupModel, MediaListItemModel, SelectListItemModel, TimeZoneModel, UserMediaAppSummaryModel } from "src/app/shared/models";
import { BaseService, BroadcastService, DisplayService, FolderService, GroupService, TimeZoneService, UserMediaAppService } from "src/app/shared/services";
import { AppUtils, Constants } from "src/helpers";

@Component({
    selector: 'app-broadcast-edit',
    templateUrl: './broadcast-edit.component.html',
    styleUrls: ['./broadcast-edit.component.scss']
})
export class BroadcastEditComponent implements OnInit {
    @Output() closeDrawer: EventEmitter<{ updatedBroadcast: BroadcastListItemModel, updateBroadcast: boolean }> = new EventEmitter<{ updatedBroadcast: BroadcastListItemModel, updateBroadcast: boolean }>();
    @Input() broadcastId: number;
    model = new BroadcastEditModel();
    subscriptions = new Array<Subscription>();
    subscriptionPlans = new Array<SelectListItemModel>();
    displays = new Array<SelectListItemModel>();
    userMediaApps = new Array<UserMediaAppSummaryModel>();
    selectedDisplays = new Array<SelectListItemModel>();
    startDateTypes = new Array<SelectListItemModel>();
    endDateTypes = new Array<SelectListItemModel>();
    recurrenceTypes = new Array<SelectListItemModel>();
    weekDays = new Array<SelectListItemModel>();
    monthDays = new Array<SelectListItemModel>();
    folders: Array<SelectListItemModel>;
    timeZones = new Array<TimeZoneModel>();
    groups = new Array<GroupModel>();
    selectedMedia = new MediaListItemModel();


    minDate: any;
    minEndDateTime: any;

    isGroupLoaded = false;
    isDisplaysLoaded = false;
    isUserMediaAppLoaded = false;
    isSeparationCountValid = true;
    isEndDateTimeValid = true;
    isLoading = false;
    isMediaSelected = false;
    isDisplaySelected = false;
    isDrawerVisible = false;
    isBroadcastLoaded: boolean = false;
    imageUrlToPreview: any;
    disableGroupSelection: boolean = false;
    showGroupSelection = true;
    selectedGroupId: number = -1;
    isTyping = false;

    get constants() { return Constants; }

    constructor(
        private appUtils: AppUtils,
        private groupService: GroupService,
        private baseService: BaseService,
        private displayService: DisplayService,
        private userMediaAppService: UserMediaAppService,
        private timeZoneService: TimeZoneService,
        private folderService: FolderService,
        private broadcastService: BroadcastService
    ) {
        const startDate = new Date();
        this.minDate = {
            year: startDate.getFullYear(),
            month: startDate.getMonth() + 1,
            day: startDate.getDate()
        };

        this.minEndDateTime = new Date();

        this.startDateTypes = this.appUtils.getStartTypeSelectListItems();
        if (this.startDateTypes.find(x => x.isSelected)) {
            this.model.startDateType = this.startDateTypes.find(x => x.isSelected).keyInt;
        }
        this.endDateTypes = this.appUtils.getEndTypeSelectListItems();
        if (this.endDateTypes.find(x => x.isSelected)) {
            this.model.endDateType = this.endDateTypes.find(x => x.isSelected).keyInt;
        }
        this.recurrenceTypes = this.appUtils.getRecurrenceTypeSelectListItems();
        this.weekDays = this.appUtils.getWeekDaysSelectListItem();
        this.monthDays = this.appUtils.getDateSelectListItem();
    }

    ngOnInit(): void {
        const selectedGroupId = this.baseService.getGroupId();
        if (selectedGroupId) {
            if (selectedGroupId !== -1) {
                this.model.groupId = selectedGroupId;
                this.disableGroupSelection = true;
                this.showGroupSelection = true;
            } else {
                this.model.groupId = null;
            }
        } else {
            this.disableGroupSelection = false;
            this.showGroupSelection = true;
            this.model.groupId = -1;
        }
        this.model.id = this.broadcastId;
        this.loadGroups();
        this.loadBroadcastMediaAppById();
        this.loadTimeZones();
        this.loadDisplays();
        this.loadFolders();
        this.loadUserMediaApps();
    }

    onSearch(value: string): void {
        this.isTyping = value.length > 0;
    }

    loadGroups(): void {
        this.isLoading = true;
        const requestSubscription =
            this.groupService.getList()
                .subscribe({
                    next: (response: Array<GroupDetailModel>) => {
                        let selectListItem = new SelectListItemModel();
                        selectListItem.keyString = '-1';
                        selectListItem.keyInt = -1;
                        selectListItem.value = `My Subscription (${this.baseService.getUserName()})`;

                        this.subscriptionPlans.push(selectListItem);

                        if (response.length === 0) {
                            this.selectedGroupId = -1;
                            this.model.groupId = -1;
                            this.isLoading = false;
                            return;
                        }

                        if (!this.model.id) {
                            this.model.groupId = -1;
                            this.selectedGroupId = -1;
                        }


                        Object.assign(this.groups, response);

                        this.groups.forEach(group => {
                            selectListItem = new SelectListItemModel();
                            selectListItem.keyInt = group.id;
                            selectListItem.keyString = group.id.toString();
                            selectListItem.value = `${group.name} (${group.ownerName})`;
                            this.subscriptionPlans.push(selectListItem);
                        });
                        this.isLoading = false;

                    },
                    error: (error) => {
                        this.isLoading = false;
                        this.baseService.processErrorResponse(error);

                    }
                })
        this.subscriptions.push(requestSubscription);
    }


    loadDisplays() {
        this.isDisplaysLoaded = false;
        this.selectedDisplays = new Array<SelectListItemModel>();
        const requestSubscription =
            this.displayService.getSelectListItems(this.model.groupId)
                .subscribe({
                    next: (data: Array<SelectListItemModel>) => {
                        this.displays = data;
                        this.isDisplaysLoaded = true;
                        this.onDisplaySelected();
                    },
                    error: error => {
                        this.isDisplaysLoaded = true;
                        this.baseService.processErrorResponse(error);
                    }
                });

        this.subscriptions.push(requestSubscription);
    }

    loadUserMediaApps() {
        this.isLoading = true;
        this.isUserMediaAppLoaded = false;
        const requestSubscription = this.userMediaAppService
            .getList(this.model.groupId)
            .subscribe({
                next: (data: Array<UserMediaAppSummaryModel>) => {
                    this.isLoading = false;
                    this.userMediaApps = data;
                    this.isUserMediaAppLoaded = true;
                },
                error: (error: any) => {
                    this.isLoading = false;
                    this.isUserMediaAppLoaded = true;
                    this.baseService.processErrorResponse(error);
                },
            });

        this.subscriptions.push(requestSubscription);
    }

    loadTimeZones() {
        const offSet = this.appUtils.getUtcOffSet();
        const requestSubscription = this.timeZoneService.getAll().subscribe({
            next: (timeZones: Array<TimeZoneModel>) => {
                Object.assign(this.timeZones, timeZones);

                const currentTimeZone = this.timeZones.find(
                    (x) => x.baseUtcOffsetMinutes === offSet
                );

                if (currentTimeZone) {
                    this.model.timeZone = currentTimeZone.standardName;
                } else {
                    console.log('Unable to detect current timezone');
                }
            },
            error: (error: any) => {
                this.baseService.processErrorResponse(error);
            },
        });

        this.subscriptions.push(requestSubscription);
    }

    loadFolders(): void {
        this.isLoading = true;
        const requestSubscription =
            this.folderService.getList(this.model.groupId, this.model.folderId).subscribe({
                next: (response: Array<SelectListItemModel>) => {
                    this.isLoading = false;
                    this.folders = response;
                },
                error: (error: any) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                },
            });

        this.subscriptions.push(requestSubscription);
    }

    onStartDateTypeChanged() {
        this.model.startDateTime = null;
        if (Number(this.model.startDateType) === 2) {
            this.model.resetStartDate = true;
            this.model.startDateTime = this.appUtils.getFullDateTime();
        } else {
            this.model.resetStartDate = false;
        }
        if (Number(this.model.startDateType) !== 1 || Number(this.model.endDateType) !== 1) {
            this.model.isRecurring = false;
        }
    }

    onEndDateTypeChanged() {
        this.model.endDateTime = null;
        if (Number(this.model.endDateType) === 2) {
            this.model.resetEndDate = true;
            this.model.isAutoDelete = false;
        } else {
            this.model.resetEndDate = false;
        }
        if (Number(this.model.startDateType) !== 1 || Number(this.model.endDateType) !== 1) {
            this.model.isRecurring = false;
        }
    }

    onRecurrenceClick(event: boolean): void {
        this.model.isRecurring = event;
        if (!this.model.isRecurring || (this.model.isRecurring && !this.model.recurring)) {
            this.model.recurring = new BroadcastRecurringModel();
        }
    }

    cancel(): void {
        this.closeDrawer.emit({ updatedBroadcast: new BroadcastListItemModel(), updateBroadcast: false });
    }

    onDisplaySelected() {
        this.selectedDisplays = this.model.displayIds.map(id => {
            return this.displays.find(display => display.keyInt === id);
        });
    }

    disableStartDate = (current: Date): boolean => {
        return current && this.appUtils.getDateForAntDatePicker(current) < this.appUtils.getDateForAntDatePicker(new Date(this.model.prevStartDateTime));
    };

    disableEndDate = (current: Date): boolean => {
        if (this.model.startDateType === 2) {
            return current && this.appUtils.getDateForAntDatePicker(current) < this.appUtils.getDateForAntDatePicker(new Date(this.model.prevStartDateTime));
        }
        if (!this.model.startDateTime) {
            return current && this.appUtils.getDateForAntDatePicker(current) < this.appUtils.getDateForAntDatePicker(this.minEndDateTime);
        }
        return current && this.appUtils.getDateForAntDatePicker(current) < this.appUtils.getDateForAntDatePicker(new Date(this.model.startDateTime));
    };

    disableRecurringEndDate = (current: Date): boolean => {
        return current && this.appUtils.getDateForAntDatePicker(current) < this.appUtils.getDateForAntDatePicker(this.minEndDateTime);
    };

    isEndDateValid(): void {
        if (Number(this.model.startDateType) === 1 &&
            (!moment(this.model.endDateTime).set({ s: 0 }).isAfter(moment(this.model.startDateTime).set({ s: 0 })))) {
            this.isEndDateTimeValid = false;
            return;

        }
        // live immediately
        else if (Number(this.model.startDateType) === 2 && !moment(this.model.endDateTime).set({ s: 0 }).isAfter(moment(this.model.prevStartDateTime))) {
            this.isEndDateTimeValid = false;
            this.model.startDateTime = this.appUtils.getFullDateTime();
            return;
        } else {
            this.isEndDateTimeValid = true;
        }
    }

    onRepeatTypeChange() {
        this.model.recurring.days = new Array<number>();
        if (this.model.recurring.days && !this.model.recurring.days[0]) {
            this.model.recurring.days[0] = null;
        }
        if (Number(this.model.recurring.recurringType) === this.constants.recurrenceType.monthly) {
            this.model.recurring.days[0] = new Date(this.model.startDateTime).getDate();
        }
        if (Number(this.model.recurring.recurringType) === this.constants.recurrenceType.minutely) {
            this.model.recurring.separationCount = 5;
        }
    }

    submit(): void {
        if (!this.selectedMedia || !this.selectedMedia.title) {
            this.isMediaSelected = false;
        }

        if (this.model.displayIds.length === 0) {
            this.isDisplaySelected = false;
        }

        if (!this.isDisplaySelected || (!this.isEndDateTimeValid && Number(this.model.endDateType) !== 2) || !this.isMediaSelected) {
            return;
        }

        if (Number(this.model.startDateType) !== 2) {
            if (this.model.startDateTime) {
                this.model.startDate = this.appUtils.getFormattedDate(this.model.startDateTime, null);
                this.model.startTime = this.appUtils.getFormattedTime(this.model.startDateTime, null);
            }
        } else {
            this.model.startDateTime = null;
            this.model.startDate = null;
            this.model.startTime = null;
            this.model.isStartDateValid = true;
        }

        if (Number(this.model.startDateType) !== 2) {
            if (this.model.startDateTime) {
                this.model.startDate = this.appUtils.getFormattedDate(this.model.startDateTime, null);
                this.model.startTime = this.appUtils.getFormattedTime(this.model.startDateTime, null);
            }
        } else {
            this.model.startDateTime = null;
            this.model.startDate = null;
            this.model.startTime = null;
            this.model.isStartDateValid = true;
        }
        if (Number(this.model.endDateType) !== 2) {
            if ((Number(this.model.startDateType) !== 1 && !moment(this.model.endDateTime).set({ s: 0 }).isAfter(this.appUtils.getFullDateTime())) ||
                (Number(this.model.startDateType) === 1 && !moment(this.model.endDateTime).set({ s: 0 }).isAfter(moment(this.model.startDateTime).set({ s: 0 })))) {
                this.isEndDateTimeValid = false;
                return;
            }
            if (this.model.endDateTime) {
                this.model.endDate = this.appUtils.getFormattedDate(this.model.endDateTime, null);
                this.model.endTime = this.appUtils.getFormattedTime(this.model.endDateTime, null);
            }
        } else {
            this.model.endDateTime = null;
            this.model.endDate = null;
            this.model.endTime = null;
            this.model.isEndDateValid = true;
        }

        if (!this.model.isStartDateValid || !this.model.isEndDateValid) {
            return;
        }
        if (this.model.isRecurring) {
            if (Number(this.model.recurring.recurringType) === this.constants.recurrenceType.weekly) {
                this.model.recurring.days = this.weekDays.filter(x => x.isSelected)
                    .map((x) => {
                        return x.keyInt;
                    });
            }
            if (Number(this.model.recurring.recurringType) !== this.constants.recurrenceType.weekly
                && Number(this.model.recurring.recurringType) !== this.constants.recurrenceType.monthly) {
                this.model.recurring.days = new Array<number>();
            }
            if ((Number(this.model.recurring.recurringType) === this.constants.recurrenceType.minutely)
                && (moment(this.model.endDateTime).set({ s: 0 }).diff(moment(this.model.startDateTime).set({ s: 0 })) / 60000) >= this.model.recurring.separationCount) {
                this.isSeparationCountValid = false;
                return;
            }
            if ((Number(this.model.recurring.recurringType) === this.constants.recurrenceType.hourly)
                && (moment(this.model.endDateTime).set({ s: 0 }).diff(moment(this.model.startDateTime).set({ s: 0 })) / 60000) >= (this.model.recurring.separationCount * 60)) {
                this.isSeparationCountValid = false;
                return;
            }
            this.isSeparationCountValid = true;
        } else {
            this.model.recurring = null;
        }

        this.isLoading = true;
        this.broadcastService.editBroadcast(this.model).subscribe({
            next: (res: any) => {
                this.isLoading = false;
                this.baseService.success('Broadcast updated successfully.');
                if (res.isAnyBroadcastDisabled) {
                    setTimeout(() => {
                        const message = `You can only have one active broadcast per screen scheduled
                         at one time. We disabled the previous broadcast scheduled for this time`;
                        this.baseService.success(message);
                    }, 1000);
                }
                let firstBroadcast = new BroadcastListItemModel();
                firstBroadcast.id = this.model.id;
                firstBroadcast.name = this.model.name;

                let selectedMediaApp = this.userMediaApps.find(x => x.id == this.model.userMediaAppId);
                if (!AppUtils.isNullOrUndefined(selectedMediaApp)) {
                    firstBroadcast.defaultUserMediaAppUrl = this.appUtils.getBorderedMediaAppIconUrl(selectedMediaApp.mediaAppType);
                }

                this.closeDrawer.emit({ updatedBroadcast: firstBroadcast, updateBroadcast: true });
            },
            error: (error: any) => {
                this.isLoading = false;
                this.baseService.processErrorResponse(error);
            }
        });
    }


    removeDisplay(display: SelectListItemModel): void {
        this.selectedDisplays = this.selectedDisplays.filter(d => d !== display);
        this.model.displayIds = this.model.displayIds.filter(id => id !== display.keyInt);
        if (this.selectedDisplays.length === 0) {
            this.isDisplaySelected = false;
        }
    }

    openMediaStoreDrawer() {
        this.isDrawerVisible = true;
    }

    closeMediaStoreDrawer(event: any) {
        this.isDrawerVisible = false;
        if (event.mediaAdded) {
            if (event.media && event.media.length > 0) {
                this.model.userMediaAppId = event.media[0].id;
            }
        }
        this.loadUserMediaApps();
    }

    loadBroadcastMediaAppById() {
        this.isBroadcastLoaded = false;
        this.isLoading = true;
        const requestSubscription =
            this.broadcastService.getForEdit(this.model.id)
                .subscribe({
                    next: (data: BroadcastEditModel) => {
                        this.model = data;
                        this.isBroadcastLoaded = true;
                        if (this.model.startDateType === 1) {
                            this.model.startDateTime = this.appUtils.getDateTime(this.model.startDate, this.model.startTime);
                        } else {
                            this.model.startDateTime = this.appUtils.getFullDateTime();
                        }
                        if (moment(this.model.startDateTime)
                            .isAfter(this.appUtils.getDateTime(new Date().toDateString(), '00:00:00'))) {
                            this.model.prevStartDateTime = this.appUtils.getDateTime(new Date().toDateString(), '00:00:00');
                        } else {
                            this.model.prevStartDateTime = this.model.startDateTime;
                        }
                        this.model.endDateTime = this.appUtils.getDateTime(this.model.endDate, this.model.endTime);
                        this.model.isStartDateValid = true;
                        this.model.isEndDateValid = true;
                        if (this.model.isRecurring) {
                            this.model.recurring.isAfterOccurrence = this.model.recurring.maxNumOfOccurrence ? true : false;
                            if (this.model.recurring.recurringType === this.constants.recurrenceType.weekly) {
                                this.model.recurring.days.forEach(day => {
                                    const weekDay = this.weekDays.find(x => x.keyInt === day);
                                    weekDay.isSelected = true;
                                });
                            }
                        }
                        if (this.model.groupId !== -1) {
                            this.loadDisplays();
                            this.loadFolders();
                        }


                        if (this.model.userMediaApp) {
                            this.selectedMedia = this.model.userMediaApp;
                            this.selectedMedia.iconUrl = this.selectedMedia.iconUrl ?? this.appUtils.getBorderlessMediaAppIconUrl(this.selectedMedia.mediaAppType)
                            this.isMediaSelected = true;
                            this.imageUrlToPreview = this.generateMediaPreviewUrl(this.model.userMediaApp.mediaAppType);
                        }

                        if (this.model.displayIds.length > 0) {
                            this.isDisplaySelected = true;
                        }
                        else {
                            this.isDisplaySelected = false;
                        }

                        this.isLoading = false;
                    },
                    error: (error: any) => {
                        this.isBroadcastLoaded = true;
                        this.isLoading = false;
                        this.baseService.processErrorResponse(error);
                    }
                });
        this.subscriptions.push(requestSubscription);
    }

    generateMediaPreviewUrl(mediaType: string): string {
        return `./assets/images/media-types/preview/${mediaType}.svg`;
    }


    onGroupChange(groupId: number): void {
        this.model.folderId = null;
        this.model.groupId = groupId;
        this.loadFolders();
        this.loadDisplays();
        this.loadUserMediaApps();
    }

    ngOnDestroy() {
        this.isLoading = false;
        this.subscriptions.forEach(s => { s.unsubscribe(); });
    }
}