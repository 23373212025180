import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NzDrawerComponent } from 'ng-zorro-antd/drawer';
import { Subscription } from 'rxjs';
import { DisplayListListItemModel, DisplayListModel, DisplayListUserMediaAppModel, GroupListItemModel, SelectListItemModel, UserMediaAppSummaryModel } from 'src/app/shared/models';
import { BaseService, DisplayListService, DisplayService, FolderService, GroupService, UserMediaAppService } from 'src/app/shared/services';
import { AppUtils, Constants } from 'src/helpers';
import { ListenerService } from 'src/services';

@Component({
    selector: 'app-display-list-edit',
    templateUrl: './display-list-edit.component.html'
})

export class DisplayListEditComponent implements OnInit, OnDestroy {
    @ViewChild('drawer') drawer: NzDrawerComponent;
    @Input() displaylistId: number;
    @Input() editDisplaylistFromScreen: boolean;
    @Input() editDisplaylistFromMedia: boolean;
    @Output() closeDrawer: EventEmitter<{ displayListId: number, displayListModel: DisplayListListItemModel, displayListAdded: boolean }> = new EventEmitter<{ displayListId: number, displayListModel: DisplayListListItemModel, displayListAdded: boolean }>();
    model: DisplayListModel;
    groups: Array<GroupListItemModel>;
    folders: Array<SelectListItemModel>;
    transitionTypes = new Array<SelectListItemModel>();
    subscriptionPlans = new Array<SelectListItemModel>();
    userMediaAppSummaryListItems = new Array<UserMediaAppSummaryModel>();
    isLoading = false;
    userMediaApps = new Array<UserMediaAppSummaryModel>();
    isUserMediaAppsSummaryLoaded: boolean = false;
    isDisplaysLoaded: boolean = false;
    isUserMediaAppsLoaded: boolean = false;
    subscriptions = new Array<Subscription>();
    pendingDisplayListIds: Array<number>;
    showGroupSelection: boolean;
    pendingDisplayListIdIndex: number;
    showMediaDrawer = false;
    isDrawerVisible = false;
    isModelLoaded: boolean;
    displayTime: number;
    isScheduled: boolean = false;
    isEndTimeValid: boolean = true;
    isStartTimeEndTimeValid: boolean;
    isEndDateValid: boolean = true;
    selectedGroupId: number;
    selectedUserMediaAppIds: Array<number> = new Array<number>();

    selectedUserMediaApps = new Array<UserMediaAppSummaryModel>();
    selectedDisplays = new Array<SelectListItemModel>();
    displays = new Array<SelectListItemModel>();
    selectedUserMediaAppSummaryListItems = new Array<UserMediaAppSummaryModel>();
    disableAddAndPreviewButton = true;
    selectedFolder = new Array<SelectListItemModel>();
    singleSelctionMultiSelectDropdownForFolder = { ...Constants.configsSingleSelctionMultiSelectDropdown };
    displaysMultiSelectDropdown = { ...Constants.configsMultiSelectDropdown };
    userMediaAppsMultiSelectDropdown = { ...Constants.configsMultiSelectDropdown };

    constructor(
        private messageService: ListenerService,
        private groupService: GroupService,
        private displayListService: DisplayListService,
        private folderService: FolderService,
        private userMediaAppService: UserMediaAppService,
        private baseService: BaseService,
        private appUtils: AppUtils,
        private cdr: ChangeDetectorRef,
        private displayService: DisplayService
    ) {
        this.model = new DisplayListModel();
        this.groups = new Array<GroupListItemModel>();
        this.folders = new Array<SelectListItemModel>();
        this.transitionTypes = this.appUtils.getTransitionTypeSelectListItems();

        const subscription = new Subscription();

        const reloadUserMediaAppDataSubscription = this.messageService
            .listenReloadUserMediaAppData.subscribe(() => {
                this.loadUserMediaAppSummaryListItems();
            });

        this.subscriptions.push(reloadUserMediaAppDataSubscription);

        this.singleSelctionMultiSelectDropdownForFolder.noDataAvailablePlaceholderText
            = 'No Folder Added';
        this.displaysMultiSelectDropdown.noDataAvailablePlaceholderText = 'No screens added';
        this.userMediaAppsMultiSelectDropdown.noDataAvailablePlaceholderText = 'No medias added';

    }

    onDragged(item: any, list: UserMediaAppSummaryModel[]) {
        const index = list.indexOf(item);
        list.splice(index, 1);

        list.forEach((x, index) => {
            x.displayOrder = index + 1;
        });
    }

    ngOnInit(): void {
        this.model.id = this.displaylistId;
        if (this.model.id) {
            this.openEditDisplayListModal(this.model.id);
        }
        this.getGroups();
        this.getUserMediaApps();

    }

    getGroups(): void {
        this.isLoading = true;
        const requestSubscription = this.groupService.getList()
            .subscribe({
                next: (response: Array<GroupListItemModel>) => {
                    Object.assign(this.groups, response);

                    this.subscriptionPlans = new Array<SelectListItemModel>();

                    let subscriptionPlan = new SelectListItemModel();
                    subscriptionPlan.keyInt = -1;
                    subscriptionPlan.value = `My Subscription (${this.baseService.getUserName()})`;
                    this.subscriptionPlans.push(subscriptionPlan);

                    this.groups.forEach((group => {
                        let subscriptionPlan = new SelectListItemModel();
                        subscriptionPlan.keyInt = group.id;
                        subscriptionPlan.value = group.name;
                        this.subscriptionPlans.push(subscriptionPlan);
                    }));

                    this.isLoading = false;
                    this.getFolders();
                },
                error: (error: any) => {
                    this.baseService.processErrorResponse(error);
                }
            });

        this.subscriptions.push(requestSubscription);

    }

    onGroupChange(): void {
        this.model.folderId = null;
        this.getFolders();
    }

    getFolders(): void {
        if (this.model.groupId == -1 || this.model.groupId == null) {
            this.selectedGroupId = null;
        }
        if (this.model.folderId == undefined) {
            this.model.folderId = null;
        }

        const requestSubscription =
            this.folderService.getList(this.model.groupId, this.model.folderId)
                .subscribe({
                    next: (response: Array<SelectListItemModel>) => {
                        this.folders = response;
                    },
                    error: (error: any) => {
                        this.baseService.processErrorResponse(error);
                    }
                });

        this.subscriptions.push(requestSubscription);
    }

    deleteMedia(event) {
        const tempArr = [];
        this.selectedUserMediaApps.forEach((userMediaApp) => {
            tempArr.push(userMediaApp);
        });
        const selectedUserMediaAppIndex = this.selectedUserMediaApps.findIndex(x => x.id === event);
        tempArr.splice(selectedUserMediaAppIndex, 1);
        this.selectedUserMediaApps = tempArr;
        this.selectedUserMediaAppIds = this.selectedUserMediaApps.map(x => x.id);

        this.updateSelectedUserMediaApps();

    }

    getUserMediaApps(): void {
        this.isUserMediaAppsLoaded = false;
        const requestSubscription =
            this.userMediaAppService.getList(this.model.groupId)
                .subscribe({
                    next: (response: any) => {
                        this.userMediaApps = new Array<UserMediaAppSummaryModel>();
                        this.userMediaApps = response;
                        this.userMediaApps.forEach((x) => {
                            x.iconUrl = x.iconUrl ?? this.appUtils.getBorderlessMediaAppIconUrl(x.mediaAppType);
                        });
                        this.isUserMediaAppsLoaded = true;
                        this.hideLoader()
                        this.refreshUserMediaAppSelection();
                    },
                    error: (error: any) => {
                        this.isUserMediaAppsLoaded = true;
                        this.baseService.processErrorResponse(error);
                    }
                })

        this.subscriptions.push(requestSubscription);
    }

    generateIconUrl(code: string): string {
        return `./assets/images/player-types/${code}.png`;
    }

    submit() {
        this.isLoading = true;
        this.model.displayIds = new Array<number>();
        this.selectedDisplays.forEach((item) => {
            this.model.displayIds.push(item.keyInt);
        });

        this.model.displays = this.selectedDisplays;

        this.model.userMediaApps = new Array<DisplayListUserMediaAppModel>();
        this.selectedUserMediaAppSummaryListItems.forEach((item) => {
            const displayListUserMediaAppModel = new DisplayListUserMediaAppModel();
            displayListUserMediaAppModel.userMediaAppId = item.id;
            displayListUserMediaAppModel.lengthInSeconds = item.lengthInSeconds;
            displayListUserMediaAppModel.displayOrder = item.displayOrder;
            this.model.userMediaApps.push(displayListUserMediaAppModel);
        });
        if (this.model.groupId == -1) {
            this.model.groupId = null;
        }

        this.displayListService.edit(this.model)
            .subscribe({
                next: () => {
                    this.baseService.success("Playlist updated successfully.");
                    let updatedDisplayList = new DisplayListListItemModel();
                    updatedDisplayList.id = this.model.id;
                    updatedDisplayList.name = this.model.name;
                    if (this.selectedUserMediaAppSummaryListItems.length > 0) {
                        updatedDisplayList.defaultUserMediaAppUrl = this.selectedUserMediaAppSummaryListItems[0].iconUrl;
                        updatedDisplayList.userMediaAppCount = this.selectedUserMediaAppSummaryListItems.length;
                    }
                    else {
                        updatedDisplayList.userMediaAppCount = 0;
                    }

                    this.closeDrawer.emit({ displayListId: this.model.id, displayListModel: updatedDisplayList, displayListAdded: true });
                    this.isLoading = false;
                },
                error: (error) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                }
            });
    }

    onUserMediaAppSelect(event: any) {
        this.selectedUserMediaApps = this.userMediaApps.filter(x => this.selectedUserMediaAppIds.includes(x.id));

        this.cdr.detectChanges();
        setTimeout(() => {
            this.updateSelectedUserMediaApps();
        });
    }

    updateSelectedUserMediaApps() {
        const tempArr = new Array<UserMediaAppSummaryModel>();

        setTimeout(() => {
            this.disableAddAndPreviewButton = this.selectedUserMediaApps.length === 0;
        }, 100);

        if (this.selectedUserMediaApps.length === 0) {
            this.selectedUserMediaAppSummaryListItems = tempArr;
            return;
        }

        this.selectedUserMediaAppSummaryListItems.forEach(item => {
            const userMediaAppSummaryModel = new UserMediaAppSummaryModel();
            userMediaAppSummaryModel.id = item.id;
            userMediaAppSummaryModel.mediaAppType = item.mediaAppType;
            userMediaAppSummaryModel.mediaAppTitle = item.mediaAppTitle;
            userMediaAppSummaryModel.title = item.title;
            userMediaAppSummaryModel.lengthInSeconds = item.lengthInSeconds;
            userMediaAppSummaryModel.displayOrder = item.displayOrder;
            userMediaAppSummaryModel.status = item.status;
            userMediaAppSummaryModel.isScheduled = item.isScheduled;
            userMediaAppSummaryModel.startDate = item.startDate;
            userMediaAppSummaryModel.endDate = item.endDate;
            userMediaAppSummaryModel.startTime = item.startTime;
            userMediaAppSummaryModel.endTime = item.endTime;
            userMediaAppSummaryModel.videoEncodingStatus = item.videoEncodingStatus;
            userMediaAppSummaryModel.mediaAppType = item.mediaAppType;
            userMediaAppSummaryModel.url = item.url;
            var selectUserMedia = this.userMediaApps.find(x => x.id == item.id);
            if (selectUserMedia != undefined) {
                userMediaAppSummaryModel.iconUrl = selectUserMedia.iconUrl;
            }


            tempArr.push(userMediaAppSummaryModel);
        });

        // process added items
        this.selectedUserMediaApps.forEach((item: UserMediaAppSummaryModel) => {
            const selectedUserMediaAppSummary = this.userMediaAppSummaryListItems.find(x => x.id === item.id);
            if (selectedUserMediaAppSummary != undefined) {
                if (tempArr.findIndex(x => x.id === selectedUserMediaAppSummary.id) === -1) {
                    const userMediaAppSummary = new UserMediaAppSummaryModel();
                    userMediaAppSummary.id = selectedUserMediaAppSummary.id;
                    userMediaAppSummary.title = selectedUserMediaAppSummary.title;
                    userMediaAppSummary.lengthInSeconds = selectedUserMediaAppSummary.lengthInSeconds > 0 ?
                        selectedUserMediaAppSummary.lengthInSeconds : 10;
                    userMediaAppSummary.mediaAppType = selectedUserMediaAppSummary.mediaAppType;
                    userMediaAppSummary.mediaAppTitle = selectedUserMediaAppSummary.mediaAppTitle;
                    userMediaAppSummary.status = selectedUserMediaAppSummary.status;
                    userMediaAppSummary.isScheduled = selectedUserMediaAppSummary.isScheduled;
                    userMediaAppSummary.startDate = selectedUserMediaAppSummary.startDate;
                    userMediaAppSummary.endDate = selectedUserMediaAppSummary.endDate;
                    userMediaAppSummary.startTime = selectedUserMediaAppSummary.startTime;
                    userMediaAppSummary.endTime = selectedUserMediaAppSummary.endTime;
                    userMediaAppSummary.videoEncodingStatus = selectedUserMediaAppSummary.videoEncodingStatus;
                    userMediaAppSummary.mediaAppType = selectedUserMediaAppSummary.mediaAppType;
                    userMediaAppSummary.url = selectedUserMediaAppSummary.url;
                    userMediaAppSummary.iconUrl = this.appUtils.getBorderlessMediaAppIconUrl(item.mediaAppType);
                    var selectUserMedia = this.userMediaApps.find(x => x.id == item.id);
                    if (selectUserMedia != undefined) {
                        userMediaAppSummary.iconUrl = selectUserMedia.iconUrl;
                    }
                    tempArr.push(userMediaAppSummary);
                }
            }
        });

        tempArr.forEach((item: UserMediaAppSummaryModel) => {
            if (this.selectedUserMediaApps.findIndex(x => x.id === item.id) === -1) {
                const removedItemIndex = tempArr.indexOf(item);
                tempArr.splice(removedItemIndex, 1);
            }
        });

        tempArr.sort((a, b) => a.displayOrder - b.displayOrder);
        this.selectedUserMediaAppSummaryListItems = tempArr;

        setTimeout(() => {
            this.updateDisplayOrder();
            this.checkMediaStatus();
        });
    }

    updateDisplayOrder() {
        this.selectedUserMediaAppSummaryListItems.forEach((x, i) => {
            x.displayOrder = i + 1;
        });
    }

    checkMediaStatus() {
        for (let i = 0; i < this.selectedUserMediaAppSummaryListItems.length; i++) {
            if (!this.selectedUserMediaAppSummaryListItems[i].startTime || !this.selectedUserMediaAppSummaryListItems[i].endTime) {
                this.selectedUserMediaAppSummaryListItems[i].isScheduled = false;
                continue;
            }
            this.selectedUserMediaAppSummaryListItems[i].isScheduled = true;
        }
    }

    onUserMediaAppSelectAll(event: any) {
        this.selectedUserMediaApps = this.userMediaApps.filter(x => this.selectedUserMediaAppIds.includes(x.id));
        this.cdr.detectChanges();
        setTimeout(() => {
            this.updateSelectedUserMediaApps();
        });
    }

    onUserMediaAppDeSelect(event: any) {
        this.selectedUserMediaApps = this.userMediaApps.filter(x => this.selectedUserMediaAppIds.includes(x.id));
        this.cdr.detectChanges();
        setTimeout(() => {
            this.updateSelectedUserMediaApps();
        });
    }

    onUserMediaAppDeSelectAll(event: any) {
        this.selectedUserMediaApps = this.userMediaApps.filter(x => this.selectedUserMediaAppIds.includes(x.id));
        this.cdr.detectChanges();
        setTimeout(() => {
            this.updateSelectedUserMediaApps();
        });
    }

    removeUserMediaApp(item: UserMediaAppSummaryModel) {
        const tempArr = [];
        this.selectedUserMediaApps.forEach((userMediaApp) => {
            tempArr.push(userMediaApp);
        });
        const selectedUserMediaAppIndex = this.selectedUserMediaApps.findIndex(x => x.id === item.id);
        tempArr.splice(selectedUserMediaAppIndex, 1);
        this.selectedUserMediaApps = tempArr;
        this.selectedUserMediaAppIds = this.selectedUserMediaApps.map(x => x.id);
        this.updateSelectedUserMediaApps();
    }


    removeSelectedItem(event: any) {
        const index = this.selectedUserMediaAppSummaryListItems.findIndex(app => app.id === event.id);
        if (index !== -1) {
            this.selectedUserMediaAppSummaryListItems.splice(index, 1);
        }

        const selectedIndex = this.selectedUserMediaApps.findIndex(x => x.id == event.id);
        if (index !== -1) {
            this.selectedUserMediaApps.splice(selectedIndex, 1);
        }
    }

    loadUserMediaAppSummaryListItems() {
        this.isUserMediaAppsSummaryLoaded = false;
        this.userMediaAppService.getList(this.model.groupId)
            .subscribe({
                next: (data: Array<any>) => {
                    this.isUserMediaAppsSummaryLoaded = true;
                    this.hideLoader();
                    if (!data || data.length === 0) {
                        return;
                    }

                    this.userMediaAppSummaryListItems = data;
                    this.userMediaAppSummaryListItems.forEach(x => x.url = this.generateMediaUrl(x.mediaAppTitle))
                    this.refreshUserMediaAppSelection();
                },
                error: (error) => {
                    this.isUserMediaAppsSummaryLoaded = true;
                    this.hideLoader();
                    this.baseService.processErrorResponse(error)
                }
            });

    }

    generateMediaUrl(code: string): string {
        return `./assets/images/media-types/icons/${code}.svg`;
    }

    hideLoader() {
        if (this.isDisplaysLoaded
            && this.isUserMediaAppsLoaded
            && this.isUserMediaAppsSummaryLoaded) {
            this.isLoading = false;
        }
    }

    refreshUserMediaAppSelection() {
        if (!this.isUserMediaAppsLoaded || !this.isUserMediaAppsSummaryLoaded) {
            return;
        }

        const tempSelectedUserMediaApps = new Array<UserMediaAppSummaryModel>();
        const tempSelectedUserMediaAppSummaryListItems = new Array<UserMediaAppSummaryModel>();


        this.model.userMediaApps.forEach((item: DisplayListUserMediaAppModel) => {
            const userMediaApp = this.userMediaApps.find(x => x.id === item.id);
            if (userMediaApp) {
                const tempSelectedUserMediaApp = new UserMediaAppSummaryModel();
                tempSelectedUserMediaApp.id = userMediaApp.id;
                tempSelectedUserMediaApp.title = userMediaApp.title;

                tempSelectedUserMediaApps.push(tempSelectedUserMediaApp);

                const userMediaAppSummary = this.userMediaAppSummaryListItems.find(x => x.id === item.id);
                if (userMediaAppSummary) {

                    const tempSelectedUserMediaAppSummary = new UserMediaAppSummaryModel();
                    tempSelectedUserMediaAppSummary.id = item.id;
                    tempSelectedUserMediaAppSummary.mediaAppType = userMediaAppSummary.mediaAppType;
                    tempSelectedUserMediaAppSummary.mediaAppTitle = userMediaAppSummary.mediaAppTitle;
                    tempSelectedUserMediaAppSummary.title = userMediaAppSummary.title;
                    tempSelectedUserMediaAppSummary.lengthInSeconds = item.lengthInSeconds;
                    tempSelectedUserMediaAppSummary.displayOrder = item.displayOrder;
                    tempSelectedUserMediaAppSummary.videoEncodingStatus = userMediaAppSummary.videoEncodingStatus;
                    tempSelectedUserMediaAppSummary.status = userMediaAppSummary.status;
                    tempSelectedUserMediaAppSummary.mediaAppType = userMediaAppSummary.mediaAppType;
                    tempSelectedUserMediaAppSummary.url = userMediaAppSummary.url;
                    tempSelectedUserMediaAppSummary.iconUrl = this.appUtils.getBorderlessMediaAppIconUrl(userMediaAppSummary.mediaAppType);

                    tempSelectedUserMediaAppSummaryListItems.push(tempSelectedUserMediaAppSummary);
                }
            }
        });

        this.selectedUserMediaApps = tempSelectedUserMediaApps;
        this.selectedUserMediaAppIds = this.selectedUserMediaApps.map(x => x.id);
        this.selectedUserMediaAppSummaryListItems = tempSelectedUserMediaAppSummaryListItems;
        this.cdr.detectChanges();
        this.checkMediaStatus();
        this.updateSelectedUserMediaApps();
    }


    cancel(): void {
        this.closeDrawer.emit({ displayListId: 0, displayListModel: new DisplayListListItemModel(), displayListAdded: false });
    }

    loadDisplays() {
        this.isDisplaysLoaded = false;
        const requestSubscription =
            this.displayService.getSelectListItems(this.model.groupId)
                .subscribe({
                    next: (response: Array<SelectListItemModel>) => {
                        this.displays = response;
                        this.isDisplaysLoaded = true;
                        this.hideLoader();
                    },
                    error: (error) => {
                        this.isDisplaysLoaded = true;
                        this.hideLoader();
                        this.baseService.processErrorResponse(error);
                    }

                });
        this.subscriptions.push(requestSubscription);
    }

    onDisplaySelect(event: any) {
        this.selectedDisplays = this.displays.filter(x => this.model.displayIds.indexOf(x.keyInt) !== -1);
    }

    onDisplaySelectAll(event: any) {
        this.cdr.detectChanges();
    }

    onDisplayDeSelect(event: any) { }

    onDisplayDeSelectAll(event: any) { }

    removeDisplay(display: SelectListItemModel): void {
        this.selectedDisplays = this.selectedDisplays.filter(d => d !== display);
        this.model.displayIds = this.model.displayIds.filter(id => id !== display.keyInt);
    }


    onGroupChanged() {
        this.loadDisplays();
        this.getUserMediaApps();
        this.getFolders();
        this.loadUserMediaAppSummaryListItems();
    }

    disableGroup: boolean = false;

    loadDisplayList(id: number) {
        this.isLoading = true;
        const getForEditRequestSubscription =
            this.displayListService.getForEdit(id)
                .subscribe({
                    next: (data: any) => {
                        Object.assign(this.model, data);

                        if (AppUtils.isNullOrUndefined(this.model.groupId)) {
                            this.model.groupId = -1;
                            this.disableGroup = true;
                        }

                        const tempSelectedDsiplays = new Array<SelectListItemModel>();
                        this.model.displays.forEach((items) => {
                            tempSelectedDsiplays.push(items);

                        });
                        this.selectedDisplays = tempSelectedDsiplays;

                        this.selectedDisplays.forEach(x => {
                            this.model.displayIds.push(x.keyInt);
                        })

                        this.refreshUserMediaAppSelection();
                        this.updateSelectedUserMediaApps();
                        this.updateSelectedFolder();

                        if (!this.model.transitionType) {
                            this.model.transitionType = this.transitionTypes.filter(x => x.keyString.toString() === 'Fade')[0].value;
                        }

                        setTimeout(() => {
                            this.loadDisplays();
                            this.getFolders();
                            this.getUserMediaApps();
                            this.loadUserMediaAppSummaryListItems();
                            this.hideLoader();
                        }, 100);

                    },
                    error: (error: any) => {
                        this.hideLoader();
                        this.baseService.processErrorResponse(error);
                    }
                });

        this.subscriptions.push(getForEditRequestSubscription);
    }

    openEditDisplayListModal(id: number) {
        const selectedGroupId = this.baseService.getGroupId();

        if (selectedGroupId) {
            if (selectedGroupId !== -1) {
                this.model.groupId = selectedGroupId;
                this.showGroupSelection = true;
            } else {
                this.showGroupSelection = false;
                this.model.groupId = null;
            }
        } else {
            this.showGroupSelection = true;
        }

        this.transitionTypes = this.appUtils.getTransitionTypeSelectListItems();
        this.loadDisplayList(id);
    }

    updateSelectedFolder() {
        if (this.folders.length === 0 || !this.model.folderId) {
            return;
        }

        const selectedFolder = this.folders.filter(x => Number(x.keyInt) === Number(this.model.folderId));

        if (selectedFolder) {
            this.selectedFolder = selectedFolder;
        }
    }


    close() {

    }

    openMediaStoreDarwer() {
        this.showMediaDrawer = true;
        this.drawer.open();

    }

    closeMediaStoreDrawer(event: any) {
        if (event.mediaAdded) {
            if (event.media && event.media.length > 0) {
                event.media.forEach((e) => {
                    const uma = new DisplayListUserMediaAppModel();
                    uma.id = e.id;
                    uma.lengthInSeconds = 10;
                    uma.displayOrder = this.model.userMediaApps.length + 1;
                    this.model.userMediaApps.push(uma);
                });
            }
        }
        this.drawer.close();
        this.isDrawerVisible = false;
        this.showMediaDrawer = false;
        this.getUserMediaApps();
        this.loadUserMediaAppSummaryListItems();
    }

    ngOnDestroy() {
        this.isLoading = false;
        this.subscriptions.forEach(s => { s.unsubscribe(); });
    }

}