import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { DisplayChangeStatusModel, DisplayModel, GAPurchaseEventModel, GroupModel, PlayerSelectListItemModel, PlayerTypeListItemModel, ScreenPurchaseLogModel, SelectListItemModel } from "src/app/shared/models";
import { SubscriptionSummaryModel, UpdateSubscriptionModel } from "src/models";
import { BaseService, DisplayListService, DisplayService, FolderService, GroupService, PageContentService, ScreenPurchaseLogService } from "src/app/shared/services";
import { PlayerTypeService } from "src/app/shared/services/player-type.service";
import { AppUtils, Constants } from "src/helpers";
import { SubscriptionService, UserService } from "src/services";
import { Subscription } from "rxjs";
import { GoogleAnalyticsService } from "src/app/shared/services/google-analytics.service";

@Component({
    selector: 'app-display-upsert',
    templateUrl: './display-upsert.component.html',
    styleUrls: ['./display-upsert.component.scss']
})

export class DisplayUpsertComponent implements OnInit {
    @Output() closeDrawer: EventEmitter<{ selectedDisplay: SelectListItemModel, updateValue: boolean }> = new EventEmitter<{ selectedDisplay: SelectListItemModel, updateValue: boolean }>();
    @Input() displayId: any;
    @Input() groupId: number;
    @Input() addFromPlaylist: boolean = false;

    isLoading = false;
    model: DisplayModel;
    playerListModel: Array<PlayerSelectListItemModel>;
    playerTypeList: Array<PlayerTypeListItemModel>;
    groups: Array<GroupModel>;
    folders: Array<SelectListItemModel>;
    subscriptionPlans: Array<SelectListItemModel>;
    selectedGroupId: number;
    isConfirmVisible: boolean;
    selectOrientation = false;
    displayCanBeAdded: boolean;
    priceHidden: any;
    confirmAddDisplay: boolean;
    addedDisplayId = 0;

    selectedGroupOwnerEmail: string;
    totalDisplayCount: number;
    isDisplayCountLoaded: boolean;
    isSubscriptionDetailLoaded: boolean;
    subscriptionSummaryModel: SubscriptionSummaryModel;
    isIAmOwner: boolean;
    hasSubscriptionExpired: boolean;
    selectedPlayerType: any;
    playerTypeInfoMessage: any;
    isDisplayListLoaded = false;
    displayLists = new Array<SelectListItemModel>();
    subscriptions = new Array<Subscription>();
    totalDisplayCountToSubscribe = 0;

    constructor(private baseService: BaseService,
        private appUtils: AppUtils,
        private service: DisplayService,
        private groupService: GroupService,
        private folderService: FolderService,
        private playerTypeService: PlayerTypeService,
        private subscriptionService: SubscriptionService,
        private pageContentService: PageContentService,
        private sanitizer: DomSanitizer,
        private displayListService: DisplayListService,
        private userService: UserService,
        private screenPurchaseLogService: ScreenPurchaseLogService,
        private ga4Service: GoogleAnalyticsService
    ) {
        this.playerListModel = new Array<PlayerSelectListItemModel>();
        this.playerTypeList = new Array<PlayerTypeListItemModel>();
        this.folders = new Array<SelectListItemModel>();
        this.model = new DisplayModel();
        this.groups = new Array<GroupModel>();
        this.subscriptionPlans = new Array<SelectListItemModel>();

    }

    async ngOnInit() {
        await this.getGroups();
        this.getPlayerTypeList();
        this.getFolders();
        this.loadSubscriptionDetail();
        this.loadTotalDisplayCount();
        this.getDisplayLists();
    }

    onVisibleChange(): void {
        this.selectOrientation = !this.selectOrientation;
    }

    toUpperCase(event: any): void {
        this.model.displayCode = event.target.value.toUpperCase();
    }

    getPlayerTypeList(): void {
        const requestSubscription =
            this.playerTypeService.getList()
                .subscribe({
                    next: (response: Array<PlayerTypeListItemModel>) => {
                        this.playerTypeList = new Array<PlayerTypeListItemModel>();
                        this.playerTypeList = response;
                        this.playerTypeList.forEach((x) => {
                            x.iconUrl = this.generateIconUrl(x.code);
                        });
                    },
                    error: (error) => {
                        this.baseService.processErrorResponse(error);
                    }
                });
        this.subscriptions.push(requestSubscription);
    }

    generateIconUrl(code: string): string {
        return `./assets/images/player-types/${code}.png`;
    }

    selectPlayerType(item: any): void {
        this.model.playerTypeId = item.id;
        this.model.playerType = item.code;
        this.getInfoMessage(item.code);
    }

    getInfoMessage(code: string): void {
        this.isLoading = true;
        const infoMessageType = `ADD_DISPLAY_PLAYER_${code}`;
        this.pageContentService.getByType(infoMessageType)
            .subscribe({
                next: (response: any) => {
                    if (response) {
                        let text = response?.content;
                        this.playerTypeInfoMessage = this.sanitizer.bypassSecurityTrustHtml(text);
                    }
                    else {
                        this.playerTypeInfoMessage = null;
                    }
                    this.isLoading = false;
                },
                error: (error) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                }
            });
    }

    selectRotationDegree(degree: number): void {
        this.model.rotationDegree = degree;
    }

    loadTotalDisplayCount() {
        let userId = null;

        const selectedGroup = this.groups.find(x => x.id == this.model.groupId);
        if (AppUtils.isNullOrUndefined(selectedGroup)) {
            this.priceHidden = false;
        } else {
            this.selectedGroupOwnerEmail = selectedGroup.ownerEmail;
            this.priceHidden = selectedGroup.hidePricing && !selectedGroup.isGroupOwner;
        }


        if (this.model.groupId == -1) {
            userId = this.baseService.getUserId();
        } else {
            if (this.model.groupId == null) {
                return;
            }

            const groupSummary = this.groups.find(x => x.id == this.model.groupId);
            this.baseService.setByKey(Constants.varGroupSummary, JSON.stringify(groupSummary));
            userId = groupSummary.ownerId;
        }

        const requestSubscription = this.service.getDisplayCountByUserOwnership(userId)
            .subscribe({
                next: (response: number) => {

                    this.totalDisplayCount = response;
                    this.isDisplayCountLoaded = true;
                    this.updateDisplayCanBeAddedStatus();
                },
                error: (error) => {
                    this.baseService.processErrorResponse(error);
                }
            })

        this.subscriptions.push(requestSubscription);
    }

    updateDisplayCanBeAddedStatus() {
        if (!this.isSubscriptionDetailLoaded || !this.isDisplayCountLoaded) {
            return;
        }
        if (this.subscriptionSummaryModel.status !== 'InTrial') {
            this.displayCanBeAdded = this.isDisplayCountLoaded
                && (this.subscriptionSummaryModel.planQuantity + this.subscriptionSummaryModel.planFreeQuantity)
                > this.totalDisplayCount;
        } else {
            this.displayCanBeAdded = true;
        }
    }

    loadSubscriptionDetail() {

        this.displayCanBeAdded = true;

        if (this.model.groupId == null) {
            return;
        }

        let userId = null;

        if (this.model.groupId == -1) {
            userId = this.baseService.getUserId();
        } else {
            const groupSummary = this.groups.find(x => x.id == this.model.groupId);
            this.baseService.setByKey(Constants.varGroupSummary, JSON.stringify(groupSummary));
            userId = groupSummary.ownerId;
            if (userId === this.baseService.getUserId()) {
                this.isIAmOwner = true;
            } else {
                this.isIAmOwner = false;
            }
        }

        let requestSubscription = new Subscription();

        if (userId === null) {
            requestSubscription = this.subscriptionService.getDetailByGroupId(this.model.groupId)
                .subscribe({
                    next: (data: SubscriptionSummaryModel) => {
                        this.subscriptionSummaryModel = data;
                        this.isSubscriptionDetailLoaded = true;
                        if (this.subscriptionSummaryModel.status === 'Cancelled') {
                            this.hasSubscriptionExpired = true;
                        } else {
                            this.hasSubscriptionExpired = false;
                        }
                        this.updateDisplayCanBeAddedStatus();
                        this.isLoading = false;
                    },
                    error: (error: any) => {
                        this.isLoading = false;
                        this.baseService.processErrorResponse(error);
                    }
                });
        } else {
            requestSubscription = this.subscriptionService.getDetailByUserId(userId)
                .subscribe({
                    next: (data: SubscriptionSummaryModel) => {
                        this.subscriptionSummaryModel = data;
                        this.isSubscriptionDetailLoaded = true;
                        if (this.subscriptionSummaryModel.status === 'Cancelled') {
                            this.hasSubscriptionExpired = true;
                        } else {
                            this.hasSubscriptionExpired = false;
                        }
                        this.updateDisplayCanBeAddedStatus();
                        this.isLoading = false;
                    },
                    error: (error: any) => {
                        this.isLoading = false;
                        this.baseService.processErrorResponse(error);
                    }
                });
        }
        this.isLoading = true;

        this.subscriptions.push(requestSubscription);
    }

    getGroups(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.isLoading = true;
            const requestSubscription = this.groupService.getList()
                .subscribe({
                    next: (response: Array<GroupModel>) => {
                        this.groups = new Array<GroupModel>();
                        this.groups = response;

                        this.subscriptionPlans = new Array<SelectListItemModel>();
                        let subscriptionPlan = new SelectListItemModel();
                        subscriptionPlan.keyInt = -1;
                        subscriptionPlan.value = `My Subscription (${this.baseService.getUserName()})`;
                        this.subscriptionPlans.push(subscriptionPlan);

                        this.groups.forEach((group => {
                            subscriptionPlan = new SelectListItemModel();
                            subscriptionPlan.keyInt = group.id;
                            subscriptionPlan.value = `${group.name} (${group.ownerName})`;
                            this.subscriptionPlans.push(subscriptionPlan);
                        }));

                        if (this.groupId != undefined && this.groupId > 0) {
                            this.model.groupId = this.groupId
                            this.selectedGroupId = this.groupId;
                        }
                        else {
                            this.selectedGroupId = -1;
                            this.model.groupId = -1;
                        }

                        this.getFolders();
                        this.getDisplayLists();
                        this.isLoading = false;
                        resolve(true);
                    },
                    error: (error: any) => {
                        this.isLoading = false;
                        this.baseService.processErrorResponse(error);
                        reject(false);
                    }
                });
            this.subscriptions.push(requestSubscription);
        });
    }

    onGroupChange(): void {
        this.model.folderId = null;
        this.model.displayListId = null;
        this.model.broadcastId = null;
        this.getFolders();
        this.getDisplayLists();
    }

    confirmGroupChange(groupId: any) {
        if (this.model.displayListId != null || this.model.displayListId > 0) {
            this.isConfirmVisible = true;
        }
        else {
            this.model.groupId = groupId;
            this.onGroupChange();
        }
    }

    getFolders(): void {
        const requestSubscription = this.folderService.getList(this.model.groupId, null)
            .subscribe({
                next: (response: Array<SelectListItemModel>) => {
                    this.folders = response;
                },
                error: (error: any) => {
                    this.baseService.processErrorResponse(error);
                }
            })
        this.subscriptions.push(requestSubscription);
    }

    getDisplayLists() {
        this.isLoading = true;
        this.isDisplayListLoaded = false;
        this.displayListService.getSelectListItems(this.model.groupId).subscribe({
            next: (response: any) => {
                this.displayLists = response;
                this.isLoading = false;
                this.isDisplayListLoaded = true;
            },
            error: (error: any) => {
                this.isLoading = false;
                this.isDisplayListLoaded = true;
                this.baseService.processErrorResponse(error);
            }
        })
    }

    submit(): void {
        if (!this.displayCanBeAdded && !this.priceHidden) {
            this.confirmAddDisplay = true;
        }
        else {
            this.confirmAddDisplay = false;
            this.addDisplay();
        }
    }

    cancel(): void {
        this.closeDrawer.emit({ selectedDisplay: new SelectListItemModel(), updateValue: false });
    }

    cancelAddDisplay(): void {
        this.confirmAddDisplay = false;
    }

    addDisplay(): void {
        this.confirmAddDisplay = false;
        this.isLoading = true;
        if (this.selectedGroupId && this.selectedGroupId !== -1) {
            this.model.groupId = this.selectedGroupId;
        } else {
            this.model.groupId = null;
        }

        this.model.status = this.displayCanBeAdded || this.priceHidden ? 1 : 3;
        this.model.priceHidden = this.priceHidden;

        this.service.add(this.model)
            .subscribe({
                next: (data: any) => {
                    this.addedDisplayId = data;
                    this.displayId = data;

                    this.isLoading = false;
                    if (this.displayCanBeAdded || this.priceHidden) {
                        this.baseService.success("Screen added successfully.");

                        let display = new SelectListItemModel();
                        display.keyInt = this.displayId;
                        display.value = this.model.name;

                        this.closeDrawer.emit({ selectedDisplay: display, updateValue: true });

                    } else {
                        setTimeout(() => {
                            this.buySubscription();
                        });
                    }

                },
                error: (error) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                }
            })
    }


    buySubscription() {
        this.isLoading = true;
        let getDisplayCountRequestSubscription: any;
        let userId = null;
        if (this.model.groupId != null && this.model.groupId !== -1 && !this.isIAmOwner) {
            const groupSummary = this.groups.find(x => x.id === Number(this.model.groupId));
            this.baseService.setByKey(Constants.varGroupSummary, JSON.stringify(groupSummary));
            userId = groupSummary.ownerId;
            getDisplayCountRequestSubscription = this.service.getDisplayCountByUserOwnership(userId)
                .subscribe({
                    next: (totalCount: number) => {
                        this.isLoading = false;
                        this.totalDisplayCountToSubscribe = totalCount + 1;
                        this.updateSubscription(totalCount);
                    },
                    error: error => {
                        this.isLoading = false;
                        this.baseService.processErrorResponse(error);
                    }
                });
        } else {
            getDisplayCountRequestSubscription = this.service.getDisplayCount(false)
                .subscribe({
                    next: (totalCount: number) => {
                        this.isLoading = false;
                        this.totalDisplayCountToSubscribe = totalCount + 1;
                        this.updateSubscription(totalCount);
                    },
                    error: error => {
                        this.isLoading = false;
                        this.baseService.processErrorResponse(error);
                    }
                });
        }

        this.subscriptions.push(getDisplayCountRequestSubscription);

    }

    updateSubscription(planQuantity: number) {
        this.isLoading = true;
        const model = new UpdateSubscriptionModel();
        if (this.model.groupId != null && this.model.groupId !== -1 && !this.isIAmOwner) {
            model.isUpdatedByGroupUser = true;
        }

        let subscriptionSummary = JSON.parse(this.baseService.getByKey(Constants.varSubscriptionSummary));

        model.subscriptionId = subscriptionSummary.id;
        model.planQuantity = planQuantity + 1;
        model.planId = subscriptionSummary.planId;

        this.subscriptionService.updateSubscription(model)
            .subscribe({
                next: () => {
                    this.isLoading = false;
                    setTimeout(() => {
                        this.addScreenPurchaseLog();
                    });
                    setTimeout(() => {
                        this.updateDisplayStatus();
                    });
                    var ga4PurchaseModel = new GAPurchaseEventModel();
                    ga4PurchaseModel.price = this.subscriptionSummaryModel.planUnitPrice;
                    ga4PurchaseModel.quantity = 1;
                    ga4PurchaseModel.totalPrice = this.subscriptionSummaryModel.planUnitPrice;
                    this.ga4Service.sendPurchaseData(ga4PurchaseModel);
                },
                error: error => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                }
            });
    }

    updateDisplayStatus() {
        this.isLoading = true;
        const model = new DisplayChangeStatusModel();
        model.id = this.addedDisplayId;
        model.status = 1; // 1: active

        this.service.changeStatus(model)
            .subscribe({
                next: () => {
                    this.isLoading = false;
                    setTimeout(() => {
                        this.refreshToken();
                    });
                },
                error: error => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                }
            });
    }

    refreshToken() {
        this.isLoading = true;
        this.userService.getUserInfo()
            .subscribe({
                next: response => {
                    this.isLoading = false;
                    this.baseService.setUserInformation(response);
                    setTimeout(() => {
                        this.baseService.success("Screen has been added successfully.");
                        let display = new SelectListItemModel();
                        display.keyInt = this.displayId;
                        display.value = this.model.name;

                        this.closeDrawer.emit({ selectedDisplay: display, updateValue: true });
                    });
                },
                error: error => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                }
            });
    }

    addScreenPurchaseLog() {
        const model = new ScreenPurchaseLogModel();
        model.purchaseType = Constants.subscriptionPurchaseType.update;
        model.quantity = this.totalDisplayCountToSubscribe;
        if (this.model.groupId && this.model.groupId > 0 && !this.isIAmOwner) {
            model.groupId = Number(this.model.groupId);
            const groupSummary = this.groups.find(x => x.id === Number(this.model.groupId));
            model.subscriptionId = groupSummary.subscriptionId;
        } else {
            const subscriptionSummary = JSON.parse(this.baseService.getByKey(Constants.varSubscriptionSummary));
            model.groupId = null;
            model.subscriptionId = subscriptionSummary.id;
        }
        this.screenPurchaseLogService.add(model)
            .subscribe({
                next: () => {
                },
                error: (error: any) => {
                    this.baseService.processErrorResponse(error.error);
                }
            });
    }

    ngOnDestroy() {
        this.isLoading = false;
        this.subscriptions.forEach(s => { s.unsubscribe(); });
    }

}