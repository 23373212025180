import { Injectable } from '@angular/core';
import { GAPurchaseEventModel } from '../models';

declare let gtag: Function;

@Injectable()

export class GoogleAnalyticsService {
    sendPurchaseData(model: GAPurchaseEventModel) {
        gtag('event', 'purchase', {
            'value': model.totalPrice,
            'currency': 'USD',
            'items': [
                {
                    'name': 'Screen',
                    'quantity': model.quantity,
                    'price': model.price
                }
            ]
        });
    }
}
