import { Injectable } from "@angular/core";
import { Constants, AppUtils } from "src/helpers";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { Router } from "@angular/router";
import { StorageType } from "src/helpers/enums";
import { environment } from "src/environments/environment";
import * as CryptoJS from 'crypto-js';
import { jwtDecode } from 'jwt-decode';

@Injectable()
export class BaseService {

    errorMessage: string;
    get constants() { return Constants }
    get appUtils() { return AppUtils }
    storageType: StorageType;

    constructor(private notification: NzNotificationService, private router: Router) {
        this.storageType = this.appUtils.isNullOrUndefined(sessionStorage.getItem(Constants.varAuthToken)) ? StorageType.local : StorageType.session;
    }

    processErrorResponse(error: any): void {
        const errorTitle = 'Error!'; // Adjust as needed
        // Use NzNotificationService with clear error type

        if (error.status === 0) {
            this.errorMessage = "Unable to connect with API Gateway."
        } else if (error.status === 404) {
            this.errorMessage = "Something went wrong. Please try refreshing the page.";
        } else if (error.status === 500) {
            this.errorMessage = "Internal server error.";
        } else if (error.status === 401) {
            this.removeByKey(Constants.varUserInfo);
            this.removeByKey(Constants.varAuthToken);
            if (this.router.url.indexOf('account/logout') === -1) {
                this.router.navigate(['/account/logout']);
            }
        } else {
            this.errorMessage = error.error;
        }

        this.notification.error(errorTitle, this.errorMessage, {
            nzDuration: 5000,
            nzPlacement: 'topRight'
        });
    }

    info(message: string): void {
        const infoTitle = 'Info!'; // Adjust as needed
        this.notification.info(infoTitle, message, {
            nzDuration: 5000,
            nzPlacement: 'topRight'
        });
    }

    successNotification(message: string): void {
        const successTitle = 'Success!';
        this.notification.success(successTitle, message, {
            nzDuration: 5000,
            nzPlacement: 'topRight'
        });
    }

    success(message: string) {
        const infoTitle = 'Success!'; // Adjust as needed
        return this.notification.success(infoTitle, message, {
            nzDuration: 5000,
            nzPlacement: 'topRight'
        });
    }

    error(message: string): void {
        const errorTitle = 'Error!'; // Adjust as needed
        this.notification.error(errorTitle, message, {
            nzDuration: 5000,
            nzPlacement: 'topRight'
        });
    }

    warning(message: string): void {
        const warningTitle = 'Warning!'; // Adjust as needed
        this.notification.warning(warningTitle, message, {
            nzDuration: 5000,
            nzPlacement: 'topRight'
        });
    }

    public getByKey(key: string) {
        if (this.storageType == StorageType.local) {
            return localStorage.getItem(key);
        }
        return sessionStorage.getItem(key);
    }

    public setByKey(key: string, value: any) {
        if (this.storageType == StorageType.local) {
            localStorage.setItem(key, value);
        } else {
            sessionStorage.setItem(key, value);
        }
    }

    public removeByKey(key: string) {
        if (this.storageType == StorageType.local) {
            localStorage.removeItem(key);
        } else {
            sessionStorage.removeItem(key);
        }
    }

    isDarkModeActive(): boolean {
        const darkMode = this.getByKey(Constants.varDarkMode);
        return darkMode && darkMode == 'active';
    }

    public encrypt(str: string) {
        const passPhrase = environment.aesBase64Cryptography.passPhrase;
        const secret = environment.aesBase64Cryptography.secret;
        return CryptoJS.AES.encrypt(str, passPhrase + secret).toString();
    }

    public encryptSha256(str: string) {
        return CryptoJS.SHA256(str).toString();
    }

    public decrypt(encryptedStr: string) {
        const passPhrase = environment.aesBase64Cryptography.passPhrase;
        const secret = environment.aesBase64Cryptography.secret;
        return CryptoJS.AES.decrypt(encryptedStr, passPhrase + secret).toString(CryptoJS.enc.Utf8);
    }

    public isAppTester() {
        const user = JSON.parse(this.decrypt(this.getByKey(Constants.varUserInfo)));
        if (!user) {
            return false;
        }
        return user.isAppTester;
    }

    public getTokenIssuer() {
        let token = this.getByKey(Constants.varAuthToken);
        const decodedToken = this.decodeJwtToken(token);
        return decodedToken.issuer;
    }

    public getUserType() {
        let token = this.getByKey(Constants.varAuthToken);
        const decodedToken = this.decodeJwtToken(token);
        return decodedToken.user_type;
    }

    decodeJwtToken(token: string): any {
        return jwtDecode(token);
    }

    public getUserId() {
        const user = JSON.parse(this.decrypt(this.getByKey(Constants.varUserInfo)));
        if (!user) {
            return '';
        }
        return user.id;
    }

    public getUserEmail() {
        const user = JSON.parse(this.decrypt(this.getByKey(Constants.varUserInfo)));
        if (!user) {
            return '';
        }
        return user.email;
    }

    public getUserName() {
        const user = JSON.parse(this.decrypt(this.getByKey(Constants.varUserInfo)));
        if (!user) {
            return '';
        }
        return `${user.firstName} ${user.lastName}`;
    }

    public getGroupId() {
        const selectedGroupId = this.getByKey(Constants.varSelectedGroupId);
        return selectedGroupId === null
            || selectedGroupId === undefined
            || selectedGroupId === ''
            ? null
            : Number(selectedGroupId);
    }

    public getUserInformation() {
        return JSON.parse(this.decrypt(this.getByKey(Constants.varUserInfo)));
    }

    public isTrialTimeEnded(): boolean {
        const val = this.getByKey(Constants.varIsTrialPeriodEnded);
        return val === 'true';
    }

    public setUserInformation(response) {
        this.setByKey(Constants.varUserInfo, this.encrypt(JSON.stringify(response)));
    }

    public getResellerId() {
        const user = JSON.parse(this.decrypt(this.getByKey(Constants.varUserInfo)));
        if (!user) {
            return '';
        }
        return user.resellerId;
    }

    public getResellerLogoUrl() {
        const user = JSON.parse(this.decrypt(this.getByKey(Constants.varUserInfo)));
        if (!user) {
            return '';
        }
        return user.resellerLogo;
    }

    public getStorageType() {
        return this.appUtils.isNullOrUndefined(sessionStorage.getItem(Constants.varAuthToken)) ? StorageType.local : StorageType.session;
    }

    public getTempAuthToken(): string {
        return this.getByKey(Constants.varTempAuthToken);
    }

    public removeTempAuthToken() {
        this.removeByKey(Constants.varTempAuthToken);
    }

    public authenticateFreshWidget(freshWidgetToken: string) {
        // wait for 5 seconds to get FC widget loaded
        setTimeout(() => {
            // return if FC widget not found
            if (!window['fcWidget']) {
                console.log('fcWidget is undefined');
                return;
            }

            window['fcWidget'].authenticate({ token: freshWidgetToken });

            console.log('fcWidget is authenticated');

            // get user info
            const userInfo = this.getByKey(Constants.varUserInfo);
            if (!userInfo) {
                return;
            }
            const user = JSON.parse(this.decrypt(userInfo));
            // To set user first name
            window['fcWidget'].user.setFirstName(user.firstName);
            // To set user last name
            window['fcWidget'].user.setLastName(user.lastName);
            // To set user email
            window['fcWidget'].user.setEmail(user.email);

            // get subscription summary
            const subscriptionSummary = this.getByKey(Constants.varSubscriptionSummary);
            if (!subscriptionSummary) {
                // To set unique user id in your system when it is available
                window['fcWidget'].setExternalId(user.id);
                return;
            }
            const subscription = JSON.parse(subscriptionSummary);
            // To set unique user id in your system when it is available
            window['fcWidget'].setExternalId(subscription.id);
            // To set user properties
            window['fcWidget'].user.setProperties({
                plan: subscription.planName,                 // meta property 1
                status: subscription.status            // meta property 2
            });
        }, 5000);
    }
    
    public authenticateHubSpotWidget(visitorIdentificationToken: string) {
        // wait for 5 seconds to get FC widget loaded
        setTimeout(() => {
            // return if FC widget not found
            if (!window['HubSpotConversations']) {
                console.log('HubSpotConversations is undefined');
                return;
            }

            window['HubSpotConversations'].widget.remove();

            (window as any).hsConversationsSettings = {
                loadImmediately: false,
                identificationEmail: this.getUserEmail(),
                identificationToken: visitorIdentificationToken
            };

            console.log(window['hsConversationsSettings']);

            window['HubSpotConversations'].widget.load();
            window['HubSpotConversations'].widget.refresh();

            console.log('HubSpot widget is authenticated');
        }, 5000);
    }


    public isUserInfoExists() {
        const userInfo = this.appUtils.isNullOrUndefined(sessionStorage.getItem(Constants.varAuthToken))
            ? localStorage.getItem(Constants.varUserInfo)
            : sessionStorage.getItem(Constants.varUserInfo);

        return userInfo !== null;
    }

    public getAuthToken() {
        return this.getByKey(Constants.varAuthToken);
    }

    public isAuthTokenExists() {
        const authToken = this.getAuthToken();
        return authToken !== null;
    }

    public isUserAuthenticated() {
        return this.getAuthToken();
    }
} 