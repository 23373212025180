<section>
    <div class="scroll-container" #scrollContainer (scroll)="onScroll($event)">
        <div nz-row>
            <div nz-col nzSpan="18">
                <div class="heading-fs-18 mb-0">
                    <span nz-icon nzType="arrow-left" nzTheme="outline" class="drawer-back-arrow mr-2 disp-mob"
                        (click)="cancel()"></span>
                    All Broadcasts
                </div>
            </div>
            <div nz-col nzSpan="6" class="text-right pt-2 disp-web">
                <span nz-icon nzType="close" nzTheme="outline" class="cursor-pointer" (click)="cancel()"></span>
            </div>
            <div nz-col nzSpan="24">
                <nz-divider></nz-divider>
            </div>
        </div>
        <div nz-row>
            <div nz-col nzSpan="24">
                <form nz-form>
                    <div class="d-flex">
                        <div class="w-100 mr-3">
                            <div class="input-container mt-2">
                                <nz-form-item class="mb-0">
                                    <nz-form-control class="drawer-search-input">
                                        <nz-input-group [nzPrefix]="prefixIcon" [nzSuffix]="suffixIcon">
                                            <input nz-input placeholder="Search Broadcast" name="filterKey" [disabled]="!hasBroadcastList"
                                                [(ngModel)]="filterModel.filterKey" (keydown.enter)="onSearch()"
                                                (ngModelChange)="filterKeyChanged()" [maxlength]="100" [disabled]="!hasBroadcastList" appAlphaNumericsLevelThree/>
                                        </nz-input-group>
                                        <ng-template #prefixIcon>
                                            <span nz-icon nzType="search" nzTheme="outline" (click)="onSearch()"
                                                class="cursor-pointer"></span>
                                        </ng-template>
                                        <ng-template #suffixIcon>
                                            <span nz-icon nzType="close" nzTheme="outline" *ngIf="filterModel.filterKey"
                                                (click)="clearSearch()" class="clear-search cursor-pointer"></span>
                                        </ng-template>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                        <div class="text-right mt-1">
                            <button nz-button nzType="primary" type="button" class="mt-2"
                                (click)="openBroadcastAddDrawer()">
                                <span nz-icon nzType="plus" nzTheme="outline"></span>Add New
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div nz-col nzSpan="24" *ngIf="isModelLoaded && hasBroadcastList && model && model.length > 0">
            <div class="d-flex justify-content-end cursor-pointer">
                <nz-form-item class="sort-by-dropdown">
                    <nz-form-control>
                        <nz-select nzPlaceHolder="Sort By" [(ngModel)]="sortOrder" (ngModelChange)="sortBroadcast()">
                            <nz-option [nzValue]="'ascending'" [nzLabel]="'Ascending'"></nz-option>
                            <nz-option [nzValue]="'descending'" [nzLabel]="'Descending'"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <nz-spin [nzSpinning]="isLoading">

            <div *ngIf="isModelLoaded && model && model.length > 0">
                <div nz-row *ngFor="let item of model" [nzGutter]="[16,16]" class="list-item mb-2 pt-1 cursor-pointer"
                    (click)="selectBroadcast(item)">
                    <div nz-col nzXs="20" nzSm="16" nzMd="16" nzLg="16" nzXl="16">
                        <div class="d-flex align-items-center overflow-hidden">
                            <div class="cursor-pointer mr-3"
                                [ngClass]="{'list-img-wrapper': !item.urlError, 'list-default-wrapper': item.urlError}"
                                (click)="selectBroadcast(item)">
                                <img *ngIf="!item.urlError" [src]="item.defaultUserMediaAppUrl" alt=""
                                    (error)="onError(item)" [ngClass]="{'hidden': item.urlError}">
                            </div>
                            <div class="cursor-pointer text-ellipsis" (click)="selectBroadcast(item)">{{item.name}}

                                <div class="fs-12 text-primary" *ngIf="item.id == newBroadcastId">New</div>
                            </div>
                        </div>
                    </div>
                    <div nz-col nzXs="4" nzSm="8" nzMd="8" nzLg="8" nzXl="8" class="align-items-center">

                        <span nz-icon nzType="close-circle" nzTheme="fill" *ngIf="!item.isEnabled"
                            class="status-icon-danger mr-2"></span>

                        <span nz-icon nzType="check-circle" nzTheme="fill"
                            *ngIf="item.isEnabled && item.broadcastStatusName == 'Broadcasting'"
                            class="status-icon-success mr-2"></span>

                        <span nz-icon nzType="minus-circle" nzTheme="fill"
                            *ngIf="item.isEnabled && item.broadcastStatusName == 'Expired'"
                            class="status-icon-danger mr-2"></span>

                        <span nz-icon nzType="clock-circle" nzTheme="fill"
                            *ngIf="item.isEnabled && item.broadcastStatusName == 'Scheduled'"
                            class="status-icon-primary mr-2"></span>

                        <span nz-icon nzType="clock-circle" nzTheme="fill"
                            *ngIf="item.isEnabled && item.broadcastStatusName == 'Triggered'"
                            class="status-icon-scheduled mr-2"></span>

                        <span *ngIf="!item.isEnabled" class="disp-web">In-active</span>
                        <span *ngIf="item.isEnabled" class="disp-web">{{item.broadcastStatusName}}</span>
                    </div>
                    <nz-divider class="mt-1 mb-0"></nz-divider>
                </div>
            </div>
            <div *ngIf="isModelLoaded && model && model.length == 0 && hasBroadcastList" class="empty-list-container">
                <span class="text-muted fs-14">No broadcast match your search</span>
            </div>
            <div *ngIf="isModelLoaded && !hasBroadcastList" class="empty-list-container">
                <span class="text-muted fs-14">No broadcast found</span>
            </div>
        </nz-spin>
        <nz-drawer #drawer [nzClosable]="false" [nzVisible]="isDrawerVisisble" nzPlacement="right" (nzOnClose)="close()"
            [nzMask]="false" class="custom-drawer">
            <ng-container *nzDrawerContent>
                <app-broadcast-add (closeDrawer)="closeBroadcastAddDrawer($event)" *ngIf="showBroadcastAddDrawer"
                    [selectedGroupId]="selectedGroupId"></app-broadcast-add>
            </ng-container>
        </nz-drawer>
    </div>
</section>