<section>
    <div class="scroll-container" #scrollContainer (scroll)="onScroll($event)">
        <div nz-row>
            <div nz-col nzSpan="18">
                <div class="heading-fs-18 mb-0">
                    <span nz-icon nzType="arrow-left" nzTheme="outline" class="drawer-back-arrow mr-2 disp-mob"
                        (click)="sendPlayList()"></span>All Playlist
                </div>
            </div>
            <div nz-col nzSpan="6" class="text-right pt-2 disp-web">
                <span nz-icon nzType="close" nzTheme="outline" class="cursor-pointer" (click)="sendPlayList()"></span>
            </div>
            <div nz-col nzSpan="24">
                <nz-divider></nz-divider>
            </div>
        </div>
        <div nz-row>
            <div nz-col nzSpan="24">
                <form nz-form>
                    <div class="d-flex">
                        <div class="w-100 mr-3">
                            <div class="input-container mt-2">
                                <nz-form-item class="mb-0">
                                    <nz-form-control class="drawer-search-input">
                                        <nz-input-group [nzPrefix]="prefixIcon" [nzSuffix]="suffixIcon">
                                            <input nz-input placeholder="Search Playlist" name="filterKey"
                                                [(ngModel)]="filterModel.filterKey" (keydown.enter)="onSearch()"
                                                (ngModelChange)="filterKeyChanged()" [maxlength]="100" [disabled]="!hasDisplayList" 
                                                appAlphaNumericsLevelThree />
                                        </nz-input-group>
                                        <ng-template #suffixIcon>
                                            <span nz-icon nzType="close" nzTheme="outline" *ngIf="filterModel.filterKey"
                                                (click)="clearSearch()" class="clear-search cursor-pointer"></span>
                                        </ng-template>
                                        <ng-template #prefixIcon>
                                            <span nz-icon nzType="search" nzTheme="outline" (click)="onSearch()"
                                                class="cursor-pointer"></span>
                                        </ng-template>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                        <div class="text-right mt-1">
                            <button nz-button nzType="primary" class="mt-2" type="button"
                                (click)="openDisplaylistUpsertDrawer()">
                                <span nz-icon nzType="plus" nzTheme="outline"></span>Add New
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <form (ngSubmit)="submit()">
            <div nz-col nzSpan="24">
                <div nz-row class="mt-2">
                    <div nz-col nzSpan="8" class="mt-1" *ngIf="isModelLoaded && hasDisplayList && model.length > 0">
                        <label nz-checkbox [(ngModel)]="allPlaylistSelected" name="selectAll"
                            (ngModelChange)="selectAllPlaylist($event)" class="align-center selected-square ">Select
                            all</label>
                    </div>
                    <div nz-col nzSpan="16">
                        <div class="d-flex justify-content-end cursor-pointer action_sidebar_wrapper">
                            <nz-form-item class="sort-by-dropdown"
                                *ngIf="isModelLoaded && hasDisplayList && model.length > 0">
                                <nz-form-control>
                                    <nz-select nzPlaceHolder="Sort By" [(ngModel)]="sortOrder" name="sortOrder"
                                        (ngModelChange)="sortPlaylist()">
                                        <nz-option [nzValue]="'ascending'" nzLabel="Ascending"></nz-option>
                                        <nz-option [nzValue]="'descending'" nzLabel="Descending"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                            <div class="align-center disp-web ml-3 action_sidebar_wrapper"
                                *ngIf="isModelLoaded && hasDisplayList && model.length > 0">
                                <button type="submit" nz-button (click)="sendPlayList()"
                                    [ngClass]="{'selected-button': isPlaylistSelected}"
                                    [disabled]="!isPlaylistSelected">Add to
                                    Media</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nz-spin [nzSpinning]="isLoading" class="mobOne-action-m-b">


                <div *ngIf="isModelLoaded && model && model.length > 0">
                    <div nz-row *ngFor="let item of model" [nzGutter]="[16, 16]" class="list-item mb-2 pt-1"
                        (click)="item.isSelected = !item.isSelected; togglePlaylist(item)">
                        <div nz-col nzSpan="16">
                            <div class="d-flex cursor-pointer">
                                <div class="align-items-center mr-3">
                                    <label nz-checkbox [(ngModel)]="item.isSelected" (ngModelChange)="togglePlaylist(item)"
                                        name="selectDisplayList{{item.name}}" #selectPlaylist="ngModel"></label>
                                </div>
                                <div class="d-flex align-items-center overflow-hidden">
                                    <div class="mr-3"
                                        [ngClass]="{'list-img-wrapper': !item.urlError, 'list-default-wrapper': item.urlError}">
                                        <img *ngIf="!item.urlError" [src]="item.defaultUserMediaAppUrl" alt=""
                                            (error)="onError(item)" [ngClass]="{'hidden': item.urlError}">
                                    </div>
                                    <span class="text-ellipsis">{{item.name}}</span>
                                </div>
                            </div>
                        </div>
                        <div nz-col nzSpan="8" class="align-items-center">
                            {{item.userMediaAppCount}} media
                        </div>
                        <nz-divider class="mt-1 mb-0"></nz-divider>
                    </div>
                </div>
                <div *ngIf="isModelLoaded && model && model.length == 0 && hasDisplayList" class="empty-list-container">
                    <span class="text-muted fs-14">No playlist match your search</span>
                </div>
                <div *ngIf="isModelLoaded && !hasDisplayList" class="empty-list-container">
                    <span class="text-muted fs-14">No playlist found</span>
                </div>
            </nz-spin>
            <nz-drawer #drawer [nzClosable]="false" nzPlacement="right" (nzOnClose)="close()" class="custom-drawer">
                <ng-container *nzDrawerContent>
                    <app-display-list-upsert (closeDrawer)="closeDisplayUpsertDrawer($event)"
                        [addFromMedia]="addFromMedia" *ngIf="addDisplayList"
                        [selectedGroupId]="selectedGroupId"></app-display-list-upsert>
                </ng-container>
            </nz-drawer>
        </form>
        <div nz-row class="disp-mob mobOne-action-bar action_sidebar_wrapper">
            <div nz-col nzSpan="24" class="text-right">
                <nz-card>
                    <div nz-row [nzGutter]="[16]">
                        <div nz-col nzSpan="24">
                            <button type="submit" nz-button class="btn-full" (click)="sendPlayList()"
                                [ngClass]="{'selected-button': isPlaylistSelected}"
                                [disabled]="!isPlaylistSelected">Add to
                                Media</button>
                        </div>
                    </div>
                </nz-card>
            </div>
        </div>
    </div>
</section>
