export class SubscriptionPlanDetailModel {
    id: string;
    name: string;
    description: string;
    price: number;
    perMonthPrice: any;
    period: number;
    periodUnit: string;
    pricingModel: string;
    pricePerDay: number;
}