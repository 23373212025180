import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { ScreenPurchaseLogModel } from '../models';

@Injectable()

export class ScreenPurchaseLogService {

    private apiEndPoint = environment.apiBaseUrl + 'api/screenPurchaseLog/';

    constructor(private http: HttpClient) { }

    add(model: ScreenPurchaseLogModel) {
        return this.http.post(this.apiEndPoint, model);
    }
}
