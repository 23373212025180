import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FilterModel, MediaAppDetailModel, MediaListItemModel, PagedResultModel } from 'src/app/shared/models';
import { BaseService, MediaAppService } from 'src/app/shared/services';
import { NzDrawerComponent } from 'ng-zorro-antd/drawer';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-media-store',
  templateUrl: './media-store.component.html',
  styleUrls: ['./media-store.component.scss'],
})

export class MediaStoreComponent implements OnInit, OnDestroy {
  @ViewChild('drawer') drawer: NzDrawerComponent;
  @Input() selectedGroupId: number;
  @Input() displayListId: number;
  @Output() closeDrawer: EventEmitter<{ media: Array<MediaListItemModel>, mediaAdded: boolean }> = new EventEmitter<{ media: Array<MediaListItemModel>, mediaAdded: boolean }>();

  isDrawerVisible = false;
  mediaAppFilterModel = new FilterModel();
  mediaApps = new Array<MediaAppDetailModel>();
  isLoading: boolean = false;
  isWebpageDrawerVisible = false;
  mediaAppId: number;
  isDrawerVisisble = false;
  isVimeoDrawerVisisble = false;
  isYouTubeDrawerVisible = false;
  isWeatherDrawerVisible = false;
  isClockDrawerVisisble = false;
  isPowerPointDrawerVisible = false;
  isGoogleSlideDrawerVisible = false;
  isBulkUploadDrawerVisible = false;
  isCanvaDrawerVisible = false;
  subscriptions = new Array<Subscription>();

  constructor(
    private service: MediaAppService,
    private baseService: BaseService
  ) { }

  ngOnInit() {
    this.loadMediaAppList();
  }

  loadMediaAppList(): void {
    this.isLoading = true;
    const requestSubscription =
    this.service.getPagedResult(this.mediaAppFilterModel).subscribe({
      next: (response: PagedResultModel<MediaAppDetailModel>) => {
        if (response && response.items.length > 0) {
          this.mediaApps = response.items.filter((x) => x.showInManager);
        }
        this.mediaApps.forEach((x) => {
          x.iconUrl = x.iconUrl ?? this.generateMediaIconUrl(x.typeString);
        });
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.baseService.processErrorResponse(error);
      },
    });

    this.subscriptions.push(requestSubscription);
  }

  generateMediaIconUrl(mediaType: string): string {
    return `./assets/images/media-types/icons/v2/${mediaType}.svg`;
  }

  openUserMediaWebPageDrawer() {
    this.drawer.open();
    this.isWebpageDrawerVisible = true;
  }

  closeUserMediaWebpageDrawer(event: any) {
    this.drawer.close();
    this.isWebpageDrawerVisible = false;
    if (event.mediaAdded) {
      this.closeDrawer.emit({media: event.media, mediaAdded: true})
    }
  }

  openUserMediaAppVimeoDrawer() {
    this.drawer.open();
    this.isVimeoDrawerVisisble = true;
  }

  closeUserMediaAppVimeoDrawer(event: any) {
    this.drawer.close();
    this.isVimeoDrawerVisisble = false;
    if (event.mediaAdded) {
      this.closeDrawer.emit({media: event.media, mediaAdded: true})
    }
  }

  openUserMediaAppYoutubeDrawer() {
    this.drawer.open();
    this.isYouTubeDrawerVisible = true;
  }

  closeUserMediaAppYouTubeDrawer(event: any) {
    this.drawer.close();
    this.isYouTubeDrawerVisible = false;
    if (event.mediaAdded) {
      this.closeDrawer.emit({media: event.media, mediaAdded: true})
    }
  }

  openUserMediaAppWeatherDrawer() {
    this.drawer.open();
    this.isWeatherDrawerVisible = true;
  }

  closeUserMediaAppWeatherDrawer(event: any) {
    this.drawer.close();
    this.isWeatherDrawerVisible = false;
    if (event.mediaAdded) {
      this.closeDrawer.emit({media: event.media, mediaAdded: true})
    }
  }

  openUserMediaAppClockDrawer() {
    this.drawer.open();
    this.isClockDrawerVisisble = true;
  }

  closeUserMediaAppClockDrawer(event: any) {
    this.drawer.close();
    this.isClockDrawerVisisble = false;
    if (event.mediaAdded) {
      this.closeDrawer.emit({media: event.media, mediaAdded: true})
    }
  }

  openUserMediaAppPowerPointDrawer() {
   this.isPowerPointDrawerVisible = true;
   this.drawer.open();
  }

  closeUserMediaAppPowerPointDrawer(event: any) {
    this.drawer.close();
    this.isPowerPointDrawerVisible = false;
    if (event.mediaAdded) {
      this.closeDrawer.emit({media: event.media, mediaAdded: true})
    }
  }

  openUserMediaAppGoogleSlideDrawer() {
    this.drawer.open();
    this.isGoogleSlideDrawerVisible = true;
  }

  closeUserMediaAppGoogleSliderDrawer(event: any) {
    this.drawer.close();
    this.isGoogleSlideDrawerVisible = false;
    if (event.mediaAdded) {
      this.closeDrawer.emit({media: event.media, mediaAdded: true})
    }
  }

  openUserMediaAppBulkUploadDrawer() {
    this.drawer.open();
    this.isBulkUploadDrawerVisible = true;
  }

  closeUserMediaAppBulkUploadDrawer(event: any) {
    this.drawer.close();
    this.isBulkUploadDrawerVisible = false;
    if (event.mediaAdded) {
      this.closeDrawer.emit({media: event.media, mediaAdded: true})
    }
  }

  openUserMediaAppCanvaDrawer() {
    this.drawer.open();
    this.isCanvaDrawerVisible = true;
  }

  closeUserMediaAppCanvaDrawer(event: any) {
    this.drawer.close();
    this.isCanvaDrawerVisible = false;
    if (event.mediaAdded) {
      this.closeDrawer.emit({media: event.media, mediaAdded: true})
    }
  }

  close() {
    this.closeDrawer.emit({ media: new Array<MediaListItemModel>(), mediaAdded: false });
  }


  addUserMediaApp(mediaApp) {
    this.mediaAppId = mediaApp.id;

    switch (mediaApp.type) {
      case 1:
        this.openUserMediaWebPageDrawer();
        break;
      case 2:
        this.openUserMediaAppPowerPointDrawer();
        break;
      case 3:
        this.openUserMediaAppGoogleSlideDrawer();
        break;
      case 5:
        this.openUserMediaAppClockDrawer();
        break;
      case 6:
        this.openUserMediaAppWeatherDrawer();
        break;
      case 11:
        this.openUserMediaAppVimeoDrawer();
        break;
      case 8:
        this.openUserMediaAppCanvaDrawer();
        break;
      case 9:
        this.openUserMediaAppYoutubeDrawer();
        break;
      case 12:
        this.openUserMediaAppBulkUploadDrawer();
        break;

      default:
        console.log("Default case executed.");
    }
  }

  onError(item: MediaAppDetailModel): void {
    item.urlError = true;
  }

  ngOnDestroy() {
    this.isLoading = false;
    this.subscriptions.forEach(s => { s.unsubscribe(); });
  }    
  
}
