<section>
    <div class="d-flex justify-content-between mb-3">
        <div class="heading-fs-16">Preview</div>
        <div class="preview-chip" *ngIf="lastUpdated"><span class="text-muted">Last updated at:</span>
            {{lastUpdated}}</div>
    </div>
    <div nz-col nzSpan="24">
        <nz-divider></nz-divider>
    </div>

    <div nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="justify-content-center p-5">
            <div
                [ngClass]="showDefault && !isLoading ? 'preview-display-static-container' : 'preview-display-dynamic-container'">
                <div *ngIf="isLoading">
                    <span nz-icon [nzType]="'loading'" class="icon-primary"></span><br>
                    <span>Loading...</span>
                </div>
                <object *ngIf="type === constants.mediaAppType.pdf" type="application/pdf" (error)="onError()"
                    [data]="dataUrl | safe" class="preview-img cursor-pointer" [ngClass]="showDefault ? 'd-none' : ''"
                    (load)="onContentLoaded()" (dblclick)="openContent()"></object>
                <video *ngIf="type === constants.mediaAppType.video" [src]="dataUrl" autoplay muted (error)="onError()"
                    loop="1" class="preview-img cursor-pointer" [ngClass]="showDefault ? 'd-none' : ''"
                    (loadeddata)="onContentLoaded()" (dblclick)="openContent()"></video>
                <img *ngIf="showDefault && !isLoading" [src]="contentUrl" alt="preview-image" />
                <img *ngIf="type !== constants.mediaAppType.video && type !== constants.mediaAppType.pdf"
                    [src]="dataUrl" class="preview-img cursor-pointer" [ngClass]="showDefault ? 'd-none' : ''"
                    alt="preview-image" (load)="onContentLoaded()" (dblclick)="openContent()" (error)="onError()">
                <div class="container-notch" *ngIf="!showDefault || isLoading"></div>
            </div>
        </div>
        <div nz-col nzSpan="24" class="preview-content" *ngIf="showDefault">
            <span nz-icon nzType="exclamation-circle" nzTheme="fill" class="mr-2"></span> Upload media to see a preview
        </div>
    </div>
</section>