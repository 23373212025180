import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { MediaListItemModel } from "src/app/shared/models";
import { BaseService, UserMediaAppService } from "src/app/shared/services";
import { AppUtils } from "src/helpers";
import { FilterModel } from "src/models";

@Component({
    selector: 'app-media-list',
    templateUrl: './media-list.component.html',
    styleUrls: ['./media-list.component.scss']
})

export class MediaListComponent implements AfterViewInit, OnInit, AfterViewInit, OnDestroy {
    @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;
    @Input() groupId: number;
    @Output() closeDrawer: EventEmitter<{ selectedMedia: MediaListItemModel, updateValue: boolean }> = new EventEmitter<{ selectedMedia: MediaListItemModel, updateValue: boolean }>();
    userMediaApps = new Array<MediaListItemModel>;
    selectedMedia = new MediaListItemModel();
    isModelLoaded = false;
    selectedValue: string;
    allMediaSelected = false;
    isLoading: boolean = false;
    sortOrder: string;
    filterKey: string;
    isDrawerVisible = false;
    showMediaDrawer = false;
    newMediaId = 0;
    newMedia = new Array<MediaListItemModel>();
    newMediaIds = new Array<number>();
    hasMediaList: boolean = true;
    hasMore = true;
    recordsToSkip: number;
    pageSize: number;
    loadingMore: boolean;
    totalRecords: number;
    filterModel = new FilterModel();
    subscriptions = new Array<Subscription>();

    constructor(private service: UserMediaAppService,
        private baseService: BaseService,
        private appUtils: AppUtils
    ) {
        if (!this.groupId || this.groupId === -1) {
            this.groupId = null;
        }
        this.filterModel.sortBy = 'Title';
        this.filterModel.sortDir = 'asc';
    }

    ngAfterViewInit() {
        this.scrollContainer.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
    }

    ngOnInit(){
        this.getMediaList(true);
    }

    onScroll(event: any) {
        const element = this.scrollContainer.nativeElement;
        const scrollTop = element.scrollTop;
        const scrollHeight = element.scrollHeight;
        const clientHeight = element.clientHeight;

        if (scrollTop + clientHeight >= scrollHeight * 0.8 && this.hasMore && !this.loadingMore) {
            this.getMoreRecords();
        }
    }

    getMediaList(initialLoading = false, limit = 15): void {
        this.isLoading = true;
        this.isModelLoaded = false;
        this.filterModel.limit = limit;
        this.filterModel.offset = 0;

        this.totalRecords = 0;
        const requestSubscription =
        this.service.getList(this.groupId, this.filterModel)
            .subscribe({
                next: (response: any) => {
                    if (response) {
                        this.userMediaApps = response;
                        this.totalRecords = response.length;
                        if (this.userMediaApps.length > 0) {
                            this.userMediaApps.forEach(item => {
                                item.iconUrl = item.url ?? this.appUtils.getBorderlessMediaAppIconUrl(item.mediaAppType);
                            });
                        }
                    }

                    if (initialLoading) {
                        this.hasMediaList = this.userMediaApps.length > 0;
                    }

                    const filteredElements = this.userMediaApps.filter(x => this.newMediaIds.includes(x.id));
                    if (filteredElements && filteredElements.length > 0){
                        this.userMediaApps = this.userMediaApps.filter(x => !this.newMediaIds.includes(x.id));
                    }

                    if (this.newMedia && this.newMedia.length > 0) {
                        this.newMedia.forEach((x) => {
                            let userAppMedia = new MediaListItemModel();
                            userAppMedia.id = x.id;
                            userAppMedia.mediaAppTitle = x.mediaAppTitle;
                            userAppMedia.mediaAppType = x.mediaAppType;
                            userAppMedia.status = x.status;
                            userAppMedia.title = x.title;
                            userAppMedia.iconUrl = x.iconUrl;
                            userAppMedia.videoEncodingStatus = x.videoEncodingStatus;
                            userAppMedia.lengthInSeconds = x.lengthInSeconds;
                            userAppMedia.isSelected = true;
                            userAppMedia.isEnabled = x.isEnabled;
                            userAppMedia.isScheduled = x.isScheduled;
                            userAppMedia.isSelected = true;
                            this.userMediaApps.unshift(userAppMedia);
                        });
                    }

                    this.isLoading = false;
                    this.isModelLoaded = true;
                },
                error: (error) => {
                    this.isLoading = false;
                    this.isModelLoaded = true;
                    this.baseService.processErrorResponse(error);
                }
            })
            this.subscriptions.push(requestSubscription);
    }

    getMoreRecords(): void {
        if (this.loadingMore) { return; }
        this.filterModel.limit = 5;
        this.filterModel.offset = this.totalRecords;
        
        this.loadingMore = true;
        this.isLoading = true;
        this.service.getList(this.groupId, this.filterModel)
            .subscribe({
                next: (response: any) => {
                    if (response && response.length > 0) {
                        response.forEach(item => {
                            if(!this.userMediaApps.some(y => y.id == item.id)){
                            item.iconUrl = item.url ?? this.appUtils.getBorderlessMediaAppIconUrl(item.mediaAppType);
                            this.userMediaApps.push(item);
                            }
                        });
                        this.totalRecords += response.length;
                    } else {
                        this.hasMore = false;
                    }
                    this.isLoading = false;
                    this.loadingMore = false;
                },
                error: (error) => {
                    this.isLoading = false;
                    this.loadingMore = false;
                    this.baseService.processErrorResponse(error);
                }
            })
    }

    sortMedia() {
        if (this.sortOrder === 'ascending') {
            this.filterModel.sortBy = 'Title';
            this.filterModel.sortDir = 'asc';
        } else if (this.sortOrder === 'descending') {
            this.filterModel.sortBy = 'Title';
            this.filterModel.sortDir = 'desc';
        }

        this.getMediaList(false, this.totalRecords);
    }

    onError(item: MediaListItemModel): void {
        item.iconUrl = this.appUtils.getBorderlessMediaAppIconUrl(item.mediaAppType);
    }

    onSearch() {
       if(this.filterModel.filterKey){
        this.getMediaList();
       }
    }

    clearSearch() {
        this.filterModel.filterKey = '';
        this.getMediaList();
    }

    filterKeyChanged() {
        if (this.appUtils.isNullOrEmpty(this.filterModel.filterKey)) {
            this.getMediaList();
        }
    }

    selectMedia(item: MediaListItemModel): void {
        this.closeDrawer.emit({ selectedMedia: item, updateValue: true });
    }

    cancel(): void {
        this.closeDrawer.emit({ selectedMedia: this.selectedMedia, updateValue: false });
    }

    openMediaStoreDrawer() {
        this.isDrawerVisible = true;
        this.showMediaDrawer = true;
    }

    closeMediaStoreDrawer(event: any) {
        if (event.mediaAdded) {
            this.newMediaId = event.mediaId;
            this.newMedia = event.media;
            this.newMediaIds = event.media.map(x => x.id);
        }
        this.isDrawerVisible = false;
        this.showMediaDrawer = false;
        this.getMediaList(true);
    }

    close() {
        this.isDrawerVisible = false;
    }

    ngOnDestroy() {
        this.isLoading = false;
        this.subscriptions.forEach(s => { s.unsubscribe(); });
    }    
}