import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class ListenerService {

    public topNavListener = new Subject<any>();
    public layoutListener = new Subject<any>();
    public applicationUpdateListener = new Subject<any>();
    public reloadWebDataListener = new Subject<any>();
    public toggleSidebarListener = new Subject<any>();
    public subscriptionChangeListener = new Subject<any>();
    public groupOwnerChangeListener = new Subject<any>();
    public reloadGroupDataListener = new Subject<any>();
    public tourStepListener = new Subject<any>();
    public switchToMySubscriptionListener = new Subject<any>();
    public tourOverlayVisibilityChangeListener = new Subject<any>();
    public canvaDesignPublishedListener = new Subject<any>();
    public reloadBroadcastDataListener = new Subject<any>();
    public reloadUserMediaAppDataListener = new Subject<any>();
    public displayStatusChangedListener = new Subject<any>();
    public subscriptionUpdatedListener = new Subject<any>();
    public profileUpdateListener = new Subject<any>();
    public disableGroupSelectionListener = new Subject<any>();
    public appThemeChangedListener = new Subject<any>();

    private collapsedSubject = new BehaviorSubject<boolean>(false);
    isCollapsed$ = this.collapsedSubject.asObservable();

    toggleCollapse(isCollapsed: boolean): void {
        this.collapsedSubject.next(isCollapsed);
    }

    get listenTopNav() {
        return this.topNavListener.asObservable();
    }

    get listenLayout() {
        return this.layoutListener.asObservable();
    }

    get listenApplicationUpdate() {
        return this.applicationUpdateListener.asObservable();
    }

    get listenReloadWebData() {
        return this.reloadWebDataListener.asObservable();
    }

    get listenToggleSidebar() {
        return this.toggleSidebarListener.asObservable();
    }

    get listenSubscriptionChange() {
        return this.subscriptionChangeListener.asObservable();
    }

    get listenGroupOwnerChange() {
        return this.groupOwnerChangeListener.asObservable();
    }

    get listenReloadGroupData() {
        return this.reloadGroupDataListener.asObservable();
    }

    get listenTourStep() {
        return this.tourStepListener.asObservable();
    }

    get listenSwitchToMySubscription() {
        return this.switchToMySubscriptionListener.asObservable();
    }

    get listenTourOverlayVisibilityChange() {
        return this.tourOverlayVisibilityChangeListener.asObservable();
    }

    get listenCanvaDesignPublished() {
        return this.canvaDesignPublishedListener.asObservable();
    }

    get listenReloadBroadcastData() {
        return this.reloadBroadcastDataListener.asObservable();
    }

    get listenReloadUserMediaAppData() {
        return this.reloadUserMediaAppDataListener.asObservable();
    }

    get listenDisplayStatusChanged() {
        return this.displayStatusChangedListener.asObservable();
    }

    get listenSubscriptionUpdated() {
        return this.subscriptionUpdatedListener.asObservable();
    }

    get listenProfileUpdated() {
        return this.profileUpdateListener.asObservable();
    }

    get listenDisableGroupSelection() {
        return this.disableGroupSelectionListener.asObservable();
    }

    get appThemeChange(){
        return this.appThemeChangedListener.asObservable();
    }
}
