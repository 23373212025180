<section>
    <nz-spin [nzSpinning]="isLoading">
        <form #form="ngForm" nz-form (ngSubmit)="submit()">
            <div class="drawer-form-header-empty"></div>
            <div nz-row class="drawer-form-header">
                <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                    <div class="heading-fs-18 mb-0">
                        <span nz-icon nzType="arrow-left" nzTheme="outline" class="drawer-back-arrow mr-2 disp-mob"
                            (click)="cancel()"></span>
                        New Media
                    </div>
                </div>
                <div nz-col nzSpan="12" class="text-right disp-web">
                    <button nz-button class="mr-3" (click)="cancel()">Cancel</button>
                    <button nz-button nzType="primary" type="submit" [disabled]="form.invalid">Save</button>
                </div>
                <div nz-col nzSpan="24">
                    <nz-divider></nz-divider>
                </div>
            </div>

            <div nz-row class="mb-3">
                <div nz-col nzSpan="24">
                    <div class="media-type-header">
                        <div nz-row>
                            <div nz-col nzLg="12" nzMd="12" nzXs="12" nzSm="12" class="content-center">
                                <div class="align-items-center">
                                    <img class="media-type-header-img-wrapper" [src]="mediaAppModel.iconUrl"
                                        alt="media-type">

                                    <span class="ml-2 fs-16 fw-bold">{{mediaAppModel.title}}</span>
                                </div>
                            </div>
                            <div nz-col nzLg="12" nzMd="12" nzXs="12" nzSm="12" class="media-type-header-content">
                                <app-content-text-view
                                    [contentType]="constants.pageContentType.mediaAppBulkUploadTop"></app-content-text-view>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div nz-row class="mobOne-action-m-b">
                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control class="mb-0">
                                <nz-form-label [nzRequired]="true">
                                    <span class="text-ellipsis"> Media name <span class="text-muted"> (File name prefix
                                            will
                                            appear before each file name)</span>
                                    </span>
                                    <span nz-icon nzType="exclamation-circle" nzTheme="outline" class="label-icon"
                                        nz-tooltip
                                        nzTooltipTitle="Enter a name for your media files. This name will be used as a prefix for each file uploaded, helping you organize and identify your files easily."></span>
                                </nz-form-label>
                                <nz-input-group>
                                    <input nz-input required placeholder="Give your media a name" [minlength]="1"
                                        [maxlength]="100" [(ngModel)]="model.prefix" name="title" #title="ngModel"
                                        appAlphaNumericsLevelThree />
                                </nz-input-group>
                                <div *ngIf="form.submitted || title?.touched || title?.dirty"
                                    class="validation-error-message">
                                    <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                        class="validation-icon mt-1" *ngIf="title?.errors"></span>
                                    <span *ngIf="title?.errors?.['required']" class="ml-1"> Media
                                        name is required. </span>
                                </div>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>


                <div nz-col nzSpan="24" class="mt-4 mb-3" *ngIf="model && !model.id">
                    <nz-upload nzType="drag" [nzAccept]="acceptFileType" [nzMultiple]="true"
                        [nzBeforeUpload]="beforeUpload">
                        <div class="ant-upload-drag-icon mb-2">
                            <img class="lightmode_img" src="./assets/images/media-types/icons/v2/BulkUpload.svg"
                                alt="media-type">
                            <img class="darkmode_img" src="./assets/images/media-types/icons/v2/BulkUpload-darkmode.svg"
                                alt="media-type">
                        </div>
                        <p class="ant-upload-text heading-fs-16">Upload images, videos, and pdfs</p>
                        <p class="ant-upload-hint">
                            Drag here or <span class="upload-text">Upload</span> any media up to 100
                            files
                        </p>
                    </nz-upload>
                </div>


                <div nz-col nzSpan="24" class="mb-3 mt-3" *ngIf="model.userMediaApps.length > 0">

                    <div nz-row class="mb-3">
                        <div nz-col nzSpan="12">Media</div>
                        <div nz-col nzSpan="12" class="text-right" *ngIf="model.userMediaApps.length > 0">{{model.userMediaApps.length}}
                            <span *ngIf="model.userMediaApps.length == 1">file</span>
                            <span *ngIf="model.userMediaApps.length > 1">files</span>
                        </div>
                    </div>

                    <div *ngIf="(fileUploadPercent > 0 && fileUploadPercent < 100) || showProgressBar">
                        <nz-progress [nzPercent]="fileUploadPercent"></nz-progress>
                    </div>

                    <div class="list-container" class="mt-1 gbl_media_card">
                        <div nz-row *ngFor="let item of model.userMediaApps; let i = index">
                            <div nz-col nzSpan="24" *ngIf="item.status != constants.mediaUploadStatus.rejected">
                                <nz-card class="list-card mb-2"
                                    [ngClass]="{'upload-success': item.status === constants.mediaUploadStatus.completed, 'upload-pending' : item.status === constants.mediaUploadStatus.inProgress || item.status === constants.mediaUploadStatus.pending }">
                                    <div nz-row [nzGutter]="[16]">
                                        <div nz-col nzXXl="16" nzXl="16" nzLg="14" nzMd="14" nzSm="14" nzXs="14">
                                            <div nz-row>
                                                <div class="d-flex overflow-hidden align-items-center">
                                                    <div class="mr-3 media-img-wrapper">
                                                        <img *ngIf="item.mediaAppId === imageMediaAppId"
                                                            [src]="imageIconUrl" alt="icon">
                                                        <img *ngIf="item.mediaAppId === videoMediaAppId"
                                                            [src]="videoIconUrl" alt="video-icon">
                                                        <img *ngIf="item.mediaAppId === pdfMediaAppId"
                                                            [src]="pdfIconUrl" alt="pdf-icon">
                                                    </div>
                                                    <div class="text-wrapper"
                                                        *ngIf="item.mediaAppId === imageMediaAppId">
                                                        <span class="fw-bold">{{item.image.originalFileName}}</span>
                                                        <div class="size-wrapper">{{item.image.size}}</div>
                                                    </div>
                                                    <div class="text-wrapper"
                                                        *ngIf="item.mediaAppId === videoMediaAppId">
                                                        <span class="fw-bold">{{item.video.originalFileName}}</span>
                                                        <div class="size-wrapper">{{item.video.size}}</div>
                                                    </div>
                                                    <div class="text-wrapper" *ngIf="item.mediaAppId === pdfMediaAppId">
                                                        <span class="fw-bold">{{item.pdf.originalFileName}}</span>
                                                        <div class="size-wrapper">{{item.pdf.size}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div nz-col nzXXl="4" nzXl="4" nzLg="6" nzMd="6" nzSm="6" nzXs="6" class="align-items-center"
                                            *ngIf="item.status != constants.mediaUploadStatus.rejected">
                                            <span *ngIf="item.mediaAppId === imageMediaAppId">Image</span>
                                            <span *ngIf="item.mediaAppId === videoMediaAppId">Video</span>
                                            <span *ngIf="item.mediaAppId === pdfMediaAppId">Pdf</span>
                                        </div>
                                        <div nz-col nzXXl="4" nzXl="4" nzLg="4" nzMd="4" nzSm="4" nzXs="4"
                                            class="align-items-center justify-content-end"
                                            *ngIf="item.status === constants.mediaUploadStatus.completed">
                                            <span nz-icon nzType="check" nzTheme="outline"
                                                class="cursor-pointer icon-success ml-2"></span>
                                        </div>
                                        <div nz-col nzXXl="4" nzXl="4" nzLg="4" nzMd="4" nzSm="4" nzXs="4"
                                            class="align-items-center justify-content-end"
                                            *ngIf="item.status === constants.mediaUploadStatus.pending">
                                            <span nz-icon nzType="delete" nzTheme="outline" *ngIf="isLoading"
                                                class="action-btn ml-2"></span>
                                            <span nz-icon nzType="delete" nzTheme="outline"
                                                class="cursor-pointer action-btn ml-2" *ngIf="!isLoading"
                                                (click)="removeUserMediaApp(i)"></span>
                                        </div>
                                        <div nz-col nzXXl="4" nzXl="4" nzLg="4" nzMd="4" nzSm="4" nzXs="4"
                                            class="align-items-center justify-content-end"
                                            *ngIf="item.status === constants.mediaUploadStatus.inProgress">

                                            <span nz-icon [nzType]="'loading'" class="icon-primary"></span>
                                        </div>
                                    </div>
                                </nz-card>
                            </div>
                        </div>

                        <div nz-row *ngFor="let item of model.userMediaApps; let i = index">
                            <div nz-col nzSpan="24" *ngIf="item.status == constants.mediaUploadStatus.rejected">
                                <nz-card class="list-card upload-danger mb-2">
                                    <div nz-row [nzGutter]="[16]">
                                        <div nz-col nzXXl="16" nzXl="16" nzLg="14" nzMd="14" nzSm="14" nzXs="14">
                                            <div nz-row class="align-items-center">
                                                <div class="d-flex overflow-hidden align-items-center">
                                                    <div class="mr-3">
                                                        <img *ngIf="item.mediaAppId === imageMediaAppId"
                                                            [src]="imageIconUrl" alt="icon">
                                                        <img *ngIf="item.mediaAppId === videoMediaAppId"
                                                            [src]="videoIconUrl" alt="video-icon">
                                                        <img *ngIf="item.mediaAppId === pdfMediaAppId"
                                                            [src]="pdfIconUrl" alt="pdf-icon">
                                                    </div>
                                                    <div class="text-wrapper"
                                                        *ngIf="item.mediaAppId === imageMediaAppId">
                                                        <span class="fw-bold">{{item.image.originalFileName}}</span>
                                                        <div class="size-wrapper">{{item.image.size}}
                                                        </div>
                                                    </div>
                                                    <div class="text-wrapper"
                                                        *ngIf="item.mediaAppId === videoMediaAppId">
                                                        <span class="fw-bold">{{item.video.originalFileName}}</span>
                                                        <div class="size-wrapper">{{item.video.size}}
                                                        </div>
                                                    </div>
                                                    <div class="text-wrapper" *ngIf="item.mediaAppId === pdfMediaAppId">
                                                        <span class="fw-bold">{{item.pdf.originalFileName}}</span>
                                                        <div class="size-wrapper">{{item.pdf.size}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div nz-col nzXXl="4" nzXl="4" nzLg="4" nzMd="4" nzSm="4" nzXs="4" nzSpan="4" class="align-items-center">
                                            <span *ngIf="item.mediaAppId === imageMediaAppId">Image</span>
                                            <span *ngIf="item.mediaAppId === videoMediaAppId">Video</span>
                                            <span *ngIf="item.mediaAppId === pdfMediaAppId">Pdf</span>
                                        </div>
                                        <div nz-col nzXXl="4" nzXl="4" nzLg="6" nzMd="6" nzSm="6" nzXs="6" class="align-items-center justify-content-end">
                                            <span nz-icon nzType="info-circle" nzTheme="outline"
                                                class="mr-1 cursor-pointer" (click)="showErrorMessage(i)"></span>
                                            <span nz-icon nzType="close-circle" nzTheme="outline"
                                                class="cursor-pointer action-btn icon-danger ml-2"
                                                (click)="removeUserMediaApp(i)"></span>
                                        </div>
                                    </div>
                                </nz-card>
                            </div>
                        </div>
                    </div>
                    
                    <div nz-row *ngIf="model && model.id">
                        <div nz-col nzSpan="24">
                            <input type="file" class="d-none" [accept]="acceptFileType" #singleFileUploadElem
                                (change)="onFileSelected($event)" />
                            <button type="button" nz-button nzType="primary" (click)="singleFileUploadElem.click()">
                                <span nz-icon nzType="upload" nzTheme="outline"></span>
                                Change media
                            </button>
                        </div>
                    </div>
                </div>
                <div nz-col nzSpan="24" class="mb-3">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control class="mb-0">
                                <nz-form-label [nzRequired]="model.type === constants.mediaAppType.pdf">Page Change
                                    Duration (in seconds)<span *ngIf="model.type !== constants.mediaAppType.pdf" nz-icon
                                        nzType="exclamation-circle" nzTheme="outline" class="label-icon" nz-tooltip
                                        nzTooltipTitle="only for PDF"></span></nz-form-label>
                                <input rows="2" nz-input name="pageChangeDuration"
                                    placeholder="Enter page change duration" #pageChangeDuration="ngModel"
                                    [(ngModel)]="model.pageChangeDuration" [maxlength]="100"
                                    [required]="model.type === constants.mediaAppType.pdf" appNumbersOnly />
                                <div *ngIf="form.submitted || pageChangeDuration?.touched"
                                    class="validation-error-message">
                                    <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                        class="validation-icon mt-1" *ngIf="pageChangeDuration?.errors"></span>
                                    <span *ngIf="pageChangeDuration?.errors?.['required']" class="ml-1">Page change
                                        duration is required</span>
                                </div>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control [ngClass]="isTyping ? 'hide-max-placeholder' : ''">
                                <nz-form-label>Playlist(s)
                                    <span nz-icon nzType="exclamation-circle" nzTheme="outline" class="label-icon"
                                        nz-tooltip
                                        nzTooltipTitle="You can assign the media to multiple playlists if needed."></span>
                                </nz-form-label>
                                <nz-select nzPlaceHolder="Select Playlist" nzMode="multiple" [nzMaxTagCount]="0"
                                    [nzShowArrow]="true" [nzMaxTagPlaceholder]="isTyping ? null : tagPlaceHolder"
                                    (nzOnSearch)="onSearch($event)" [(ngModel)]="selectedDisplayLists" name="playlists"
                                    [nzShowArrow]="true" #playlists="ngModel" (ngModelChange)="onDisplaySelected()">
                                    <nz-option [nzValue]="displaylist" [nzLabel]="displaylist.name"
                                        *ngFor="let displaylist of displayLists"></nz-option>
                                    <nz-option *ngIf="displayLists.length == 0" nzDisabled
                                        nzLabel="No playlist added."></nz-option>
                                </nz-select>
                                <ng-template #tagPlaceHolder let-selectedList>
                                    <span *ngIf="selectedDisplayLists.length > 0">{{
                                        selectedDisplayLists.length }} <span *ngIf="selectedDisplayLists.length == 1">
                                            Item
                                        </span> <span *ngIf="selectedDisplayLists.length > 1"> Items
                                        </span> selected
                                    </span>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-col nzSpan="24" class="mb-3" *ngIf="selectedDisplayLists.length > 0">
                    <div class="selected-chip-container">
                        <nz-tag *ngFor="let displaylist of selectedDisplayLists"
                            (nzOnClose)="removeDisplayList(displaylist)" nzMode="closeable" class="selected-tag ">
                            <span class="selected-tag-content">{{displaylist.name}}</span></nz-tag>
                    </div>
                </div>
                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-form-label>Group
                                    <span nz-icon nzType="exclamation-circle" nzTheme="outline" class="label-icon"
                                        nz-tooltip
                                        nzTooltipTitle="Groups allow you to share things within manager with other people."></span>
                                </nz-form-label>
                                <nz-select nzPlaceHolder="Select a group" name="group" [(ngModel)]="model.groupId"
                                    (ngModelChange)="onGroupChanged()" [nzDisabled]="true">
                                    <nz-option *ngFor="let item of subscriptionPlans" [nzValue]="item.keyInt"
                                        nzLabel="{{item.value}}"></nz-option>
                                    <nz-option *ngIf="subscriptionPlans.length == 0" nzValue="disabled"
                                        nzLabel="No group Added" nzDisabled></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-form-label>Folder
                                    <span nz-icon nzType="exclamation-circle" nzTheme="outline" class="label-icon"
                                        nz-tooltip nzTooltipTitle="Organize items in Manager with folders."></span>
                                </nz-form-label>
                                <nz-select nzPlaceHolder="Select a folder" name="folder" [(ngModel)]="model.folderId">
                                    <nz-option *ngFor="let item of folders" [nzValue]="item.keyInt"
                                        nzLabel="{{item.value}}"></nz-option>
                                    <nz-option *ngIf="folders.length == 0" nzValue="disabled" nzLabel="No folder Added"
                                        nzDisabled></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <!-- <div nz-row class="mb-3">
                    <div nz-col nzSpan="24">
                        <label class="mr-3" for="" class="fs-16 mb-0 fw-bold">Screen Orientation</label>
                        <span class="ml-3"><nz-switch name="orientation"></nz-switch></span>

                        <div nz-row>
                            <div nz-col nzSpan="24">
                                <nz-divider></nz-divider>
                            </div>
                        </div>
                        <div nz-row [nzGutter]="[16]" class="mt-3">
                            <div nz-col nzSpan="8" class="cursor-pointer">
                                <nz-card class="orientation-card select-option">
                                    <div class="orientation-conatiner">
                                        <img src="./assets/images/orientation-90.png" alt="">
                                    </div>
                                    <div class="mt-3 mb-2 ortn-content text-center">90 Degree</div>
                                </nz-card>
                            </div>
                            <div nz-col nzSpan="8" class="cursor-pointer">
                                <nz-card class="orientation-card select-option">
                                    <div class="orientation-conatiner">
                                        <img src="./assets/images/orientation-180.png" alt="">
                                    </div>
                                    <div class="mt-3 mb-2 ortn-content text-center">180 Degree</div>
                                </nz-card>
                            </div>
                            <div nz-col nzSpan="8" class="cursor-pointer">
                                <nz-card class="orientation-card select-option">
                                    <div class="orientation-conatiner">
                                        <img src="./assets/images/orientation-270.png" alt="">
                                    </div>
                                    <div class="mt-3 mb-2 ortn-content text-center">270 Degree</div>
                                </nz-card>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div nz-col nzSpan="24" class="mb-3">
                    <div class="align-items-center">
                        <label class="mr-3" for="" class="fs-16 mb-0 fw-bold">Schedule</label>
                        <span nz-icon nzType="exclamation-circle" nzTheme="outline" nz-tooltip
                            nzTooltipTitle="Schedule allows you to show and hide media within a playlist at desired times."
                            class="label-icon"></span>
                        <span class="ml-3"><nz-switch [(ngModel)]="isScheduled" (ngModelChange)="onSchedule()"
                                name="schedule" #schedule="ngModel"></nz-switch></span>
                    </div>
                    <div nz-row *ngIf="isScheduled">
                        <div nz-col nzSpan="24">
                            <nz-divider></nz-divider>
                        </div>
                    </div>
                    <div nz-row class="mt-2 ml-2" *ngIf="isScheduled">
                        <div nz-col nzSpan="24">
                            <nz-radio-group [(ngModel)]="model.isDaily" name="isDaily" #isDaily="ngModel"
                                (ngModelChange)="scheduleTypeChanged($event)">
                                <label nz-radio [nzValue]="true">Daily</label>
                                <label nz-radio [nzValue]="false">Date range</label>
                            </nz-radio-group>
                        </div>
                    </div>

                    <div nz-row [nzGutter]="[16]" class="mt-2" *ngIf="isScheduled">
                        <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                            <div class="input-container">
                                <nz-form-item>
                                    <nz-form-control class="mt-3 mb-0">
                                        <nz-form-label>Start time</nz-form-label>
                                        <nz-time-picker [nzUse12Hours]="true" [nzAllowEmpty]="false"
                                            (ngModelChange)="isStartTimeValid($event)" class="cursor-pointer"
                                            [(ngModel)]="scheduleStartTime" name="startTime" #startTime="ngModel"
                                            nzFormat="hh:mm a" nzPlaceHolder="Select" required>
                                        </nz-time-picker>
                                        <div *ngIf="form.submitted || startTime?.touched"
                                            class="validation-error-message">
                                            <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                                class="validation-icon mt-1" *ngIf="startTime?.errors"></span>
                                            <span *ngIf="startTime?.errors?.['required']" class="ml-1">Start time is
                                                required</span>
                                        </div>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                        <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                            <div class="input-container">
                                <nz-form-item>
                                    <nz-form-control class="mt-3 mb-0">
                                        <nz-form-label>End time</nz-form-label>
                                        <nz-time-picker [nzUse12Hours]="true" class="cursor-pointer"
                                            (ngModelChange)="isTimeValid($event)" [nzAllowEmpty]="false"
                                            [(ngModel)]="scheduleEndTime" name="endTime" #endTime="ngModel"
                                            nzFormat="hh:mm a" nzPlaceHolder="Select" required>
                                        </nz-time-picker>
                                        <div *ngIf="form.submitted || endTime?.touched"
                                            class="validation-error-message">
                                            <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                                class="validation-icon mt-1"
                                                *ngIf="(endTime?.errors) || (!endTime?.errors?.['required'] && !isEndTimeValid)"></span>
                                            <span *ngIf="endTime?.errors?.['required']" class="ml-1">End
                                                time is required</span>
                                            <span *ngIf="!endTime?.errors?.['required'] && !isEndTimeValid"
                                                class="ml-1">Please select any future time</span>
                                        </div>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                    <div nz-row [nzGutter]="[16]" *ngIf="isScheduled && !model.isDaily">
                        <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                            <div class="input-container">
                                <nz-form-item>
                                    <nz-form-control class="mt-3 mb-0">
                                        <nz-form-label>Start date</nz-form-label>
                                        <nz-date-picker nzFormat="MM/dd/YYYY" name="startDate" [nzAllowClear]="false"
                                            [(ngModel)]="model.startDate" #startDate="ngModel"
                                            [nzDisabledDate]="disableStartDate" nzPlaceHolder="Select"
                                            class="cursor-pointer"></nz-date-picker>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                        <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                            <div class="input-container">
                                <nz-form-item>
                                    <nz-form-control class="mt-3 mb-0">
                                        <nz-form-label>End date</nz-form-label>
                                        <nz-date-picker nzFormat="MM/dd/YYYY" name="endDate" [nzAllowClear]="false"
                                            [(ngModel)]="model.endDate" #endDate="ngModel"
                                            [nzDisabledDate]="disableEndDate" nzPlaceHolder="Select"
                                            class="cursor-pointer"></nz-date-picker>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                </div>

                <div nz-col nzSpan="24" class="mt-2 mb-3">
                    <div nz-col nxSpan="24" class="align-items-center">
                        <label class="mr-3" for="" class="fs-16 mb-0 fw-bold">Show this
                            media</label>
                        <span nz-icon nzType="exclamation-circle" nzTheme="outline" nz-tooltip
                            nzTooltipTitle="Show or hide media" class="label-icon"></span>
                        <span class="ml-3"><nz-switch name="isEnabled" #isEnabled="ngModel"
                                [(ngModel)]="model.isEnabled"></nz-switch></span>
                    </div>
                </div>

                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control class="mb-0">
                                <nz-form-label>Notes</nz-form-label>
                                <nz-textarea-count [nzMaxCharacterCount]="100">
                                    <textarea rows="2" nz-input placeHolder="Enter Notes" [minLength]="1"
                                        [maxLength]="100" name="description" [(ngModel)]="model.description"></textarea>
                                </nz-textarea-count>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </div>

            <div nz-row class="disp-mob mobOne-action-bar">
                <div nz-col nzSpan="24" class="text-right">
                    <nz-card>
                        <div nz-row [nzGutter]="[16]">
                            <div nz-col nzSpan="12">
                                <button nz-button class="btn-full" type="button" (click)="cancel()">Cancel</button>
                            </div>
                            <div nz-col nzSpan="12">
                                <button nz-button class="btn-full" nzType="primary"
                                    [disabled]="form.invalid">Save</button>
                            </div>
                        </div>
                    </nz-card>
                </div>
            </div>
        </form>
    </nz-spin>
</section>