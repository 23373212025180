import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import moment from "moment";
import { NzDrawerComponent } from "ng-zorro-antd/drawer";
import { Subscription } from "rxjs";
import { AppUtils, Constants } from "src/helpers";
import { GroupDetailModel, MediaAppDetailModel, MediaAppListItemModel, MediaListItemModel, UserMediaAppYouTubeUpsertModel, YoutubeVideoModel } from "src/app/shared/models";
import { UserMediaAppUpsertModel } from "src/app/shared/models/user-media-app/user-media-app-upsert.model";
import { SelectListItemModel } from "src/models";
import { BaseService, DisplayListService, FolderService, GroupService, MediaAppService, UserMediaAppService } from "src/app/shared/services";

@Component({
    selector: 'app-user-media-youtube-drawer',
    templateUrl: './user-media-app-youtube.component.html',
    styleUrls: ['./user-media-app-youtube.component.scss']
})

export class UserMediaAppYouTubeDrawerComponent implements OnInit, OnDestroy {
    @ViewChild('drawer') drawer: NzDrawerComponent;
    @Output() closeDrawer: EventEmitter<{ media: Array<MediaListItemModel>, mediaAdded: boolean }> = new EventEmitter<{ media: Array<MediaListItemModel>, mediaAdded: boolean }>();
    @Input() mediaAppId: any;
    @Input() selectedGroupId: number;
    @Input() displayListId: number;
    isLoading = false;
    showScheduleFields = false;
    model = new UserMediaAppUpsertModel();
    subscriptions = new Array<Subscription>();
    subscriptionPlans = new Array<SelectListItemModel>();
    selectedFolder = new Array<SelectListItemModel>();
    groups = new Array<GroupDetailModel>();
    folders = new Array<SelectListItemModel>();
    displayLists = new Array<SelectListItemModel>();
    selectedDisplayLists = new Array<SelectListItemModel>();
    youtubeVideos = new Array<YoutubeVideoModel>();
    selectedVideo = new YoutubeVideoModel();
    mediaAppModel = new MediaAppDetailModel();
    imageUrlToPreview: string;
    mediaApps = new Array<MediaAppListItemModel>();
    scheduleStartTime: any;
    scheduleEndTime: any;
    isScheduled: boolean = false;
    showDuration: boolean = false;
    isEndDateValid = true;
    isEndTimeValid = true;
    isStartTimeEndTimeValid = true;
    showYoutubeVideo: boolean;
    visible: boolean;
    showYouTubeView: boolean;
    nextPageToken: string;
    isGroupLoaded: boolean = false;
    isTyping = false;

    get constants() { return Constants; }

    constructor(
        private baseService: BaseService,
        public appUtils: AppUtils,
        private groupService: GroupService,
        private displayListService: DisplayListService,
        private folderService: FolderService,
        private userMediaAppService: UserMediaAppService,
        private mediaAppService: MediaAppService
    ) {
        this.folders = new Array<SelectListItemModel>();
        this.model = new UserMediaAppUpsertModel();
        this.groups = new Array<GroupDetailModel>();
        this.subscriptionPlans = new Array<SelectListItemModel>();

        this.model = new UserMediaAppUpsertModel();
        this.model.youTube = new UserMediaAppYouTubeUpsertModel();
    }

    ngOnInit() {
        this.model.mediaAppId = this.mediaAppId;
        this.getMediaAppDetails();
        this.loadGroups();
        this.getMediaAppList();
    }

    onSearch(value: string): void {
        this.isTyping = value.length > 0;
    }

    getMediaAppDetails() {
        this.mediaAppService.get(this.model.mediaAppId)
            .subscribe({
                next: (response: MediaAppDetailModel) => {
                    this.mediaAppModel = response;
                    this.mediaAppModel.iconUrl = this.generateMediaIcon(this.mediaAppModel.typeString)

                },
                error: (error: any) => {
                    this.baseService.processErrorResponse(error);
                }
            });
    }

    generateMediaIcon(mediaAppType: string): string {
        return `./assets/images/media-types/icons/v2/${mediaAppType}.svg`;
    }

    loadGroups(): void {
        this.isLoading = true;
        const requestSubscription =
            this.groupService.getList().subscribe({
                next: (response: Array<GroupDetailModel>) => {
                    let selectListItem = new SelectListItemModel();
                    selectListItem.keyString = '-1';
                    selectListItem.keyInt = -1;
                    selectListItem.value = `My Subscription (${this.baseService.getUserName()})`;

                    this.subscriptionPlans.push(selectListItem);

                    Object.assign(this.groups, response);

                    this.groups.forEach((group) => {
                        selectListItem = new SelectListItemModel();
                        selectListItem.keyInt = group.id;
                        selectListItem.keyString = group.id.toString();
                        selectListItem.value = `${group.name} (${group.ownerName})`;
                        this.subscriptionPlans.push(selectListItem);
                    });

                    if (this.selectedGroupId != undefined && this.selectedGroupId > 0) {
                        this.model.groupId = this.selectedGroupId;
                    }
                    else {
                        this.selectedGroupId = -1;
                        this.model.groupId = -1;
                    }
                    this.isGroupLoaded = true;
                    this.isLoading = false;
                    this.getFolders();
                    this.loadDisplayLists();
                },
                error: (error) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                },
            });

        this.subscriptions.push(requestSubscription);
    }

    onGroupChange() {
        this.getFolders();
    }

    getFolders() {
        this.isLoading = true;
        if (!this.model.folderId) {
            this.model.folderId = null;
        }
        const requestsubscription = this.folderService
            .getList(this.model.groupId, this.model.folderId)
            .subscribe({
                next: (response: Array<SelectListItemModel>) => {
                    this.folders = response;
                    this.updateSelectedFolder();
                    this.isLoading = false;
                },
                error: (error) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                },
            });

        this.subscriptions.push(requestsubscription);
    }

    updateSelectedFolder() {
        if (this.folders.length === 0 || !this.model.folderId) {
            this.model.folderId = null;
            this.isLoading = false;
            return;
        }

        const selectedFolder = this.folders.filter(
            (x) => Number(x.keyInt) === Number(this.model.folderId)
        );

        if (selectedFolder) {
            this.selectedFolder = selectedFolder;
        }
    }

    cancel(): void {
        this.closeDrawer.emit({ media: new Array<MediaListItemModel>(), mediaAdded: false });
    }

    onSchedule() {
        this.model.startDate = null;
        this.model.endDate = null;
        this.scheduleStartTime = null;
        this.scheduleEndTime = null;
    }

    scheduleTypeChanged(isDaily: boolean) {
        this.model.isDaily = isDaily;
        this.model.startDate = null;
        this.model.endDate = null;
    }

    disableStartDate = (current: Date): boolean => {
        return current && current < new Date(new Date().setHours(0, 0, 0, 0));
    }

    disableEndDate = (current: Date): boolean => {
        if (!this.model.startDate) {
            return current && current < new Date(new Date().setHours(0, 0, 0, 0));
        }
        return current && current < new Date(this.model.startDate);
    }

    isTimeValid(time: any): void {
        const startTime = moment(this.scheduleStartTime, 'hh:mm A').set({ s: 0 });
        const endTime = moment(time, 'hh:mm A').set({ s: 0 });
        if (
            !moment(endTime).isAfter(moment(startTime)) &&
            startTime.format('a') === endTime.format('a')
        ) {
            this.isEndTimeValid = false;
        } else {
            this.isEndTimeValid = true;
        }
    }

    isStartTimeValid(time: any): void {
        const startTime = moment(time, 'hh:mm A').set({ s: 0 });
        const endTime = moment(this.scheduleEndTime, 'hh:mm A').set({ s: 0 });
        if (
            !moment(endTime).isAfter(moment(startTime)) &&
            startTime.format('a') === endTime.format('a')
        ) {
            this.isEndTimeValid = false;
        } else {
            this.isEndTimeValid = true;
        }
    }

    validateScheduleDetails(): boolean {
        if (!this.model.isScheduled) {
            this.model.startTime = null;
            this.model.endTime = null;
            this.model.startDate = null;
            this.model.endDate = null;
            return true;
        }

        if (this.scheduleStartTime && this.scheduleEndTime) {
            if (!this.isStartTimeEndTimeValid) {
                return false;
            }

            const startTime = moment(this.scheduleStartTime, 'MM/DD/YYYY hh:mm A').set({ s: 0 });
            const endTime = moment(this.scheduleEndTime, 'MM/DD/YYYY hh:mm A').set({ s: 0 });
            if (
                !moment(endTime).isAfter(moment(startTime)) &&
                startTime.format('a') === endTime.format('a')
            ) {
                this.isEndTimeValid = false;
                return false;
            }
            this.model.startTime = this.appUtils.getFormattedTime(
                startTime.toString(),
                null
            );
            this.model.endTime = this.appUtils.getFormattedTime(
                endTime.toString(),
                null
            );

            if (this.model.isDaily) {
                this.model.startDate = null;
                this.model.endDate = null;
                return true;
            }
            this.model.startDate = this.appUtils.getFormattedDate(
                this.model.startDate,
                null
            );
            this.model.endDate = this.appUtils.getFormattedDate(
                this.model.endDate,
                null
            );
            if (moment(this.model.endDate).isBefore(this.model.startDate)) {
                this.isEndDateValid = false;
                return false;
            }
            this.isEndDateValid = true;
            return true;
        } else {
            return false;
        }
    }

    loadDisplayLists() {
        if (!this.isGroupLoaded) {
            return
        }
        this.displayLists = new Array<SelectListItemModel>();
        this.isLoading = true;
        const requestSubscription = this.displayListService
            .getSelectListItems(this.model.groupId)
            .subscribe({
                next: (data: Array<any>) => {
                    this.isLoading = false;
                    this.displayLists = data;
                    if (!this.appUtils.isNullOrEmpty(this.displayListId)) {
                        let selectedDisplayList = this.displayLists.find(x => x.keyInt == this.displayListId);
                        this.model.displayLists.push(selectedDisplayList);
                    }
                    this.updateSelectedDisplayList();
                },
                error: (error: any) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                },
            });

        this.subscriptions.push(requestSubscription);
    }

    updateSelectedDisplayList() {
        if (this.displayLists.length === 0) {
            return;
        }

        const selectedDisplayLists = new Array<SelectListItemModel>();
        this.model.displayLists.forEach((displayList) => {
            selectedDisplayLists.push(
                this.displayLists.find(
                    (x) => Number(x.keyInt) === Number(displayList.keyInt)
                )
            );
        });

        if (selectedDisplayLists && selectedDisplayLists.length > 0) {
            this.selectedDisplayLists = selectedDisplayLists;
        }
    }

    removeDisplay(item: SelectListItemModel) {
        const tempArr = [];
        this.selectedDisplayLists.forEach((displayList) => {
            tempArr.push(displayList);
        });
        const itemIndex = tempArr.indexOf(item);
        tempArr.splice(itemIndex, 1);
        this.selectedDisplayLists = tempArr;
    }

    getMediaAppList(): void {
        this.isLoading = true;
        this.mediaAppService.getList().subscribe({
            next: (response: any) => {
                if (response) {
                    this.mediaApps = new Array<MediaAppListItemModel>();
                    response.forEach((x) => {
                        let mediaApp = new MediaAppListItemModel();
                        mediaApp.id = x.typeString;
                        mediaApp.name = x.title;
                        mediaApp.iconUrl =
                            x.iconUrl ?? this.generateMediaIconUrl(x.typeString);
                        mediaApp.previewUrl =
                            x.previewUrl ?? this.generateMediaPreviewUrl(x.typeString);
                        this.mediaApps.push(mediaApp);
                    });
                }
                this.imageUrlToPreview = this.getMediaForPreview();
                this.isLoading = false;
            },
            error: (error) => {
                this.isLoading = false;
                this.baseService.processErrorResponse(error);
            },
        });
    }

    generateMediaIconUrl(mediaType: string): string {
        return `./assets/images/media-types/icons/${mediaType}.svg`;
    }

    generateMediaPreviewUrl(mediaType: string): string {
        return `./assets/images/media-types/preview/${mediaType}.svg`;
    }

    getMediaForPreview(): string {
        return `./assets/images/media-types/preview/Youtube.svg`;
    }

    submit() {
        this.isLoading = true;
        if (this.model.groupId === '' || this.model.groupId == -1) {
            this.model.groupId = null;
        }

        if (this.selectedFolder && this.selectedFolder.length > 0) {
            this.model.folderId = this.selectedFolder[0].keyInt;
        } else {
            this.model.folderId = null;
        }

        this.model.isScheduled = this.isScheduled;
        if (!this.validateScheduleDetails()) {
            this.showScheduleFields = true;
            this.isLoading = false;
            return;
        }

        this.model.displayLists = new Array<SelectListItemModel>();
        if (this.selectedDisplayLists && this.selectedDisplayLists.length > 0) {
            this.selectedDisplayLists.forEach((item) => {
                let selectedDisplayListModel = new SelectListItemModel();
                selectedDisplayListModel.keyInt = item.keyInt;
                selectedDisplayListModel.value = item.value;
                this.model.displayLists.push(selectedDisplayListModel);
            });
            this.model.lengthInSeconds =
                this.selectedDisplayLists.length === 1 || this.showDuration
                    ? this.model.lengthInSeconds
                    : '0';
        }

        this.model.mediaAppId = this.mediaAppId;

        this.model.id = 0;
        this.userMediaAppService.add(this.model).subscribe({
            next: (response: number) => {
                this.isLoading = false;
                let message;
                message = 'Media added successfully.';
                this.baseService.successNotification(message);
                let addedMedia = new MediaListItemModel();
                addedMedia.id = response;
                addedMedia.typeString = this.mediaAppModel.typeString;
                addedMedia.title = this.model.title;
                addedMedia.iconUrl = this.appUtils.getBorderlessMediaAppIconUrl(this.mediaAppModel.typeString);
                addedMedia.mediaAppType = this.mediaAppModel.typeString;
                addedMedia.mediaAppTitle = this.mediaAppModel.title;
                addedMedia.status = this.model.youTube.isEnabled == true ? 1 : 2;
                addedMedia.isSelected = true;
                addedMedia.urlError = false;
                addedMedia.isEnabled = this.model.youTube.isEnabled;
                addedMedia.lengthInSeconds = this.model.lengthInSeconds;
                addedMedia.isScheduled = this.model.isScheduled;
                let addedMedias = new Array<MediaListItemModel>();
                addedMedias.push(addedMedia);
                this.closeDrawer.emit({ media: addedMedias, mediaAdded: true });
            },
            error: (error: any) => {
                this.isLoading = false;
                this.baseService.processErrorResponse(error);
            },
        });
    }

    openVideoSearchDrawer(): void {
        this.showYoutubeVideo = true;
        this.visible = true;
        this.drawer.open();
    }

    removeVideo(): void {
        this.selectedVideo = new YoutubeVideoModel();
        this.model.youTube.type = this.selectedVideo.type;
        this.model.youTube.videoId = this.selectedVideo.videoId;
        this.model.youTube.videoTitle = this.selectedVideo.title;
        this.model.youTube.url = '';
    }

    closeYoutubeDrawer(event: any) {
        this.selectedVideo = event.selectedVideo;
        this.setEmbeddedUrl(this.selectedVideo);
        this.visible = false;
    }

    setEmbeddedUrl(item: YoutubeVideoModel) {
        this.model.youTube.type = item.type;
        this.model.youTube.videoId = item.videoId;
        this.model.youTube.videoTitle = item.title;
        this.model.youTube.url = `https://www.youtube-nocookie.com/embed/${item.videoId}?autoplay=1&mute=1&enablejsapi=1&controls=0`;
        this.showYouTubeView = false;
        this.youtubeVideos = new Array<YoutubeVideoModel>();
        this.model.youTube.searchText = '';
        this.nextPageToken = '';
    }

    close() {
        this.visible = false;
    }

    ngOnDestroy() {
        this.isLoading = false;
        this.subscriptions.forEach(s => { s.unsubscribe(); });
    }

}