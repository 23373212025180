import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { environment } from "src/environments/environment";
import { BlobServiceClient } from "@azure/storage-blob";
import { Guid } from "guid-typescript";
import { AppUtils, Constants } from "src/helpers";
import { BaseService, FeedbackService, FeedbackTypeService, StorageService } from "src/app/shared/services";
import { SelectListItemModel } from "src/models";
import { FeedbackModel } from "src/app/shared/models";

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss']
})

export class FeedbackComponent implements OnInit {
    @Output() closeModal = new EventEmitter<any>();
    isLoading = false;
    feedbackTypes = new Array<SelectListItemModel>();
    model = new FeedbackModel();
    attachment: File;
    attachmentName: string;
    selectedAttachment = '';

    constructor(private appUtils: AppUtils,
        private baseService: BaseService,
        private storageService: StorageService,
        private feedbackService: FeedbackService,
        private feedbackTypeService: FeedbackTypeService
    ) { }

    ngOnInit() {
        this.getFeedbackTypes();
    }

    getFeedbackTypes(): void {
        this.isLoading = true;
        this.feedbackTypeService.getSelectListItems()
            .subscribe({
                next: (response: Array<SelectListItemModel>) => {
                    this.feedbackTypes = response;
                    this.isLoading = false;
                },
                error: (error: any) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                }
            });
    }

    onFeedbackTypeChange() {
        this.model.comment = null;
        this.model.attachment = null;
        this.model.willingToJoinPrg = false;
        this.attachment = null;
        this.attachmentName = null;
        this.selectedAttachment = null;
        let feedbackType = this.feedbackTypes.find(x => x.keyInt == this.model.feedbackTypeId);
        if (feedbackType) {
            this.model.attachmentRequired = feedbackType.isSelected;
        }
        else {
            this.model.attachmentRequired = false;
        }
    }

    onFileSelected(event: any): void {
        let uploadedFile = event.target.files[0];

        if (uploadedFile.type !== 'image/jpg'
            && uploadedFile.type !== 'image/png'
            && uploadedFile.type !== 'image/jpeg') {
            this.baseService.error('Please upload image of JPG, PNG format only.');
            this.resetImageFileElement(event);
            return;
        }

        if (uploadedFile.size > 5242880) {
            this.baseService.error('Image can not be more than 5 MB');
            this.resetImageFileElement(event);
            return;
        }

        this.attachment = uploadedFile;
        this.selectedAttachment = uploadedFile.name;
    }

    resetImageFileElement(event: any) {
        this.attachment = null;
        this.attachmentName = null;
        event.target.files[0].val = null;
    }

    getAzureSasToken(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.storageService.getAzureSasToken().subscribe({
                next: async (data) => {
                    resolve(data);
                },
                error: error => {
                    this.baseService.error('Unable to upload image file please try again.');
                    reject(new Error(error));
                }
            });
        });
    }

    async uploadImage(attachment: File, sasToken: any): Promise<any> {
        return new Promise((resolve, reject) => {

            const CloudConfig = {
                sas: sasToken.toString(),
                storageAccount: environment.azureAccount,
                containerName: environment.azureContainers.feedback
            };
            const originalFileName = `${Guid.create()}.${this.appUtils.getFileExtension(attachment.name)}`;
            const fileName = `${this.baseService.getUserId()}/${originalFileName}`;
            const blobServiceClientUrl = `${environment.azureContainerBaseUrl}?${sasToken}`;
            const blobServiceClient = new BlobServiceClient(blobServiceClientUrl);
            const containerClient = blobServiceClient.getContainerClient(
                CloudConfig.containerName
            );
            const blockBlobClient = containerClient.getBlockBlobClient(fileName);

            blockBlobClient
                .uploadData(attachment, {
                    blockSize: 262144,
                    onProgress: (progress) => {
                    },
                })
                .then(() => {
                    Constants.interceptAuthToken = true;
                    this.model.attachment = originalFileName;
                    resolve(true);
                })
                .catch((err) => {
                    Constants.interceptAuthToken = true;
                    this.baseService.error('Image file upload timeout.');
                    reject(new Error(err));
                });
        });
    }

    async submit(): Promise<void> {
        this.isLoading = true;
        if (this.attachment) {
            let sasToken = await this.getAzureSasToken();
            await this.uploadImage(this.attachment, sasToken);
        }
        this.add();
    }

    add(): void {
        this.feedbackService.add(this.model)
            .subscribe({
                next: () => {
                    this.isLoading = false;
                    this.baseService.success("Feedback submitted successfully.");
                    this.closeModal.emit();
                },
                error: (error: any) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                }
            });
    }

    cancel(): void {
        this.closeModal.emit();
    }

    deleteAttachment(){
        this.attachment = null;
        this.attachmentName = null;
        this.selectedAttachment = null;
    }

}