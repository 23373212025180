import { Component, Input, OnChanges, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { BaseService } from "src/app/shared/services";
import { AppUtils, Constants } from "src/helpers";
import { ListenerService } from "src/services";

@Component({
    selector: 'app-media-preview',
    templateUrl: './media-preview.component.html',
    styleUrls: ['./media-preview.component.scss']
})
export class MediaPreviewComponent implements OnChanges, OnDestroy {
    @Input() dataUrl: any;
    @Input() lastUpdated: string;
    @Input() type: number;
    @Input() mediaAppType: string;
    contentUrl: string;
    showDefault = true;
    subscriptions = new Array<Subscription>();
    isLoading = false;

    get constants() { return Constants; }

    constructor(private appUtils: AppUtils, private listenerService: ListenerService, private baseService: BaseService) {
        const appThemeChange = this.listenerService
            .appThemeChange.subscribe(() => {
                this.handlePreview();
            });
        this.subscriptions.push(appThemeChange);
    }

    ngOnChanges(): void {
        this.handlePreview();
    }

    handlePreview(): void {
        if (!this.appUtils.isNullOrEmpty(this.dataUrl)) {
            this.isLoading = true;
        }
        else {
            this.showDefault = true;
            this.isLoading = false;
            if (this.baseService.isDarkModeActive()) {
                this.contentUrl = "./assets/images/darkmode-preview.svg";
            }
            else {
                this.contentUrl = "./assets/images/default_preview.png";
            }
        }
    }

    onContentLoaded() {
        this.isLoading = false;
        this.showDefault = false;
    }

    openContent() {
        window.open(this.dataUrl, '_blank');
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => { s.unsubscribe(); });
    }

    onError() {
        this.isLoading = false;
        this.showDefault = false;
        this.dataUrl = this.generateMediaPreviewUrl(this.mediaAppType);
    }

    generateMediaPreviewUrl(mediaType: string): string {
        return `./assets/images/media-types/preview/${mediaType}.svg`;
    }
}