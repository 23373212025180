
import { NgModule } from "@angular/core";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { BaseService, DisplayService, FeedbackService, FeedbackTypeService, FolderService, MediaAppService, ScreenPurchaseLogService, StorageService, UserMediaAppService } from "./services";
import { NativeAutofillDetector } from "./directives/autofill.detector.directive";
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NumbersOnlyDirective, AnythingButWhiteSpaceDirective, AlphaNumericsLevelThreeDirective, AlphabatesOnlyDirective, AlphaNumericsLevelFourDirective } from "src/helpers/app.directive";
import { NzTagModule } from 'ng-zorro-antd/tag';
import { HelpComponent } from "./components/help/help.component";
import { PlaylistComponent } from "./components/display-list/display-list-list.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { DisplayListService } from "./services/display-list.service";
import { BroadcastAddComponent, BroadcastMultiSelectComponent, ConfirmDialogComponent, DisplayListMultiSelectComponent, MediaListComponent, MediaEditComponent, MediaListMultiSelectComponent, MediaStoreComponent, UnderConstructionComponent, UserMediaWebPageDrawerComponent, UserMediaAppVimeoComponent, UserMediaAppViemoVideoComponent, DisplayUpsertComponent, UserMediaAppYouTubeDrawerComponent, YoutubeSearchVideoDrawerComponent, UserMediaAppWeatherComponent, UserMediaAppClockComponent, DisplayListUpsertComponent, DisplayListEditComponent, UserMediaAppGoogleSlideComponent, UserMediaAppBulkUploadComponent, UserMediaAppPowerPointComponent, HelpVideoViewComponent, BroadcastEditComponent, FeedbackComponent, UserMediaAppCanvaComponent, GroupInvitationExpiredComponent, ContentTextViewComponent, FeedbackDialogComponent, ValidateInvitationComponent, DisplayPreviewComponent, MediaPreviewComponent } from "./components";
import { BroadcastListComponent } from "./components";
import { MediaService } from "./services/media.service";
import { GroupService } from "./services";

import { RouterModule } from "@angular/router";
import { BroadcastService } from "./services/broadcast.service";
import { TimeZoneService } from "./services/time-zone.service";
import { CustomMaxDirective, CustomMinDirective, SafePipe } from "src/helpers";
import { NzColorPickerModule } from 'ng-zorro-antd/color-picker';
import { DndModule } from "ngx-drag-drop";
import { PlayerTypeService } from "./services/player-type.service";
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { GoogleAnalyticsService } from "./services/google-analytics.service";


@NgModule({
    declarations: [
        NativeAutofillDetector,

        NumbersOnlyDirective,
        CustomMaxDirective,
        CustomMinDirective,
        AlphabatesOnlyDirective,
        AlphaNumericsLevelFourDirective,
        AnythingButWhiteSpaceDirective,
        AlphaNumericsLevelThreeDirective,

        ContentTextViewComponent,
        HelpComponent,
        DisplayPreviewComponent,
        PlaylistComponent,
        MediaListComponent,
        MediaPreviewComponent,
        ValidateInvitationComponent,
        DisplayListUpsertComponent,
        UnderConstructionComponent,
        BroadcastListComponent,
        BroadcastAddComponent,
        DisplayListMultiSelectComponent,
        BroadcastMultiSelectComponent,
        ConfirmDialogComponent,
        MediaListMultiSelectComponent,
        MediaEditComponent,
        UserMediaWebPageDrawerComponent,
        MediaStoreComponent,
        UserMediaAppVimeoComponent,
        YoutubeSearchVideoDrawerComponent,
        UserMediaAppViemoVideoComponent,
        DisplayUpsertComponent,
        UserMediaAppYouTubeDrawerComponent,
        UserMediaAppWeatherComponent,
        UserMediaAppClockComponent,
        UserMediaAppBulkUploadComponent,
        DisplayListEditComponent,
        UserMediaAppPowerPointComponent,
        UserMediaAppGoogleSlideComponent,
        UserMediaAppCanvaComponent,
        BroadcastEditComponent,
        HelpVideoViewComponent,
        FeedbackComponent,
        GroupInvitationExpiredComponent,
        FeedbackDialogComponent,

        SafePipe
    ],
    imports: [
        NzLayoutModule,
        NzMenuModule,
        NzGridModule,
        NzCardModule,
        NzBreadCrumbModule,
        NzInputModule,
        NzSelectModule,
        NzButtonModule,
        NzDropDownModule,
        NzCollapseModule,
        NzDividerModule,
        NzDrawerModule,
        NzTableModule,
        NzPageHeaderModule,
        NzToolTipModule,
        NzFormModule,
        NzIconModule,
        NzSpinModule,
        NzCarouselModule,
        NzModalModule,
        NzCardModule,
        NzSwitchModule,
        NzPaginationModule,
        NzCheckboxModule,
        NzRadioModule,
        NzDatePickerModule,
        NzTimePickerModule,
        NzUploadModule,
        NzTagModule,
        NzRateModule,
        NzSegmentedModule,

        CommonModule,
        FormsModule,
        NzTabsModule,
        NzStepsModule,
        NzSwitchModule,
        NzRadioModule,
        NzColorPickerModule,
        RouterModule,
        DndModule,
        NzProgressModule

    ],
    exports: [
        NzLayoutModule,
        NzMenuModule,
        NzGridModule,
        NzCardModule,
        NzBreadCrumbModule,
        NzInputModule,
        NzSelectModule,
        NzButtonModule,
        NzDropDownModule,
        NzCollapseModule,
        NzDividerModule,
        NzDrawerModule,
        NzTableModule,
        NzPageHeaderModule,
        NzToolTipModule,
        NzIconModule,
        NzFormModule,
        NativeAutofillDetector,
        NzSpinModule,
        HelpComponent,
        NzCarouselModule,
        NzModalModule,
        NzCardModule,
        NzSwitchModule,
        NzPaginationModule,
        NzCheckboxModule,
        NzRadioModule,
        NzUploadModule,
        NzSegmentedModule,

        CommonModule,
        FormsModule,
        RouterModule,

        NzTabsModule,
        NzStepsModule,
        NzSwitchModule,
        NzRadioModule,
        NzColorPickerModule,
        NzDatePickerModule,
        NzTimePickerModule,
        NzTagModule,
        NzProgressModule,
        NzRateModule,
        NzSegmentedModule,

        NumbersOnlyDirective,
        AnythingButWhiteSpaceDirective,
        CustomMaxDirective,
        CustomMinDirective,
        AlphabatesOnlyDirective,
        AlphaNumericsLevelFourDirective,

        DisplayPreviewComponent,
        PlaylistComponent,
        DisplayListUpsertComponent,
        BroadcastListComponent,
        UnderConstructionComponent,
        DisplayListMultiSelectComponent,
        BroadcastMultiSelectComponent,
        ConfirmDialogComponent,
        AlphaNumericsLevelThreeDirective,
        MediaListComponent,
        MediaListMultiSelectComponent,
        MediaEditComponent,
        UserMediaWebPageDrawerComponent,
        MediaStoreComponent,
        UserMediaAppVimeoComponent,
        YoutubeSearchVideoDrawerComponent,
        DisplayUpsertComponent,
        UserMediaAppViemoVideoComponent,
        UserMediaAppYouTubeDrawerComponent,
        UserMediaAppWeatherComponent,
        DisplayListEditComponent,
        UserMediaAppBulkUploadComponent,
        UserMediaAppPowerPointComponent,
        UserMediaAppGoogleSlideComponent,
        UserMediaAppCanvaComponent,
        BroadcastEditComponent,
        HelpVideoViewComponent,
        FeedbackComponent,
        GroupInvitationExpiredComponent,
        ContentTextViewComponent,
        FeedbackDialogComponent,
        ValidateInvitationComponent,
        MediaPreviewComponent
    ],
    providers: [
        BaseService,
        DisplayListService,
        MediaService,
        GroupService,
        FolderService,
        BroadcastService,
        DisplayService,
        UserMediaAppService,
        TimeZoneService,
        MediaAppService,
        StorageService,
        PlayerTypeService,
        FeedbackTypeService,
        FeedbackService,
        ScreenPurchaseLogService,
        GoogleAnalyticsService
    ]
})
export class SharedModule { }