import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppUtils } from './app.utils';
import { BaseService } from 'src/app/shared/services';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {

    constructor(private router: Router,
        private appUtils: AppUtils,
        private baseService: BaseService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const loggedIn = this.appUtils.isUserAuthenticated();
        if (!loggedIn || !this.baseService.isUserInfoExists()) {
            this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }

        const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
        if (element) {
            element.style.visibility = 'hidden';
        }

        return true;
    }
}
