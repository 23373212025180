<section>
    <nz-spin [nzSpinning]="isLoading">
        <form #f="ngForm" nz-form (ngSubmit)="f.form.valid" novalidate>
            <div nz-row>
                <div nz-col nzSpan="24">
                    Please share your feedback on the new design, including what you like and what you don't.
                </div>
            </div>

            <div nz-row class="pt-3">
                <div nz-col nzSpan="24">
                    <label for="" [class.required]="true">Submit your rating
                    </label>
                </div>
            </div>

            <div nz-row class="mt-2 mb-2">
                <div nz-col nzSpan="24">
                    <nz-rate [(ngModel)]="model.rating" name="rating" #rating="ngModel" class="rating-star"
                        [nzCharacter]="characterIcon" required></nz-rate>

                    <ng-template #characterIcon let-index><span nz-icon nzType="star"  [nzTheme]="getRatingStarTheme(index + 1)"></span></ng-template>

                    <div *ngIf="f.submitted || rating?.touched || rating?.dirty"
                        class="validation-error-message d-flex align-items-start">
                        <span nz-icon nzType="exclamation-circle" nzTheme="fill" class="validation-icon mt-1"
                            *ngIf="rating?.errors"></span>
                        <span class="validation-error-message mt-2" *ngIf="rating.errors?.['required']">
                            Rating is required.
                        </span>
                    </div>
                </div>
            </div>

            <div nz-row class="mt-3">
                <div nz-col nzSpan="24">
                    <div class="input-container">
                        <nz-form-item>
                            <nz-form-control class="mb-0">
                                <nz-form-label [nzRequired]="true">Let us know what's on your mind</nz-form-label>
                                <nz-textarea-count [nzMaxCharacterCount]="100">
                                    <textarea rows="2" nz-input name="description" #description="ngModel"
                                        placeholder="Enter here" maxlength="100" [(ngModel)]="model.comment"
                                        (keyup)="trimComment()" required></textarea>
                                </nz-textarea-count>
                                <div *ngIf="f.submitted || description?.touched || description?.dirty"
                                    class="validation-error-message d-flex align-items-start">
                                    <span nz-icon nzType="exclamation-circle" nzTheme="fill"
                                        class="validation-icon mt-1" *ngIf="description?.errors"></span>
                                    <span class="validation-error-message m-t-2"
                                        *ngIf="description.errors?.['required']">
                                        Feedback is required.
                                    </span>
                                </div>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </div>
            <div nz-row class="mt-3 mb-2">
                <div nz-col nzSpan="24" class="text-right">
                    <button nz-button nzType="default" (click)="cancel()" class="mr-3">Cancel
                    </button>
                    <button nz-button nzType="primary" (click)="submit()" [disabled]="f.invalid">Submit
                    </button>
                </div>
            </div>
        </form>
    </nz-spin>
</section>