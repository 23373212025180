import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { AppUtils } from "src/helpers";
import { BaseService } from "src/app/shared/services";
import { DisplayListListItemModel, FilterModel, MediaAppListItemModel } from "src/app/shared/models";
import { DisplayListService } from "src/app/shared/services/display-list.service";
import { NzDrawerComponent } from "ng-zorro-antd/drawer";
import { Subscription } from "rxjs";

@Component({
    selector: 'app-display-list-multi-select',
    templateUrl: './display-list-multi-select.component.html',
    styleUrls: ['./display-list-multi-select.component.scss']
})

export class DisplayListMultiSelectComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
    @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;
    @ViewChild('drawer') drawer: NzDrawerComponent;
    @Input() addFromMedia: boolean;
    @Input() selectedGroupId: number;
    @Input() medias: Array<MediaAppListItemModel> = new Array<MediaAppListItemModel>();
    @Input() selectedPlaylistIds: Array<number> = new Array<number>();
    @Input() selectedPlaylists: Array<DisplayListListItemModel> = new Array<DisplayListListItemModel>();
    @Output() closeDrawer: EventEmitter<{ selectedDisplayLists: Array<DisplayListListItemModel>, updateValue: boolean }> = new EventEmitter<{ selectedDisplayLists: Array<DisplayListListItemModel>, updateValue: boolean }>();
    isModelLoaded: boolean;
    allPlaylistSelected: boolean;
    groupId: number;
    filterKey: string;
    model: Array<DisplayListListItemModel>;
    isLoading = true;
    sortOrder: string;
    hasDisplayList: boolean = true;
    isPlaylistSelected: boolean;
    addDisplayList: boolean;
    totalRecords: number = 0;
    recordsToSkip: number;
    pageSize: number;
    filterModel = new FilterModel();
    subscriptions = new Array<Subscription>();

    loadingMore = false;
    hasMore = true;

    constructor(private service: DisplayListService,
        private baseService: BaseService,
        private appUtils: AppUtils
    ) {
        if (this.selectedGroupId == undefined) {
            this.groupId == null;
        }
        else {
            this.groupId = this.selectedGroupId
        }

        this.filterModel.sortBy = 'name';
        this.filterModel.sortDir = 'asc';
    }

    ngOnChanges() {
        if (this.selectedGroupId == undefined) {
            this.groupId == null;
        }
        else {
            this.groupId = this.selectedGroupId;
        }
    }

    sendPlayList() {
        let playlistIdstoRemove = new Array<number>();
        this.selectedPlaylists.forEach((x => {
            let playlist = this.model.filter(y => y.id == x.id);
            if (playlist && playlist.length > 0) {
                if (!playlist[0].isSelected) {
                    playlistIdstoRemove.push(playlist[0].id);
                }
            }
        }));

        this.selectedPlaylists = this.selectedPlaylists.filter(x => !playlistIdstoRemove.includes(x.id));

        var selectedPlaylist = this.model.filter(x => x.isSelected);
        selectedPlaylist.forEach((x => {
            let exists = this.selectedPlaylists.some(y => y.id == x.id);
            if (!exists) {
                this.selectedPlaylists.push(x);
            }
        }));

        this.closeDrawer.emit({ selectedDisplayLists: this.selectedPlaylists, updateValue: true });
    }


    selectPlaylist() {
        if (this.selectedPlaylists != null && this.selectedPlaylists.length > 0) {
            this.model.forEach(x => {
                const playlist = this.selectedPlaylists.find(y => y.id == x.id);
                if (playlist) {
                    x.isSelected = true;
                    this.isPlaylistSelected = true;
                }
            });

            if (this.model.length > 0 && this.model.every(x => x.isSelected == true)) {
                this.allPlaylistSelected = true;
            } else {
                this.allPlaylistSelected = false;
            }
        }
    }

    ngOnInit(): void {
        this.getList(true);
    }

    ngAfterViewInit() {
        this.scrollContainer.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
    }

    submit() {

    }

    close() {

    }

    onScroll(event: any) {
        const element = this.scrollContainer.nativeElement;
        const scrollTop = element.scrollTop;
        const scrollHeight = element.scrollHeight;
        const clientHeight = element.clientHeight;

        if (scrollTop + clientHeight >= scrollHeight * 0.8 && this.hasMore && !this.loadingMore) {
            this.getMoreRecords();
        }
    }

    openDisplaylistUpsertDrawer() {
        this.addDisplayList = true;
        this.drawer.open();
    }

    closeDisplayUpsertDrawer(event: any): void {
        if (event.displayListAdded) {
            this.selectedPlaylists.push(event.displayList);
            this.getList(true);
        }
        this.drawer.close();
        this.addDisplayList = false;
    }

    getList(initialLoading = false, limit = 15) {
        this.isModelLoaded = false;
        this.isLoading = true;
        this.filterModel.limit = limit;
        this.filterModel.offset = 0
        this.totalRecords = 0;
        const requestSubscription =
            this.service.getList(this.groupId, this.filterModel)
                .subscribe({
                    next: (response: Array<DisplayListListItemModel>) => {
                        this.model = new Array<DisplayListListItemModel>();
                        this.model = response;
                        this.totalRecords = response.length;
                        if (this.model.length > 0) {
                            this.model.forEach((x) => {
                                x.defaultUserMediaAppUrl = this.getMediaIconUrl(x.defaultUserMediaAppType);
                                x.showDefaultIcon = true;
                            });
                        }

                        if (initialLoading) {
                            this.hasDisplayList = this.model.length > 0;
                        }
                        this.selectPlaylist();
                        this.isModelLoaded = true;
                        this.isLoading = false;

                    },
                    error: (error) => {
                        this.baseService.processErrorResponse(error);
                        this.isLoading = false;
                    }
                });

        this.subscriptions.push(requestSubscription)
    }

    getMediaIconUrl(mediaType: string): string {
        const media = this.medias.find(x => x.id == mediaType);
        if (media != undefined) {
            return media.iconUrl;

        }
        return '';
    }

    onSearch() {
        if (this.filterModel.filterKey) {
            this.getList();
        }
    }

    clearSearch() {
        this.filterModel.filterKey = '';
        this.getList();
    }

    sortPlaylist() {
        if (this.sortOrder === 'ascending') {
            this.filterModel.sortBy = 'name';
            this.filterModel.sortDir = 'asc';
        } else if (this.sortOrder === 'descending') {
            this.filterModel.sortBy = 'name';
            this.filterModel.sortDir = 'desc';
        }
        this.getList(false, this.totalRecords);
    }

    filterKeyChanged() {
        if (this.appUtils.isNullOrEmpty(this.filterModel.filterKey)) {
            this.getList();
        }
    }

    onError(item: DisplayListListItemModel): void {
        item.urlError = true;
    }

    togglePlaylist(item: DisplayListListItemModel) {
        if (this.model.every(x => x.isSelected)) {
            this.allPlaylistSelected = true;
        }
        if (!this.model.every(x => x.isSelected)) {
            this.allPlaylistSelected = false;
        }

        if (this.model.some(x => x.isSelected)) {
            this.isPlaylistSelected = true;
        }
        else {
            this.isPlaylistSelected = false;
        }

        if (item.isSelected) {
            this.selectedPlaylists.push(item);
        } else {
            this.selectedPlaylists.splice(this.selectedPlaylists.indexOf(item), 1);
        }
    }

    selectAllPlaylist(event: any): void {
        if (event) {
            this.model.forEach(x => {
                x.isSelected = true;
                this.isPlaylistSelected = true;
            });
        }
        else {
            this.model.forEach(x => {
                x.isSelected = false;
                this.isPlaylistSelected = false;
            });
        }
    }

    getMoreRecords() {
        if (this.loadingMore) { return; }
        this.filterModel.limit = 5;
        this.filterModel.offset = this.totalRecords;
        this.loadingMore = true;
        this.isLoading = true;
        this.service.getList(this.groupId, this.filterModel)
            .subscribe({
                next: (response: Array<DisplayListListItemModel>) => {
                    if (response && response.length > 0) {
                        response.forEach((x) => {
                            x.defaultUserMediaAppUrl = this.getMediaIconUrl(x.defaultUserMediaAppType);
                            x.showDefaultIcon = true;
                            if (this.allPlaylistSelected) {
                                x.isSelected = true;
                            }
                            this.model.push(x);
                        });
                        this.totalRecords += response.length;
                    }
                    else {
                        this.hasMore = false;
                    }
                    this.selectPlaylist();
                    this.isLoading = false;
                    this.loadingMore = false;
                },
                error: (error) => {
                    this.baseService.processErrorResponse(error);
                    this.isLoading = false;
                    this.loadingMore = false;
                }
            });
    }

    ngOnDestroy() {
        this.isLoading = false;
        this.subscriptions.forEach(s => { s.unsubscribe(); });
    }
}