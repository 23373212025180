<section>
    <div class="scroll-container" #scrollContainer (scroll)="onScroll($event)">
        <div nz-row>
            <div nz-col nzSpan="20">
                <div class="heading-fs-18 mb-0">All Media</div>
            </div>
            <div nz-col nzSpan="4" class="text-right pt-2">
                <span nz-icon nzType="close" nzTheme="outline" class="cursor-pointer" (click)="cancel()"></span>
            </div>
            <div nz-col nzSpan="24">
                <nz-divider></nz-divider>
            </div>
        </div>
        <div nz-row>
            <div nz-col nzSpan="24">
                <form nz-form>
                    <div class="d-flex">
                        <div class="w-100 mr-3">
                            <div class="input-container mt-2">
                                <nz-form-item class="mb-0">
                                    <nz-form-control class="drawer-search-input">
                                        <nz-input-group [nzPrefix]="prefixIcon" [nzSuffix]="suffixIcon">
                                            <input nz-input placeholder="Search Media" name="filterKey" [disabled]="!hasMediaList"
                                                [(ngModel)]="filterModel.filterKey" (keydown.enter)="onSearch()"
                                                (ngModelChange)="filterKeyChanged()" [maxlength]="100" [disabled]="!hasMediaList"
                                                appAlphaNumericsLevelThree />
                                        </nz-input-group>
                                        <ng-template #suffixIcon>
                                            <span nz-icon nzType="close" nzTheme="outline" *ngIf="filterModel.filterKey"
                                                (click)="clearSearch()" class="clear-search cursor-pointer"></span>
                                        </ng-template>
                                        <ng-template #prefixIcon>
                                            <span nz-icon nzType="search" nzTheme="outline" (click)="onSearch()"
                                                class="cursor-pointer"></span>
                                        </ng-template>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>

                        <div class="mt-1">
                            <button nz-button nzType="primary" class="mt-2" (click)="openMediaStoreDrawer()"
                                type="button">
                                <span nz-icon nzType="plus" nzTheme="outline"></span>Add New
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <form (ngSubmit)="submit()">
            <div nz-col nzSpan="24">
                <div nz-row class="mt-2">
                    <div nz-col nzSpan="8" class="mt-1">
                        <label nz-checkbox [(ngModel)]="allMediaSelected" name="selectAll"  *ngIf="isModelLoaded && hasMediaList && model.length > 1"
                            (ngModelChange)="selectAllMedia($event)" class="align-center selected-square ">Select
                            all</label>
                    </div>
                    <div nz-col nzSpan="16" class="mb-3">
                        <div class="d-flex justify-content-end cursor-pointer">
                            <nz-form-item class="sort-by-dropdown"
                                *ngIf="isModelLoaded && hasMediaList && model.length > 1">
                                <nz-form-control>
                                    <nz-select nzPlaceHolder="Sort By" [(ngModel)]="sortOrder" name="sortOrder"
                                        (ngModelChange)="sortMedia()">
                                        <nz-option [nzValue]="'ascending'" nzLabel="Ascending"></nz-option>
                                        <nz-option [nzValue]="'descending'" nzLabel="Descending"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                            <div class="align-center disp-web ml-2 action_sidebar_wrapper"
                                *ngIf="isModelLoaded && hasMediaList && model.length > 0">
                                <button type="submit" nz-button (click)="sendMedia()" [disabled]="!isMediaSelected"  [ngClass]="{'selected-button': isMediaSelected}"
                                    *ngIf="!addMediaToPlaylist && (isMediaSelected || allMediaSelected)">Send to
                                    playlist</button>
                                <button type="submit" nz-button (click)="addMediasToPlaylist()"
                                    [disabled]="!isMediaSelected"
                                    *ngIf="addMediaToPlaylist && (isMediaSelected || allMediaSelected)">Add to
                                    playlist</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <nz-spin [nzSpinning]="isLoading" class="mobOne-action-m-b">
                <div nz-row *ngFor="let item of model" [nzGutter]="[16,16]" class="list-item mb-2 pt-1 cursor-pointer" (click)="item.isSelected = !item.isSelected; toggleMedia(item)">
                    <div nz-col nzXs="11" nzSm="15" nzMd="15" nzLg="15" nzXl="15">
                        <div class="d-flex align-items-center cursor-pointer">
                            <div class="mr-3">
                                <label nz-checkbox [(ngModel)]="item.isSelected" (ngModelChange)="toggleMedia(item)"
                                    name="selectMedia{{item.title}}" #selectedMedia="ngModel"></label>
                            </div>
                            <div class="list-img-wrapper mr-3">
                                <img [src]="item.iconUrl" alt="" (error)="onPreviewLoadError(item)">
                            </div>
                            <div class="text-ellipsis">{{item.title}}<br />
                                <div class="fs-12 text-primary" *ngIf="newMediaIds.includes(item.id)">New</div>
                            </div>
                        </div>
                    </div>
                    <div nz-col nzXs="6" nzSm="4" nzMd="4" nzLg="4" nzXl="4">
                        <div nz-row class="align-items-center mt-2">
                            <span class="text-ellipsis">{{item.mediaAppTitle}}</span>
                        </div>
                    </div>
                    <div nz-col nzSpan="7" nzSm="6" nzMd="5" nzLg="5" nzXl="5">
                        <div nz-col nzSpan="6" class="align-items-center"
                            *ngIf="item.videoEncodingStatus && item.videoEncodingStatus !==3">
                            <span nz-icon [nzType]="'loading'" class="icon-primary" nz-tooltip
                                nzTooltipTitle="Video optimization in progress. We'll notify you when it's complete."></span>
                            &nbsp;<span class="text-nowrap">Optimizing</span>
                        </div>
                        <div nz-col nzSpan="6" class="align-items-center"
                            *ngIf="!item.videoEncodingStatus || item.videoEncodingStatus === 3">

                            <span nz-icon nzType="check-circle" nzTheme="fill"
                                *ngIf="item.status === 1 && !item.isScheduled" class="status-icon-success mr-2"></span>
                            <span *ngIf="item.status === 1 && !item.isScheduled">Active</span>

                            <span nz-icon nzType="close-circle" nzTheme="fill" *ngIf="item.status === 2"
                                class="status-icon-danger mr-2"></span>
                            <span *ngIf="item.status === 2" class="text-nowrap">In-active</span>

                            <span nz-icon nzType="check-circle" nzTheme="fill"
                                *ngIf="item.status === 1 && item.isScheduled" class="status-icon-primary mr-2"></span>
                            <span *ngIf="item.status === 1 && item.isScheduled">Scheduled</span>

                            <span nz-icon nzType="clock-circle" nzTheme="fill" *ngIf="item.status === 4"
                                class="status-icon-primary mr-2"></span>
                            <span *ngIf="item.status === 4">Scheduled</span>
                        </div>
                    </div>

                    <nz-divider  class="mt-1 mb-0"></nz-divider>
                </div>
                <div *ngIf="isModelLoaded && model && model.length == 0 && hasMediaList" class="empty-list-container">
                    <span class="text-muted fs-14">No media match your search.</span>
                </div>
                <div *ngIf="isModelLoaded && !hasMediaList && model.length == 0" class="empty-list-container">
                    <span class="text-muted fs-14">No media found.</span>
                </div>

                <div nz-row class="disp-mob mobOne-action-bar action_sidebar_wrapper"
                    *ngIf="isModelLoaded && hasMediaList && model.length > 0">
                    <div nz-col nzSpan="24">
                        <nz-card>
                            <div nz-row [nzGutter]="[16]">
                                <div nz-col nzSpan="24">
                                    <button type="submit" nz-button (click)="sendMedia()" [disabled]="!isMediaSelected"
                                        class="btn-full"  [ngClass]="{'selected-button': isMediaSelected}"
                                        *ngIf="!addMediaToPlaylist && (isMediaSelected || allMediaSelected)">Send to
                                        playlist</button>
                                    <button type="submit" nz-button (click)="addMediasToPlaylist()"
                                        [disabled]="!isMediaSelected"
                                        *ngIf="addMediaToPlaylist && (isMediaSelected || allMediaSelected)">Add to
                                        playlist</button>
                                </div>
                            </div>
                        </nz-card>
                    </div>
                </div>
            </nz-spin>
        </form>

        <nz-drawer #drawer [nzClosable]="false" [nzVisible]="isDrawerVisible" nzPlacement="right" (nzOnClose)="close()"
            [nzMask]="false" class="custom-drawer">
            <ng-container *nzDrawerContent>
                <app-media-store (closeDrawer)="closeMediaStoreDrawer($event)" [selectedGroupId]="groupId"
                    *ngIf="showMediaDrawer" [displayListId]="displayListId"></app-media-store>
            </ng-container>
        </nz-drawer>
    </div>
</section>