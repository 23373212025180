<section>
    <div class="scroll-container" #scrollContainer (scroll)="onScroll($event)">
        <div nz-row>
            <div nz-col nzSpan="20">
                <div class="heading-fs-18 mb-0">All Media</div>
            </div>
            <div nz-col nzSpan="4" class="text-right pt-2">
                <span nz-icon nzType="close" nzTheme="outline" class="cursor-pointer" (click)="cancel()"></span>
            </div>
            <div nz-col nzSpan="24">
                <nz-divider></nz-divider>
            </div>
        </div>
        <div nz-col nzSpan="24">
            <div class="d-flex">
                <div class="w-100 mr-3">
                    <form nz-form>
                        <div class="input-container mt-2">
                            <nz-form-item class="mb-0">
                                <nz-form-control class="drawer-search-input">
                                    <nz-input-group [nzPrefix]="prefixIcon" [nzSuffix]="suffixIcon">
                                        <input nz-input placeholder="Search Media" name="filterKey"
                                            [(ngModel)]="filterModel.filterKey" (keydown.enter)="onSearch()"
                                            (ngModelChange)="filterKeyChanged()" [maxlength]="100" [disabled]="!hasMediaList" 
                                            appAlphaNumericsLevelThree/>
                                    </nz-input-group>
                                    <ng-template #suffixIcon>
                                        <span nz-icon nzType="close" nzTheme="outline" *ngIf="filterModel.filterKey"
                                            (click)="clearSearch()" class="clear-search cursor-pointer"></span>
                                    </ng-template>
                                    <ng-template #prefixIcon>
                                        <span nz-icon nzType="search" nzTheme="outline" (click)="onSearch()"
                                            class="cursor-pointer"></span>
                                    </ng-template>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </form>
                </div>
                <div class="pt-1">
                    <button nz-button nzType="primary" class="mt-2" (click)="openMediaStoreDrawer()">
                        <span nz-icon nzType="plus" nzTheme="outline"></span>Add New
                    </button>
                </div>
            </div>
        </div>

        <form nz-form>
            <div nz-col nzSpan="24">
                <div class="d-flex justify-content-end">
                    <nz-form-item class="sort-by-dropdown" *ngIf="isModelLoaded && userMediaApps.length > 1">
                        <nz-form-control>
                            <nz-select nzPlaceHolder="Sort By" [(ngModel)]="sortOrder" name="sortOrder"
                                (ngModelChange)="sortMedia()">
                                <nz-option [nzValue]="'ascending'" nzLabel="Ascending"></nz-option>
                                <nz-option [nzValue]="'descending'" nzLabel="Descending"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <nz-spin [nzSpinning]="isLoading">
                <div *ngIf="isModelLoaded && userMediaApps && userMediaApps.length > 0">
                    <div nz-row *ngFor="let item of userMediaApps" [nzGutter]="[16,16]" class="list-item mb-2 pt-1 cursor-pointer " (click)="selectMedia(item)">
                        <div nz-col nzXs="11" nzSm="15" nzMd="15" nzLg="15" nzXl="15">
                            <div class="d-flex align-items-center cursor-pointer">
                                <div class="cursor-pointer mr-3 list-img-wrapper"
                                    (click)="selectMedia(item)">
                                    <img [src]="item.iconUrl" alt="" (error)="onError(item)"
                                        [ngClass]="{'hidden': item.urlError}">
                                </div>
                                <div class="cursor-pointer text-ellipsis" (click)="selectMedia(item)"
                                    onkeypress="selectMedia(item)">{{item.title}}
                                    <div class="fs-12 text-primary" *ngIf="newMediaIds.includes(item.id)">New</div>
                                </div>
                            </div>
                        </div>
                        <div nz-col nzXs="6" nzSm="4" nzMd="4" nzLg="4" nzXl="4" class="align-items-center">
                            <span class="text-ellipsis">{{item.mediaAppTitle}}</span>
                        </div>
                        <div nz-col nzSpan="7" nzSm="6" nzMd="5" nzLg="5" nzXl="5">
                            <div nz-col nzSpan="6" class="align-items-center"
                                *ngIf="item.videoEncodingStatus && item.videoEncodingStatus !==3">
                                <span nz-icon [nzType]="'loading'" class="icon-primary" nz-tooltip
                                    nzTooltipTitle="Video optimization in progress. We'll notify you when it's complete."></span>
                                &nbsp;<span>Optimizing</span>
                            </div>
                            <div nz-col nzSpan="6" class="align-items-center"
                                *ngIf="!item.videoEncodingStatus || item.videoEncodingStatus === 3">
    
                                <span nz-icon nzType="check-circle" nzTheme="fill"
                                    *ngIf="item.status === 1 && !item.isScheduled" class="status-icon-success mr-2"></span>
                                <span *ngIf="item.status === 1 && !item.isScheduled">Active</span>
    
                                <span nz-icon nzType="close-circle" nzTheme="fill" *ngIf="item.status === 2"
                                    class="status-icon-danger mr-2"></span>
                                <span *ngIf="item.status === 2" class="text-nowrap">In-active</span>
    
                                <span nz-icon nzType="check-circle" nzTheme="fill"
                                    *ngIf="item.status === 1 && item.isScheduled" class="status-icon-primary mr-2"></span>
                                <span *ngIf="item.status === 1 && item.isScheduled">Scheduled</span>
    
                                <span nz-icon nzType="clock-circle" nzTheme="fill" *ngIf="item.status === 4"
                                    class="status-icon-primary mr-2"></span>
                                <span *ngIf="item.status === 4">Scheduled</span>
                            </div>
                        </div>
                        <nz-divider  class="mt-1 mb-0"></nz-divider>
                    </div>
                </div>

                <div *ngIf="isModelLoaded && userMediaApps && userMediaApps.length == 0 && hasMediaList"
                 class="empty-list-container">
                    <span class="text-muted fs-14">No media match your search.</span>
                </div>
                <div *ngIf="isModelLoaded && !hasMediaList" class="empty-list-container">
                    <span class="text-muted fs-14">No media found.</span>
                </div>
            </nz-spin>
        </form>
        <nz-drawer #drawer [nzClosable]="false" [nzVisible]="isDrawerVisible" nzPlacement="right" (nzOnClose)="close()"
            [nzMask]="false" class="custom-drawer">
            <ng-container *nzDrawerContent>
                <app-media-store (closeDrawer)="closeMediaStoreDrawer($event)" [selectedGroupId]="groupId"
                    *ngIf="showMediaDrawer"></app-media-store>
            </ng-container>
        </nz-drawer>
    </div>
</section>