<section>
    <div class="scroll-container" #scrollContainer (scroll)="onScroll($event)">
        <div nz-row>
            <div nz-col nzSpan="18">
                <div class="heading-fs-18 mb-0">
                    <span nz-icon nzType="arrow-left" nzTheme="outline" class="drawer-back-arrow mr-2 disp-mob"
                        (click)="cancel()"></span>
                    All Playlists
                </div>
            </div>
            <div nz-col nzSpan="6" class="text-right pt-2 disp-web">
                <span nz-icon nzType="close" nzTheme="outline" class="cursor-pointer" (click)="cancel()"></span>
            </div>
            <div nz-col nzSpan="24">
                <nz-divider></nz-divider>
            </div>
        </div>
        <div nz-row>
            <div nz-col nzSpan="24">
                <form nz-form>
                    <div class="d-flex">
                        <div class="w-100 mr-3">
                            <div class="input-container mt-2">
                                <nz-form-item class="mb-0">
                                    <nz-form-control class="drawer-search-input">
                                        <nz-input-group [nzPrefix]="prefixIcon" [nzSuffix]="suffixIcon">
                                            <input nz-input placeholder="Search Playlist" name="filterKey"
                                                [(ngModel)]="filterModel.filterKey" (keydown.enter)="onSearch()"
                                                (ngModelChange)="filterKeyChanged()" [maxlength]="100" [disabled]="!hasDisplayList"
                                                appAlphaNumericsLevelThree/>
                                        </nz-input-group>
                                        <ng-template #suffixIcon>
                                            <span nz-icon nzType="close" nzTheme="outline" *ngIf="filterModel.filterKey"
                                                (click)="clearSearch()" class="clear-search cursor-pointer"></span>
                                        </ng-template>
                                        <ng-template #prefixIcon>
                                            <span nz-icon nzType="search" nzTheme="outline" (click)="onSearch()"
                                                class="cursor-pointer"></span>
                                        </ng-template>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                        <div class="text-right mt-1">
                            <button nz-button nzType="primary" type="button" class="mt-2"
                                (click)="openDisplaylistUpsertDrawer()">
                                <span nz-icon nzType="plus" nzTheme="outline"></span>Add New
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div nz-col nzSpan="24" *ngIf="isModelLoaded  && hasDisplayList && model && model.length > 0">
            <div class="d-flex justify-content-end cursor-pointer">
                <nz-form-item class="sort-by-dropdown">
                    <nz-form-control>
                        <nz-select nzPlaceHolder="Sort By" [(ngModel)]="sortOrder" name="sortOrder"
                            (ngModelChange)="sortPlaylist()">
                            <nz-option [nzValue]="'ascending'" nzLabel="Ascending"></nz-option>
                            <nz-option [nzValue]="'descending'" nzLabel="Descending"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <nz-spin [nzSpinning]="isLoading">
            <div *ngIf="isModelLoaded && model && model.length > 0">
                <div nz-row *ngFor="let item of model; trackBy: trackById" [nzGutter]="[16,16]"
                    (click)="selectPlaylist(item)" class="list-item mb-2 pt-1 cursor-pointer">
                    <div nz-col nzSpan="16">
                        <div nz-row class="align-items-center">
                            <div class="d-flex align-items-center overflow-hidden">
                                <div class="cursor-pointer mr-3"
                                    [ngClass]="{'list-img-wrapper': !item.urlError, 'list-default-wrapper': item.urlError}"
                                    (click)="selectPlaylist(item)">
                                    <img *ngIf="!item.urlError" [src]="item.defaultUserMediaAppUrl" alt=""
                                        (error)="onError(item)" [ngClass]="{'hidden': item.urlError}">
                                </div>
                                <span class="cursor-pointer text-ellipsis" (click)="selectPlaylist(item)"
                                    onkeypress="selectPlaylist(item)">{{item.name}}</span>
                            </div>
                        </div>
                    </div>
                    <div nz-col nzSpan="8" class="align-items-center">
                        {{item.userMediaAppCount}} media
                    </div>
                    <nz-divider class="mt-1 mb-0"></nz-divider>
                </div>
            </div>
            <div *ngIf="isModelLoaded && model && model.length == 0 && hasDisplayList" class="empty-list-container">
                <span class="text-muted fs-14">No playlist match your search</span>
            </div>
            <div *ngIf="isModelLoaded && !hasDisplayList" class="empty-list-container">
                <span class="text-muted fs-14">No playlist found</span>
            </div>
        </nz-spin>
        <nz-drawer #drawer [nzClosable]="false" nzPlacement="right" (nzOnClose)="close()" [nzMask]="false"
            class="custom-drawer">
            <ng-container *nzDrawerContent>
                <app-display-list-upsert (closeDrawer)="closeDisplayUpsertDrawer($event)"
                    [addFromDisplay]="addFromDisplay" *ngIf="addDisplayList"
                    [selectedGroupId]="groupId"></app-display-list-upsert>
            </ng-container>
        </nz-drawer>
    </div>
</section>