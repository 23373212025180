export class PlanDetailModel {
    id: string;
    name: string;
    description: string;
    price: number;
    perMonthPrice: any;
    currencyCode: string;
    period: number;
    periodUnit: string;
    trialPeriod: number;
    trialPeriodUnit: string;
    pricingModel: string;
    billingCycles: number;
    taxCode: string;
    subscribedPlanQuantity: number;
    subscribedPlanFreeQuantity: number;
    subscribedQuantity: number;
    isSubscribed: boolean;
    isSelected: boolean;
    isMostPopular: boolean;
    pricePerDay: number;
    isProPlan: boolean;
}