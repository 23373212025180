import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppUtils, Constants } from 'src/helpers';
import moment from 'moment';
import { GroupDetailModel, MediaAppDetailModel, MediaListItemModel, UserMediaAppWebPageUpsertModel } from 'src/app/shared/models';
import { SelectListItemModel } from 'src/models';
import { UserMediaAppUpsertModel } from 'src/app/shared/models/user-media-app/user-media-app-upsert.model';
import { BaseService, DisplayListService, FolderService, GroupService, MediaAppService, UserMediaAppService } from 'src/app/shared/services';

@Component({
  selector: 'app-user-media-web-page-drawer',
  templateUrl: './user-media-web-page.component.html',
})
export class UserMediaWebPageDrawerComponent implements OnInit, OnDestroy {
  @Output() closeDrawer: EventEmitter<{ media: Array<MediaListItemModel>, mediaAdded: boolean }> = new EventEmitter<{ media: Array<MediaListItemModel>, mediaAdded: boolean }>();
  @Input() mediaAppId: any;
  @Input() selectedGroupId: number;
  @Input() displayListId: number;

  isLoading = false;
  showScheduleFields = false;
  model = new UserMediaAppUpsertModel();
  subscriptions = new Array<Subscription>();
  subscriptionPlans = new Array<SelectListItemModel>();
  selectedFolder = new Array<SelectListItemModel>();
  groups = new Array<GroupDetailModel>();
  folders = new Array<SelectListItemModel>();
  displayLists = new Array<SelectListItemModel>();
  selectedDisplayLists = new Array<SelectListItemModel>();
  mediaAppModel = new MediaAppDetailModel();
  scheduleStartTime: any;
  scheduleEndTime: any;
  isScheduled: boolean = false;
  showDuration: boolean = false;
  isEndDateValid = true;
  isEndTimeValid = true;
  isGroupLoaded: boolean = false;
  isStartTimeEndTimeValid = true;
  isTyping = false;

  get constants() { return Constants; }

  constructor(
    private baseService: BaseService,
    public appUtils: AppUtils,
    private groupService: GroupService,
    private displayListService: DisplayListService,
    private userMediaAppService: UserMediaAppService,
    private mediaAppService: MediaAppService,
    private folderService: FolderService,
  ) {
    this.folders = new Array<SelectListItemModel>();
    this.model = new UserMediaAppUpsertModel();
    this.groups = new Array<GroupDetailModel>();
    this.subscriptionPlans = new Array<SelectListItemModel>();

    this.model = new UserMediaAppUpsertModel();
    this.model.webPage = new UserMediaAppWebPageUpsertModel();
  }

  ngOnInit() {
    this.model.mediaAppId = this.mediaAppId;
    this.getMediaAppDetails();
    this.loadGroups();
  }

  onSearch(value: string): void {
    this.isTyping = value.length > 0;
  }

  getMediaAppDetails() {
    this.mediaAppService.get(this.model.mediaAppId)
      .subscribe({
        next: (response: MediaAppDetailModel) => {
          this.mediaAppModel = response;
          this.mediaAppModel.iconUrl = this.generateMediaIcon(this.mediaAppModel.typeString)

        },
        error: (error: any) => {
          this.baseService.processErrorResponse(error);
        }
      });
  }

  generateMediaIcon(mediaAppType: string): string {
    return `./assets/images/media-types/icons/v2/${mediaAppType}.svg`;
  }

  loadGroups(): void {
    this.isLoading = true;
    this.groupService.getList().subscribe({
      next: (response: Array<GroupDetailModel>) => {
        let selectListItem = new SelectListItemModel();
        selectListItem.keyString = '-1';
        selectListItem.keyInt = -1;
        selectListItem.value = `My Subscription (${this.baseService.getUserName()})`;

        this.subscriptionPlans.push(selectListItem);

        Object.assign(this.groups, response);

        this.groups.forEach((group) => {
          selectListItem = new SelectListItemModel();
          selectListItem.keyInt = group.id;
          selectListItem.keyString = group.id.toString();
          selectListItem.value = `${group.name} (${group.ownerName})`;
          this.subscriptionPlans.push(selectListItem);
        });

        if (this.selectedGroupId != undefined && this.selectedGroupId > 0) {
          this.model.groupId = this.selectedGroupId;
        }
        else {
          this.selectedGroupId = -1;
          this.model.groupId = -1;
        }

        this.isGroupLoaded = true;
        this.isLoading = false;
        this.getFolders();
        this.loadDisplayLists();
      },
      error: (error) => {
        this.isLoading = false;
        this.baseService.processErrorResponse(error);
      },
    });
  }

  onGroupChange() {
    this.getFolders();
    this.loadDisplayLists();
  }

  getFolders() {
    this.isLoading = true;
    if (!this.model.folderId) {
      this.model.folderId = null;
    }
    const requestsubscription = this.folderService
      .getList(this.model.groupId, this.model.folderId)
      .subscribe({
        next: (response: Array<SelectListItemModel>) => {
          this.folders = response;
          this.updateSelectedFolder();
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
          this.baseService.processErrorResponse(error);
        },
      });

    this.subscriptions.push(requestsubscription);
  }

  updateSelectedFolder() {
    if (this.folders.length === 0 || !this.model.folderId) {
      this.model.folderId = null;
      this.isLoading = false;
      return;
    }

    const selectedFolder = this.folders.filter(
      (x) => Number(x.keyInt) === Number(this.model.folderId)
    );

    if (selectedFolder) {
      this.selectedFolder = selectedFolder;
    }
  }

  cancel(): void {
    this.closeDrawer.emit({ media: new Array<MediaListItemModel>(), mediaAdded: false });
  }

  submit(): void {
    this.isLoading = true;
    if (this.model.groupId == null || this.model.groupId == -1) {
      this.model.groupId = null;
    }

    this.model.isScheduled = this.isScheduled;
    if (!this.validateScheduleDetails()) {
      this.isScheduled = true;
      this.isLoading = false;
      return;
    }

    this.model.displayLists = new Array<SelectListItemModel>();
    if (this.selectedDisplayLists && this.selectedDisplayLists.length > 0) {
      this.selectedDisplayLists.forEach((item) => {
        let selectedDisplayListModel = new SelectListItemModel();
        selectedDisplayListModel.keyInt = item.keyInt;
        selectedDisplayListModel.value = item.value;
        this.model.displayLists.push(selectedDisplayListModel);
      });
      this.model.lengthInSeconds =
        this.selectedDisplayLists.length === 1 || this.showDuration
          ? this.model.lengthInSeconds
          : '0';
    }

    if (this.selectedFolder && this.selectedFolder.length > 0) {
      this.model.folderId = this.selectedFolder[0].keyInt;
    } else {
      this.model.folderId = null;
    }

    this.model.mediaAppId = this.mediaAppId;
    this.model.id = 0;
    this.userMediaAppService.add(this.model).subscribe({
      next: (response: number) => {
        this.isLoading = false;
        let message;
        message = 'Media added successfully.';
        this.baseService.successNotification(message);
        console.log("media app", this.mediaAppModel);
        let addedMedia = new MediaListItemModel();
        addedMedia.id = response;
        addedMedia.typeString = this.mediaAppModel.typeString;
        addedMedia.title = this.model.title;
        addedMedia.iconUrl = this.appUtils.getBorderlessMediaAppIconUrl(this.mediaAppModel.typeString);
        addedMedia.mediaAppType = this.mediaAppModel.typeString;
        addedMedia.mediaAppTitle = this.mediaAppModel.title;
        addedMedia.status = this.model.webPage.isEnabled == true ? 1 : 2;
        addedMedia.isEnabled = this.model.webPage.isEnabled;
        addedMedia.isScheduled = this.model.isScheduled;
        addedMedia.isSelected = true;
        addedMedia.urlError = false;
        addedMedia.lengthInSeconds = this.model.lengthInSeconds;
        addedMedia.isScheduled = this.model.isScheduled;
        let addedMedias = new Array<MediaListItemModel>();
        addedMedias.push(addedMedia);
        console.log("addedMedias", addedMedias);
        this.closeDrawer.emit({ media: addedMedias, mediaAdded: true });
      },
      error: (error: any) => {
        this.isLoading = false;
        this.baseService.processErrorResponse(error);
      },
    });
  }

  onSchedule() {
    this.model.startDate = null;
    this.model.endDate = null;
    this.scheduleStartTime = null;
    this.scheduleEndTime = null;
  }

  scheduleTypeChanged(isDaily: boolean) {
    this.model.isDaily = isDaily;
    this.model.startDate = null;
    this.model.endDate = null;
  }

  disableStartDate = (current: Date): boolean => {
    return current && current < new Date(new Date().setHours(0, 0, 0, 0));
  }

  disableEndDate = (current: Date): boolean => {
    if (!this.model.startDate) {
      return current && current < new Date(new Date().setHours(0, 0, 0, 0));
    }
    return current && current < new Date(this.model.startDate);
  }

  isTimeValid(time: any): void {
    const startTime = moment(this.scheduleStartTime, 'hh:mm A').set({ s: 0 });
    const endTime = moment(time, 'hh:mm A').set({ s: 0 });
    if (
      !moment(endTime).isAfter(moment(startTime)) &&
      startTime.format('a') === endTime.format('a')
    ) {
      this.isEndTimeValid = false;
    } else {
      this.isEndTimeValid = true;
    }
  }

  isStartTimeValid(time: any): void {
    const startTime = moment(time, 'hh:mm A').set({ s: 0 });
    const endTime = moment(this.scheduleEndTime, 'hh:mm A').set({ s: 0 });
    if (
      !moment(endTime).isAfter(moment(startTime)) &&
      startTime.format('a') === endTime.format('a')
    ) {
      this.isEndTimeValid = false;
    } else {
      this.isEndTimeValid = true;
    }
  }

  validateScheduleDetails(): boolean {
    if (!this.model.isScheduled) {
      this.model.startTime = null;
      this.model.endTime = null;
      this.model.startDate = null;
      this.model.endDate = null;
      return true;
    }

    if (this.scheduleStartTime && this.scheduleEndTime) {
      if (!this.isStartTimeEndTimeValid) {
        return false;
      }

      const startTime = moment(this.scheduleStartTime, 'MM/DD/YYYY hh:mm A').set({ s: 0 });
      const endTime = moment(this.scheduleEndTime, 'MM/DD/YYYY hh:mm A').set({ s: 0 });
      if (
        !moment(endTime).isAfter(moment(startTime)) &&
        startTime.format('a') === endTime.format('a')
      ) {
        this.isEndTimeValid = false;
        return false;
      }
      this.model.startTime = this.appUtils.getFormattedTime(
        startTime.toString(),
        null
      );
      this.model.endTime = this.appUtils.getFormattedTime(
        endTime.toString(),
        null
      );

      if (this.model.isDaily) {
        this.model.startDate = null;
        this.model.endDate = null;
        return true;
      }
      this.model.startDate = this.appUtils.getFormattedDate(
        this.model.startDate,
        null
      );
      this.model.endDate = this.appUtils.getFormattedDate(
        this.model.endDate,
        null
      );
      if (moment(this.model.endDate).isBefore(this.model.startDate)) {
        this.isEndDateValid = false;
        return false;
      }
      this.isEndDateValid = true;
      return true;
    } else {
      return false;
    }
  }

  loadDisplayLists() {
    if (!this.isGroupLoaded) {
      return
    }
    this.displayLists = new Array<SelectListItemModel>();

    this.isLoading = true;
    const requestSubscription = this.displayListService
      .getSelectListItems(this.model.groupId)
      .subscribe({
        next: (data: Array<any>) => {
          this.isLoading = false;
          this.displayLists = data;
          if (!this.appUtils.isNullOrEmpty(this.displayListId)) {
            let selectedDisplayList = this.displayLists.find(x => x.keyInt == this.displayListId);
            this.model.displayLists.push(selectedDisplayList);
          }
          this.updateSelectedDisplayList();
        },
        error: (error: any) => {
          this.isLoading = false;
          this.baseService.processErrorResponse(error);
        },
      });

    this.subscriptions.push(requestSubscription);
  }

  updateSelectedDisplayList() {
    if (this.displayLists.length === 0) {
      return;
    }

    const selectedDisplayLists = new Array<SelectListItemModel>();
    this.model.displayLists.forEach((displayList) => {
      selectedDisplayLists.push(
        this.displayLists.find(
          (x) => Number(x.keyInt) === Number(displayList.keyInt)
        )
      );
    });

    if (selectedDisplayLists && selectedDisplayLists.length > 0) {
      this.selectedDisplayLists = selectedDisplayLists;
    }
  }

  removeDisplay(item: SelectListItemModel) {
    const tempArr = [];
    this.selectedDisplayLists.forEach((displayList) => {
      tempArr.push(displayList);
    });
    const itemIndex = tempArr.indexOf(item);
    tempArr.splice(itemIndex, 1);
    this.selectedDisplayLists = tempArr;
  }

  ngOnDestroy() {
    this.isLoading = false;
    this.subscriptions.forEach(s => { s.unsubscribe(); });
  }

}
