import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root',
})
export class AccountService {
    constructor(private http: HttpClient) { }

    private apiEndPoint = environment.apiBaseUrl + 'account/';

    generatePat(userType: string, issuer: string) {
        return this.http.get(this.apiEndPoint + `pat?userType=${userType}&issuer=${issuer}`);
    }
}
